import React, { useState } from 'react'
import { useColorStore } from '../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { useCategoryStore } from '../../../Store/ProductStore/CategoryStore'
import { useToasts } from 'react-toast-notifications';
import moment from 'moment/moment';
import { useResearchProductStore } from '../../../Store/ResearchStore/ResearchProductStore/ResearchProductStore';

const ResearchProductList = (props) => {
    const { addToast } = useToasts();
    const { fetchResearchProduct, updateResearchProduct, deleteResearchProduct } = useResearchProductStore()
    const { color } = useColorStore()
    const { data, count, isLoading, setIsLoading, product } = props
    const [error, setError] = useState(false)
    const [payload, setPayload] = useState({
        category: data.category,
        sub_category: data.sub_category,
        product: data.product_id,
        calculation: data.calculation,
        tgt_prcnt: data.tgt_prcnt ? data.tgt_prcnt : '',
        sl_prcnt: data.sl_prcnt ? data.sl_prcnt : '',
        profit: data.profit ? data.profit : '',
        loss: data.loss ? data.loss : '',
        buy_template: data.buy_template ? data.buy_template : '',
        sell_template: data.sell_template ? data.sell_template : '',
        call_template: data.call_template ? data.call_template : '',
        put_template: data.put_template ? data.put_template : '',
    })

    const handleSubmit = () => {
        setIsLoading(true)
        if (payload.category === 'CASH' && payload.sub_category.length > 0) {
            if (payload.product.length === 0 || payload.tgt_prcnt.length === 0 || payload.sl_prcnt.length === 0) {
                setIsLoading(false)
                addToast('All Fields Required...', { appearance: 'error' });
            } else {
                updateResearchProduct(payload, data.id)
                    .then(res => {
                        fetchResearchProduct()
                        setPayload({
                            ...payload,
                            category: payload.category,
                            sub_category: payload.sub_category,
                            product: payload.product_id,
                            calculation: payload.calculation,
                            tgt_prcnt: payload.tgt_prcnt ? payload.tgt_prcnt : '',
                            sl_prcnt: payload.sl_prcnt ? payload.sl_prcnt : '',
                            profit: payload.profit ? payload.profit : '',
                            loss: payload.loss ? payload.loss : '',
                            buy_template: payload.buy_template ? payload.buy_template : '',
                            sell_template: payload.sell_template ? payload.sell_template : '',
                            call_template: payload.call_template ? payload.call_template : '',
                            put_template: payload.put_template ? payload.put_template : '',
                        })
                        setIsLoading(false)
                        addToast('Success...', { appearance: 'success' });
                    }).catch(err => {
                        console.log(err);
                        setIsLoading(false)
                        addToast('Error...', { appearance: 'error' });
                    })
            }
        }
        else if (payload.category === 'FUTURE' || payload.category === 'OPTION' && payload.sub_category.length > 0) {
            if (payload.product.length === 0 || payload.profit.length === 0 || payload.loss.length === 0) {
                setIsLoading(false)
                addToast('All Fields Required...', { appearance: 'error' });
            } else {
                updateResearchProduct(payload, data.id)
                    .then(res => {
                        fetchResearchProduct()
                        setPayload({
                            ...payload, category: 'CASH',
                            category: payload.category,
                            sub_category: payload.sub_category,
                            product: payload.product_id,
                            calculation: payload.calculation,
                            tgt_prcnt: payload.tgt_prcnt ? payload.tgt_prcnt : '',
                            sl_prcnt: payload.sl_prcnt ? payload.sl_prcnt : '',
                            profit: payload.profit ? payload.profit : '',
                            loss: payload.loss ? payload.loss : '',
                            buy_template: payload.buy_template ? payload.buy_template : '',
                            sell_template: payload.sell_template ? payload.sell_template : '',
                            call_template: payload.call_template ? payload.call_template : '',
                            put_template: payload.put_template ? payload.put_template : '',
                        })
                        setIsLoading(false)
                        addToast('Success...', { appearance: 'success' });
                    }).catch(err => {
                        console.log(err);
                        setIsLoading(false)
                        addToast('Error...', { appearance: 'error' });
                    })
            }
        }

        else {
            setIsLoading(false)
            addToast('All Fields Required...', { appearance: 'error' });
        }
    }

    const handleDelete = () => {
        let d = document.getElementById("dltBtn")
        d.click()
        setIsLoading(true)
        deleteResearchProduct(data.id)
            .then(res => {
                fetchResearchProduct()
                setIsLoading(false)
                addToast('Success...', { appearance: 'success' });
            }).catch(err => {
                console.log(err);
                setIsLoading(false)
                addToast('Error...', { appearance: 'error' });
            })
    }

    const findProduct = (id) =>{
        let d = product.find((f)=>{
            return f.id===id
        })
        if(d){
            return d.name
        }else{ return '-'}
    }

    return (
        <>
            <tr style={{ color: color.darkText }}>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
                    {count}
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>
                    {findProduct(data.product_id)}
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.category} - {data.sub_category}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.calculation}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.calculation === 'PERCENTAGE' ? data.tgt_prcnt + '%' : data.profit}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.calculation === 'PERCENTAGE' ? data.sl_prcnt + '%' : data.loss}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.buy_template + data.call_template}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.sell_template + data.put_template}</td>

                <td style={{ borderBottom: '1px solid ' + color.tableBorder }}>
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <span type="button" class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryModal" + data.id} style={{ color: color.blue }}><Icon icon="akar-icons:edit" fontSize={18} /></span>
                        </li>
                        <li class="list-inline-item">
                            <span type={'button'} class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal" + data.id} style={{ color: color.red }}><Icon icon="ep:delete" fontSize={17} /></span>
                        </li>
                    </ul>
                </td>
            </tr>

            {/* ----------------------Update Modal---------------------------- */}

            <div class="modal fade" id={"categoryModal" + data.id} tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Update Lotsize</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Category <span className="text-danger">*</span></label>
                                <select value={payload.category} onChange={(e) => setPayload({ ...payload, category: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="CASH">Cash</option>
                                    <option value="FUTURE">Future</option>
                                    <option value="OPTION">Option</option>
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Sub Category <span className="text-danger">*</span></label>
                                <select value={payload.sub_category} onChange={(e) => setPayload({ ...payload, sub_category: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="STOCK">Stock</option>
                                    <option value="INDEX">Index</option>
                                    <option value="COMMODITY">Commodity</option>
                                    <option value="CURRENCY">Currency</option>
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Select Product <span className="text-danger">*</span></label>
                                <select value={payload.product} onChange={(e) => setPayload({ ...payload, product: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">--------Select Product---------</option>
                                    {product.map((s) => {
                                        return <option value={s.id} key={s.id}>{s.name}</option>
                                    })}
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Calculation Base <span className="text-danger">*</span></label>
                                <select value={payload.calculation} onChange={(e) => setPayload({ ...payload, calculation: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="PERCENTAGE">Percentage</option>
                                    <option value="PROFIT">Profit</option>
                                </select>
                            </div>

                            <div className="myrow justify_space_between">

                                {payload.calculation === 'PERCENTAGE' ? <>
                                    <div className='mb-2' style={{ width: '48%' }}>
                                        <label className='modal_label' style={{ color: color.darkText }}>TGT% <span className="text-danger">*</span></label>
                                        <input placeholder='Enter TGT Percentage...' value={payload.tgt_prcnt} onChange={(e) => setPayload({ ...payload, tgt_prcnt: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>

                                    <div className='mb-2' style={{ width: '48%' }}>
                                        <label className='modal_label' style={{ color: color.darkText }}>SL% <span className="text-danger">*</span></label>
                                        <input placeholder='Enter SL Percentage...' value={payload.sl_prcnt} onChange={(e) => setPayload({ ...payload, sl_prcnt: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </> : <>
                                    <div className='mb-2' style={{ width: '48%' }}>
                                        <label className='modal_label' style={{ color: color.darkText }}>Profit <span className="text-danger">*</span></label>
                                        <input placeholder='Enter Profit...' value={payload.profit} onChange={(e) => setPayload({ ...payload, profit: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>

                                    <div className='mb-2' style={{ width: '48%' }}>
                                        <label className='modal_label' style={{ color: color.darkText }}>Loss <span className="text-danger">*</span></label>
                                        <input placeholder='Enter Loss...' value={payload.loss} onChange={(e) => setPayload({ ...payload, loss: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>

                                </>
                                }

                                <div className='mb-2' style={{ width: '48%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Buy Template Name</label>
                                    <input placeholder='Enter...' value={payload.buy_template} onChange={(e) => setPayload({ ...payload, buy_template: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-2' style={{ width: '48%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Sell Template Name</label>
                                    <input placeholder='Enter...' value={payload.sell_template} onChange={(e) => setPayload({ ...payload, sell_template: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-2' style={{ width: '48%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Call Template Name</label>
                                    <input placeholder='Enter...' value={payload.call_template} onChange={(e) => setPayload({ ...payload, call_template: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-2' style={{ width: '48%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Put Template Name</label>
                                    <input placeholder='Enter...' value={payload.put_template} onChange={(e) => setPayload({ ...payload, put_template: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Update"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------------------------Delete Modal------------------------ */}

            <div class="modal fade" id={"categoryDeleteModal" + data.id} tabindex="-1" aria-labelledby="categoryDeleteModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to delete ( {findProduct(data.product_id)} ) Research Product ? You would'nt be able to retrieve this.</div>
                        </div>


                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id="dltBtn" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleDelete}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Delete"}</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ResearchProductList