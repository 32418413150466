import React, { useState, useEffect } from 'react'
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { useColorStore } from '../../../Store/ColorStore';
import { useToasts } from 'react-toast-notifications';

const SettingList = (props) => {

    const { addToast } = useToasts()
    const { d, updateSettingOption, fetchSettingOption } = props
    const { color } = useColorStore()

    let x = {
        IP_LOGIN:'IP_LOGIN',
        LEAD_SHUFFLE:'LEAD_SHUFFLE',
        LEAD_RESTORE:'LEAD_RESTORE',
    }

    const handleChange = (name,e) =>{
        let payload = {
            d:name,status:e.target.checked
        }
        updateSettingOption(payload)
        .then(res => {
            fetchSettingOption()
            addToast('Success...', { appearance: 'success' });
        })
        .catch(err => {
            addToast('Something went wrong...', { appearance: 'error' });
        })
    }

    return (
        <>
            < div className="d-flex justify_space_between">
                <div className="per_block1" style={{ color: color.darkText }}>
                    Ip Login
                </div>

                <div className="per_block2">
                    <Switch sx={{ m: 1 }} color='secondary' onChange={(e)=>handleChange(x.IP_LOGIN,e)} checked={d.ip_login} />
                </div>
            </div>

            < div className="d-flex justify_space_between">
                <div className="per_block1" style={{ color: color.darkText }}>
                    Lead Shuffle
                </div>

                <div className="per_block2">
                    <Switch sx={{ m: 1 }} color='secondary' onChange={(e)=>handleChange(x.LEAD_SHUFFLE,e)} checked={d.lead_shuffel} />
                </div>
            </div>

            < div className="d-flex justify_space_between">
                <div className="per_block1" style={{ color: color.darkText }}>
                    Lead Restore
                </div>

                <div className="per_block2">
                    <Switch sx={{ m: 1 }} color='secondary' onChange={(e)=>handleChange(x.LEAD_RESTORE,e)} checked={d.lead_restore} />
                </div>
            </div>
        </>
    )
}

export default SettingList