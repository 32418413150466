import { create } from 'zustand';
import axios from 'axios';
import { url } from '../../Export';

export const useComplaintDataStore = create((set) => ({
    complaintData: [],
    fetchComplaintData: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'web/view-complaint-current-month/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ complaintData: data });
    },
    createComplaintData: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'web/create-complaint-current-month/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updateComplaintData: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'web/update-complaint-current-month/'+id+'/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    deleteComplaintData: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'web/delete-complaint-current-month/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    
}));