import React, { useState, useEffect, Suspense } from 'react'
import Ripples from 'react-ripples';
import { Icon } from '@iconify/react';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../../Loading';
import ReactPaginate from 'react-paginate';
import Select, { createFilter, useStateManager } from 'react-select';
import makeAnimated from 'react-select/animated';
import { useColorStore } from './../../../../Store/ColorStore';
import { useLeadImportStore } from '../../../../Store/LeadStore/LeadImportStore';
import { useUserStore } from '../../../../Store/UserStore/UserStore';
import { useLeadActionStore } from '../../../../Store/LeadStore/LeadAction';
import "./AllLeads.css"
import { CSVLink } from "react-csv";
import moment from 'moment';
import SelectUsersList from './SelectUsersList';
import { useLocation, useNavigate } from 'react-router-dom';
const AllLeadsList = React.lazy(() => { return import('./AllLeadsList') });
const animatedComponents = makeAnimated();

const AllLeads = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const { color } = useColorStore()
    const { users, fetchUsers } = useUserStore()
    const { importExcelLeads, importManualLeads, fetchAllLeads, allLeadsList, allLeads, fetchUnassignedLeads, updateAllLeadsList, allLeadsOther, setAllLeads, exportLeads, setExportLeads, fetchExportLeads } = useLeadImportStore()
    const { restoreLeads, trashLeads, assignLeads, bulkAssignLeads } = useLeadActionStore()
    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)

    const [searchInput, setSearchInput] = useState("")
    const [searchParam] = useState(["name", 'email', 'contact']);

    function search() {
        setReload(!reload)
    }

    const handleBlur = ()=>{
        if(searchInput.length===0){
            setReload(!reload)
        }
    }

    useEffect(() => {
        if (users.length === 0) {
            fetchUsers()
        }
    }, [])



    // ----------------------------------------------------------------------------Filteration work-------------------------------------------------------------------------------------------

    const [filterMode, setFilterMode] = useState(false)
    const [changeNotify, setChangeNotify] = useState(false)
    const [owner, setOwner] = useState([])
    const [filterLeadSource, setFilterLeadSource] = useState([])
    const [status, setStatus] = useState("")
    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: '',
    })
    const [options, setOptions] = useState([])
    const leadsource = [
        { value: 'FB', label: 'FaceBook' },
        { value: 'G', label: 'Google' },
        { value: 'O', label: 'Organic' },
        { value: 'B', label: 'Bulk' },
        { value: 'OT', label: 'Other' }
    ]

    useEffect(() => {
        setOptions([])
        let d = []
        for (let i = 0; i < users.length; i++) {
            const u = users[i];
            d.push({
                value: u.id, label: u.name
            })
        }
        setOptions(d)
    }, [users])

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: color.inputBackground,
            // match with the menu
            borderRadius: 4,
            // Overwrittes the different states of border
            borderColor: color.borderColor,
            fontSize: 14,
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: color.borderColor
            }
        }),
        menu: base => ({
            ...base,
            background: color.inputBackground,
            color: color.darkText,
            fontSize: 14,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);

            return {
                ...styles,
                backgroundColor: isFocused ? color.blue : null,
                color: isFocused ? "#fff" : null,
                // color: "#333333"
            };
        }
    };

    const handleRemoveOwner = (index) => {
        setOwner([
            ...owner.slice(0, index),
            ...owner.slice(index + 1, owner.length)
        ]);
    }
    const handleRemoveFilterLeadSource = (index) => {
        setFilterLeadSource([
            ...filterLeadSource.slice(0, index),
            ...filterLeadSource.slice(index + 1, filterLeadSource.length)
        ]);
    }

    useEffect(() => {
        if (location.search.length > 0 && options.length > 0) {
            const filterParams = new URLSearchParams(location.search);
            const nowner = filterParams.get('owner');
            const nfilterLeadSource = filterParams.get('filterLeadSource');
            const nstatus = filterParams.get('status');
            const nstartDate = filterParams.get('startDate');
            const nendDate = filterParams.get('endDate');
            const nsearch = filterParams.get('search');
            setStatus(nstatus)
            setOwner(options.filter((f) => {
                return nowner.split(',').indexOf(String(f.value)) > -1;
            }))
            setFilterLeadSource(leadsource.filter((f) => {
                return nfilterLeadSource.split(',').indexOf(f.value) > -1;
            }))
            setDateRange({ ...dateRange, startDate: nstartDate, endDate: nendDate })
            setSearchInput(nsearch)
            setChangeNotify(true)
        }
        else {
            if (location.search.length === 0) {
                clearFilter()
            }
        }
    }, [location.search, options])

    const [filterToggle, setFilterToggle] = useState(true)

    const applyFilter = () => {
        setChangeNotify(false)
        if (owner.length > 0 || filterLeadSource.length > 0 || status.length > 0 || searchInput.length > 0 || dateRange.startDate.length > 0 && dateRange.endDate.length > 0) {
            setFilterMode(true)
            setFilterToggle(!filterToggle)
            let u = `?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&owner=${owner.map((f) => { return f.value })}&filterLeadSource=${filterLeadSource.map((f) => { return f.value })}&status=${status}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&search=${searchInput}`
            navigate(`${u}`);
        } else {
            clearFilter()
        }
    }

    const clearFilter = () => {
        setOwner([])
        setFilterLeadSource([])
        setStatus("")
        setDateRange({ ...dateRange, startDate: '', endDate: '' })
        navigate('')
        setFilterMode(false)
        setFilterToggle(!filterToggle)
    }

    const handleStatusChange = () => {
        setStatus('')
        setChangeNotify(true)
    }

    useEffect(() => {
        if (changeNotify) {
            applyFilter()
        }
    }, [changeNotify])


    // ------------------------------------------------------ Notification Work Is Here--------------------------------------------------------
    const [showDropDownNotification, setShowDropDownNotification] = useState(false)
    document.onclick = function () {
        setShowDropDownNotification(false)
    }

    const [csvFile, setCsvFile] = useState()
    const [leadSource, setLeadSource] = useState('')

    const handleFileUpload = (e) => {
        e.preventDefault()
        if (!e.dataTransfer) return;
        setCsvFile(e.dataTransfer.files[0]);
    }

    const handleSubmit = () => {
        if (csvFile && leadSource.length > 0) {
            setError(false)
            setIsLoading(true)
            let formData = new FormData()
            formData.append('leadSource', leadSource)
            formData.append('csvFile', csvFile)
            importExcelLeads(formData)
                .then(res => {
                    setIsLoading(false)
                    addToast('Lead Import Success...', { appearance: 'success' });
                    setCsvFile()
                    setLeadSource('')
                })
                .catch(err => {
                    setIsLoading(false)
                    addToast('Lead Import Error...', { appearance: 'error' });
                })
        } else {
            setError(true)
        }
    }

    const [payload, setPayload] = useState({
        name: '',
        email: '',
        contact: '',
        lead_source: '',
    })

    const [error2, setError2] = useState({
        name: '',
        contact: '',
        email: '',
        lead_source: ''
    })

    const handleValidation = () => {
        let formIsValid = true;
        let errors = { ...error2 }
        if (payload.lead_source.length === 0) {
            formIsValid = false;
            errors.lead_source = 'Required...!'
        } else {
            errors.lead_source = ''
        }
        if (payload.name.length === 0) {
            formIsValid = false;
            errors.name = 'Required...!'
        } else {
            errors.name = ''
        }
        if (payload.contact.length !== 10) {
            formIsValid = false;
            errors.contact = 'Please enter valid contact number...!'
        } else {
            errors.contact = ''
        }

        setError2(errors)
        return formIsValid
    }

    const handleSubmit2 = () => {
        if (handleValidation()) {
            setIsLoading(true)
            importManualLeads(payload)
                .then(res => {
                    setIsLoading(false)
                    addToast('Lead Import Success...', { appearance: 'success' });
                    setPayload({ ...payload, name: '', lead_source: '', email: '', contact: '' })
                })
                .catch(err => {
                    setIsLoading(false)
                    addToast('Lead Import Error...', { appearance: 'error' });
                })
        } else {

        }
    }

    useEffect(() => {
        if (allLeads.length === 0 || allLeadsList.length === 0) {
            fetchAllLeads()
        }

    }, [])

    // ----------------Checked Ids Work---------------------------

    const [checked, setChecked] = useState([]);
    const handleCheckAllChange = (e) => {
        setChecked(
            e.target.checked ? allLeads.map((c) => c.id) : []
        );
    };

    const handleSingleChange = (e, c) => {
        setChecked((prevChecked) =>
            e.target.checked
                ? [...prevChecked, c.id]
                : prevChecked.filter((item) => item !== c.id)
        );
    };


    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (allLeadsOther) {
            const endOffset = itemOffset + itemsPerPage;
            setPageCount(allLeadsOther.count / itemsPerPage);
            setItemEndSet(endOffset)
            setitemLength(allLeadsOther.count)
        }
    }, [allLeadsOther]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1)
        setReload(!reload)
        const newOffset = (event.selected * itemsPerPage) % allLeadsOther.count;
        setItemOffset(newOffset);
    };

    // ******************pagination work ends here***************************************

    const handleLeadRestore = () => {
        if (checked.length > 0) {
            setIsLoading(true)
            restoreLeads(checked)
                .then(res => {
                    setChecked([])
                    setReload(!reload)
                    setIsLoading(false)
                    addToast('Lead Restore Success...', { appearance: 'success' });
                })
                .catch(err => {
                    setIsLoading(false)
                    addToast('Something went wrong...', { appearance: 'error' });
                })

        } else {
            addToast('Please select leads to restore...', { appearance: 'error' });
        }
    }

    const handleLeadTrash = () => {
        if (checked.length > 0) {
            let d = document.getElementById("dltBtn")
            d.click()
            setIsLoading(true)
            trashLeads(checked)
                .then(res => {
                    setChecked([])
                    setReload(!reload)
                    setIsLoading(false)
                    addToast('Leads Sent to Trash...', { appearance: 'success' });
                })
                .catch(err => {
                    setIsLoading(false)
                    addToast('Something went wrong...', { appearance: 'error' });
                })

        } else {
            addToast('Please select leads to trash...', { appearance: 'error' });
        }
    }

    const [assignTo, setAssignTo] = useState("")

    const handleLeadAssign = () => {
        if (checked.length > 0 && assignTo.length > 0) {
            let d = document.getElementById("dltBtn")
            d.click()
            setIsLoading(true)
            let ndata = {
                checked: checked,
                assign_to: assignTo
            }
            assignLeads(ndata)
                .then(res => {
                    setChecked([])
                    setReload(!reload)
                    setIsLoading(false)
                    addToast('Leads assigned...', { appearance: 'success' });
                })
                .catch(err => {
                    setIsLoading(false)
                    addToast('Something went wrong...', { appearance: 'error' });
                })

        } else {
            addToast('Please select user to assign leads...', { appearance: 'error' });
        }
    }

    // ---------------------------Bulk Assign Works Start--------------------------

    const [availabelLeads, setAvailabelLeads] = useState(0)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [numofLeads, setNumofLeads] = useState('')
    const [dateRange2, setDateRange2] = useState({
        startDate: '',
        endDate: '',
    })

    useEffect(() => {
        let u = `?startDate=${dateRange2.startDate}&endDate=${dateRange2.endDate}`
        fetchUnassignedLeads(u)
            .then(res => {
                console.log(res);
                setAvailabelLeads(res)
            })
            .catch(err => {
                console.log(err);
            })
    }, [dateRange2])

    const handleCheckAllChange2 = (e) => {
        setSelectedUsers(
            e.target.checked ? filterUsers(users).map((c) => c.id) : []
        );
    };

    function filterUsers(items) {
        return items.filter((item) => {
            return item.user_type === 'SALES EXECUTIVE' || item.user_type === 'SALES MANAGER' || item.user_type === 'ADMIN'
        });
    }

    const handleBulkAssign = () => {
        if (selectedUsers.length > 0 && numofLeads.length > 0) {
            if (selectedUsers.length * Number(numofLeads) > Number(availabelLeads)) {
                addToast('Insufficient Leads...!', { appearance: 'error' });
            } else {
                setIsLoading(true)
                let ndata = {
                    assignToList: selectedUsers,
                    numberOfENq: numofLeads,
                    startDate: dateRange2.startDate,
                    endDate: dateRange2.endDate,
                }
                bulkAssignLeads(ndata)
                    .then(res => {
                        setSelectedUsers([])
                        fetchAllLeads()
                        setNumofLeads('')
                        setIsLoading(false)
                        setReload(!reload)
                        addToast('Leads assigned...', { appearance: 'success' });
                    })
                    .catch(err => {
                        setIsLoading(false)
                        addToast('Something went wrong...', { appearance: 'error' });
                    })
            }

        } else {
            addToast('Please select user to assign leads...', { appearance: 'error' });
        }
    }

    // *************************************************Export Work****************************************************

    const [downloadExport, setDownloadExport] = useState(false)
    const handleExport = () => {
        if (exportLeads.length > 0) {
            document.getElementById('exportData').click()
        } else {
            setIsLoading(true)
            fetchExportLeads()
                .then(res => {
                    setExportLeads(res)
                    setIsLoading(false)
                    setDownloadExport(true)
                })
                .catch(err => {
                    setIsLoading(false)
                })
        }
    }

    useEffect(() => {
        if (downloadExport && exportLeads.length > 0) {
            setDownloadExport(false)
            document.getElementById('exportData').click()
        }
    }, [downloadExport, exportLeads])

    // *************************************************Main Url Work****************************************************

    useEffect(() => {
        let u = `itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&owner=${owner.map((f) => { return f.value })}&filterLeadSource=${filterLeadSource.map((f) => { return f.value })}&status=${status}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&search=${searchInput}`
        setIsLoading(true)
        fetchAllLeads(u)
            .then(res => {
                setIsLoading(false)
                setAllLeads(res)
            })
            .catch(err => {
                setIsLoading(false)
            })
    }, [reload, itemsPerPage, filterToggle])

    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search d-flex align_center" style={{ position: 'relative' }}>
                        <div>
                            <input onBlur={handleBlur} type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
                            <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                        </div>
                        <div className='ms-2'>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block mb-0' during={650}>
                                <button onClick={search} className="primaryButton "><Icon icon="ion:search-outline" fontSize={18} /></button>
                            </Ripples>
                        </div>
                    </div>

                    <div className="myrow">
                        <CSVLink data={exportLeads} id='exportData' filename='All-Leads.csv' style={{ display: 'none' }}></CSVLink>
                        <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={650}>
                            <button data-bs-toggle="modal" data-bs-target="#bulkAssignModal" className="primaryButton right_border_radius " style={{ marginRight: '0.7px' }}><Icon icon="ri:a-b" fontSize={18} /></button>
                        </Ripples>

                        <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={650}>
                            <button data-bs-toggle="modal" data-bs-target="#leadAssignModal" className="primaryButton left_border_radius right_border_radius" disabled={checked.length === 0} style={{ marginRight: '1px' }}><Icon icon="mingcute:user-edit-line" fontSize={18} /></button>
                        </Ripples>

                        <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={650}>
                            <button data-bs-toggle="modal" data-bs-target="#leadRestoreModal" disabled={checked.length === 0} className="primaryButton left_border_radius right_border_radius" style={{ marginRight: '1px' }}><Icon icon="ic:round-settings-backup-restore" fontSize={18} /></button>
                        </Ripples>

                        <Ripples color='rgba(255,255,255,0.2)' className='me-4 dis_block' during={650}>
                            <button data-bs-toggle="modal" data-bs-target="#leadTrashModal" disabled={checked.length === 0} className="primaryButton left_border_radius" ><Icon icon="basil:trash-solid" fontSize={18} /></button>
                        </Ripples>


                        <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={800}>
                            <button data-toggle="tooltip" data-placement="top" title="Tooltip on top" className="infoButton right_border_radius" data-bs-toggle="offcanvas" data-bs-target="#LeadsFilterCanvas" aria-controls="LeadsFilterCanvas" ><Icon icon="mdi:filter" fontSize={15} /> Filter</button>
                        </Ripples>
                        <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={800}>
                            <button onClick={handleExport} className="successButton left_border_radius right_border_radius"><Icon icon="ph:export-fill" fontSize={16} /> Export</button>
                        </Ripples>
                        <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={1000}>
                            <button className="primaryButton left_border_radius" onClick={() => setShowDropDownNotification(!showDropDownNotification)} ><Icon icon="clarity:import-solid" fontSize={16} /> Import</button>
                        </Ripples>
                        {showDropDownNotification &&
                            <div className="more_block" style={{ backgroundColor: color.secondaryBg, marginTop: '150px', width: 'fit-content', marginRight: '40px' }} onClick={(e) => e.stopPropagation()}>
                                <div className="p-3">
                                    <div className="myrow justify_space_between ">
                                        <h6 style={{ color: color.darkText, fontSize: '14px' }}>Import</h6>
                                    </div>
                                </div>

                                <div style={{ marginTop: '-10px' }}>
                                    <div className="d-flex notification_wrapper align_center" data-bs-toggle="modal" data-bs-target="#excelImportModal" >
                                        <Icon icon="vscode-icons:file-type-excel2" fontSize={15} style={{ marginTop: '' }} />
                                        <div style={{ marginLeft: '10px' }}>
                                            <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Import from Excel</div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ marginTop: '-10px' }}>
                                    <div className="d-flex notification_wrapper align_center" data-bs-toggle="modal" data-bs-target="#manualImportModal" >
                                        <Icon icon="solar:import-bold-duotone" color={color.yellow} fontSize={15} style={{ marginTop: '' }} />
                                        <div style={{ marginLeft: '10px' }}>
                                            <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Manual Import</div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ marginTop: '-10px' }}>
                                    <div className="d-flex notification_wrapper align_center" onClick={() => setShowDropDownNotification(false)}>
                                        <Icon icon="ph:x-duotone" color={color.red} fontSize={15} style={{ marginTop: '' }} />
                                        <div style={{ marginLeft: '10px' }}>
                                            <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Close</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </div>

                {filterMode &&
                    <div className='filter_values_wrapper'>
                        <div className="filter_values_title" style={{ color: color.darkText }}>Filter by &nbsp;:<span onClick={clearFilter} className="clear_filter_link">Clear filter</span></div>
                        {status.length > 0 && <div className="filter_values">{status === "A" ? 'Assigned' : status === "UA" ? 'Un-Assigned' : status === "T" ? 'Trash' : null} <Icon icon="fa6-solid:x" onClick={handleStatusChange} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>}
                        {owner.length > 0 ?
                            owner.map((u, count) => {
                                return <div className="filter_values" key={count}>{u.label.toLowerCase()} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveOwner(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            })
                            : null
                        }
                        {filterLeadSource.length > 0 ?
                            filterLeadSource.map((u, count) => {
                                return <div className="filter_values" key={count}>{u.label.toLowerCase()} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveFilterLeadSource(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            })
                            : null
                        }
                        {dateRange.startDate.length > 0 && dateRange.endDate.length > 0 &&
                            <div className="filter_values">
                                {moment(dateRange.startDate).format('ll')} - {moment(dateRange.endDate).format('ll')} <Icon icon="fa6-solid:x" onClick={() => { setDateRange({ ...dateRange, startDate: '', endDate: '' }); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} />
                            </div>
                        }

                    </div>
                }

                <div className="table-responsive mt-3">
                <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '75px', padding: '5px', textAlign: 'center', borderRadius: '2px' }}>
                            <option value={10}>10</option>
                            <option value={1}>1</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={100}>1000</option>
                        </select>
                        &nbsp; Out of - {allLeadsOther ? allLeadsOther.count : 0}
                    </div>
                    {checked.length > 0 &&
                        <div style={{ color: color.blue2, fontSize: "13px", marginBottom: '10px' }}>
                            {checked.length} Entries are Selected. <u onClick={() => setChecked([])} style={{ color: color.red, cursor: 'pointer' }}> Clear all </u>
                        </div>
                    }
                    <table className="table table-nowrap align-middle mb-3 mt-3">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '40px', color: color.darkText }}>
                                    <input checked={allLeads.every((e, index) => e.id === checked[index]) && itemLength > 0} onChange={handleCheckAllChange} type="checkbox" className="form-check-input" style={{ backgroundColor: color.inputBackground, border: "1px solid " + color.borderColor }} />
                                    {/* <label className="form-check-label" for="contacusercheck"></label> */}
                                </th>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Name</th>
                                <th style={{ color: color.darkText }} scope="col">Contact Info</th>
                                <th style={{ color: color.darkText }} scope="col">Lead Owner</th>
                                <th style={{ color: color.darkText }} scope="col">Lead Source</th>
                                <th style={{ color: color.darkText }} scope="col">Status</th>
                                <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {allLeads.length > 0 ?
                                allLeads.map((c, count) => {
                                    return <Suspense key={c.id} fallback={<></>}>
                                        <AllLeadsList checked={checked} handleSingleChange={handleSingleChange} data={c} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                    </Suspense>
                                })
                                :
                                <div style={{ color: color.darkText, fontSize: 14, textAlign: 'center' }}>No Record found....!</div>
                            }
                        </tbody>
                    </table>
                    {allLeads.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />

                </div>

            </div>

            {isLoading && <Loading />}
            {/* ----------------------Excel Upload Modal---------------------------- */}

            <div className="modal fade" id="excelImportModal" tabindex="-1" aria-labelledby="excelImportModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div className="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }} id="excelImportModalLabel">Import files from Excel</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Lead Source <span className="text-danger">*</span></label>
                                <select value={leadSource} onChange={(e) => setLeadSource(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">----------Please Select Lead Source------------------</option>
                                    <option value="FB">Facebook</option>
                                    <option value="O">Organic</option>
                                    <option value="G">Google</option>
                                    <option value="B">Bulk</option>
                                    <option value="OT">Other</option>
                                </select>
                            </div>
                            {csvFile ? <>
                                <label className='modal_label' style={{ color: color.darkText }}>Selected File</label>
                                <div className='modal_input' style={{ color: color.darkText, fontSize: '14px', border: "1px solid " + color.borderColor }}>
                                    {csvFile.name}
                                </div>
                                <span type="button" onClick={() => setCsvFile()} style={{ textDecoration: 'underline', fontSize: '13px', color: color.red }}>Remove</span>

                            </>
                                :
                                <div className='mb-3'>
                                    <label className='modal_label' style={{ color: color.darkText }}>CSV File <span className="text-danger">*</span></label>
                                    <input type='file' id='fileInput' onChange={(e) => setCsvFile(e.target.files[0])} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor, display: "none" }} accept=".xlsx, .xls, .csv" className='modal_input' />

                                    <label htmlFor="fileInput" onDrop={(e) => handleFileUpload(e)} onDragOver={(e) => e.preventDefault()} style={{ border: "0.5px solid " + color.tableBorder }} className='fileDropzone'>
                                        <Icon icon="line-md:cloud-upload-loop" color={color.lightText} style={{ color: color.darkText, opacity: 0.4 }} fontSize={150} />
                                        <div className="text-center" style={{ color: color.darkText, opacity: 0.7 }}>Upload or Drop file here</div>
                                    </label>

                                </div>
                            }
                        </div>

                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Import"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------------------Manual Import Upload Modal---------------------------- */}

            <div className="modal fade" id="manualImportModal" tabindex="-1" aria-labelledby="manualImportModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div className="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }} id="manualImportModalLabel">Manual Import</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Lead Source <span className="text-danger">*</span></label>
                                <select value={payload.lead_source} onChange={(e) => setPayload({ ...payload, lead_source: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">----------Please Select Lead Source------------------</option>
                                    <option value="FB">Facebook</option>
                                    <option value="O">Organic</option>
                                    <option value="G">Google</option>
                                    <option value="B">Bulk</option>
                                    <option value="OT">Other</option>
                                </select>
                                {error2.lead_source.length > 0 && <div className="text-danger" style={{ fontSize: '13px' }}>{error2.lead_source}</div>}
                            </div>

                            <div className="mb-2">
                                <label className='modal_label' style={{ color: color.darkText }}>Name <span className="text-danger">*</span></label>
                                <input value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                {error2.name.length > 0 && <div className="text-danger" style={{ fontSize: '13px' }}>{error2.name}</div>}
                            </div>

                            <div className="mb-3">
                                <label className='modal_label' style={{ color: color.darkText }}>Contact <span className="text-danger">*</span></label>
                                <input value={payload.contact} onChange={(e) => setPayload({ ...payload, contact: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                {error2.contact.length > 0 && <div className="text-danger" style={{ fontSize: '13px' }}>{error2.contact}</div>}
                            </div>

                            <div className="mb-3">
                                <label className='modal_label' style={{ color: color.darkText }}>Email</label>
                                <input value={payload.email} onChange={(e) => setPayload({ ...payload, email: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                {error2.email.length > 0 && <div className="text-danger" style={{ fontSize: '13px' }}>{error2.email}</div>}
                            </div>

                        </div>

                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit2}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Import"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ------------------------------Filter modal----------------------------------- */}

            <div className="offcanvas offcanvas-end" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} tabIndex="-1" id="LeadsFilterCanvas" aria-labelledby="offcanvasRightLabel">
                <div className="modal-content">
                    <div className="modal-header px-3 pt-3" style={{ borderBottomColor: color.tableBorder }}>
                        <div className="modal_title" style={{ color: color.darkText }} id="offcanvasRightLabel">Filter User</div>
                        <span type="button" id='canvaCloseId' className='modal_close' data-bs-dismiss="offcanvas" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>

                    </div>
                </div>
                <hr style={{ borderTop: "0.5px solid " + color.tableBorder, opacity: '1' }} />
                <div className="offcanvas-body">
                    <div className="mb-3">
                        <label className='modal_label' style={{ color: color.darkText }}>Status</label>
                        <select value={status} onChange={(e) => setStatus(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'  >
                            <option value="">-------Select Status-------</option>
                            <option value={'A'}>Assigned</option>
                            <option value={"UA"}>Un-Assigned</option>
                            <option value={"T"}>Trash</option>

                        </select>
                    </div>

                    <div className="mb-4">
                        <label className='modal_label' style={{ color: color.darkText }}>Lead Owner</label>
                        <Select
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={options}
                            onChange={(e) => setOwner(e)}
                            value={owner}
                        />
                    </div>

                    <div className="mb-4">
                        <label className='modal_label' style={{ color: color.darkText }}>Lead Source</label>
                        <Select
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={leadsource}
                            onChange={(e) => setFilterLeadSource(e)}
                            value={filterLeadSource}
                        />
                    </div>

                    <label className='modal_label' style={{ color: color.lightText }}>Date Range</label>
                    <div className="mb-4 mt-1">
                        <label className='modal_label' style={{ color: color.darkText }}>Start Date</label>
                        <input type="date" value={dateRange.startDate} onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className="mb-4">
                        <label className='modal_label' style={{ color: color.darkText }}>End Date</label>
                        <input type="date" value={dateRange.endDate} onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className="pt-4">

                        <button className="primaryButton me-3" id='filterBtn' onClick={applyFilter}>Apply filter</button>
                        <button className="secondaryButton" onClick={clearFilter}>Clear filter</button>

                    </div>
                </div>
            </div>

            {/* ------------------------------Delete modal----------------------------------- */}

            <div className="modal fade" id={"leadTrashModal"} tabIndex="-1" aria-labelledby="leadTrashModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to Trash selected Leads ?</div>
                        </div>
                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id="dltBtn" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleLeadTrash}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Proceed"}</button>
                        </div>
                    </div>
                </div>
            </div>


            {/* ------------------------------Lead Assign modal----------------------------------- */}

            <div class="modal fade" id="leadAssignModal" tabindex="-1" aria-labelledby="leadAssignModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Change Owner</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Name <span className="text-danger">*</span></label>
                                <select value={assignTo} onChange={(e) => setAssignTo(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">----------Select User to Assign-------------</option>
                                    {users.map((d) => {
                                        return <option value={d.id} key={d.id}>{d.name}</option>
                                    })}
                                </select>
                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleLeadAssign}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Save"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ------------------------------Bulk Assign modal----------------------------------- */}

            <div class="modal fade" id="bulkAssignModal" tabindex="-1" aria-labelledby="bulkAssignModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Bulk Assign</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            <label className='modal_label' style={{ color: color.lightText }}>Date Range <span className="text-danger"></span></label>
                            <div className='mb-3'>
                                <div className="myrow justify_space_between">
                                    <div style={{ width: '48%' }}>
                                        <label className='modal_label' style={{ color: color.darkText }}>Start Date </label>
                                        <input type='date' value={dateRange2.startDate} onChange={(e) => setDateRange2({ ...dateRange2, startDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>

                                    <div style={{ width: '48%' }}>
                                        <label className='modal_label' style={{ color: color.darkText }}>End Date</label>
                                        <input type='date' value={dateRange2.endDate} onChange={(e) => setDateRange2({ ...dateRange2, endDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </div>
                            </div>

                            <div style={{ color: color.blue2, fontSize: "13px", marginBottom: '10px' }}>
                                Availabel Leads to Bulk Assign - <span style={{ color: color.red }}> {availabelLeads}</span>
                            </div>

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.lightText }}>Enter Number of Leads to Provide Individuals.</label>
                                <input placeholder='Enter number...' type='text' value={numofLeads} onChange={(e) => setNumofLeads(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <label className='modal_label' style={{ color: color.lightText }}>Select Users ( Selected - {selectedUsers.length} )</label>

                            <div style={{ color: color.darkText, fontSize: '13px', paddingRight: '5px', marginTop: '10px', display: 'flex', alignContent: 'center' }} className='usershow'>
                                <input checked={filterUsers(users).every((e, index) => e.id === selectedUsers[index]) && selectedUsers.length > 0} onChange={handleCheckAllChange2} type="checkbox" className="form-check-input" style={{ backgroundColor: color.inputBackground, border: "1px solid " + color.borderColor, marginRight: '10px' }} />
                                <div style={{ marginTop: '2px' }}>Select All</div>
                            </div>
                            <div className="myrow mt-3 justify_space_between">
                                {filterUsers(users).length > 0 ?
                                    filterUsers(users).map((u) => {
                                        return <SelectUsersList users={users} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} u={u} key={u.id} />
                                    })
                                    : null}
                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleBulkAssign}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Submit"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ------------------------------Lead Restore modal----------------------------------- */}

            <div className="modal fade" id={"leadRestoreModal"} tabIndex="-1" aria-labelledby="leadRestoreModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>
                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to Restore this Lead ? This lead will be taken back from the Lead Owner.</div>
                        </div>
                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id="restoreBtn" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleLeadRestore}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Restore"}</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AllLeads