import React, { useState, useEffect } from 'react'
import { useColorStore } from '../../../Store/ColorStore';
import { useToasts } from 'react-toast-notifications';
import { useResearchStore } from '../../../Store/ResearchStore/ResearchStore';

const Sar = () => {
    const { addToast } = useToasts();
    const { color } = useColorStore()
    const { sendSar } = useResearchStore()

    const [isLoading, setIsLoading] = useState(false)

    const [payload, setPayload] = useState({
        ns1: '',
        ns2: '',
        np: '',
        nr1: '',
        nr2: '',
        bs1: '',
        bs2: '',
        bp: '',
        br1: '',
        br2: '',
    })

    const handleSend = () => {
        if (payload.ns1.length === 0 || payload.ns2.length === 0 || payload.np.length === 0 || payload.nr1.length === 0 || payload.nr2.length === 0 || payload.bs1.length === 0 || payload.bs2.length === 0 || payload.bp.length === 0 || payload.br1.length === 0 || payload.br2.length === 0) {
            addToast('All fields Required...!', { appearance: 'error' });
        } else {
            setIsLoading(true)
            sendSar(payload)
            .then(res=>{
                setIsLoading(false)
                setPayload({
                    ...payload, ns1: '',
                    ns2: '',
                    np: '',
                    nr1: '',
                    nr2: '',
                    bs1: '',
                    bs2: '',
                    bp: '',
                    br1: '',
                    br2: '',
                })
                addToast('Success...', { appearance: 'success' });
            })
            .catch(err => {
                setIsLoading(false)
                addToast('Error...!', { appearance: 'error' });
            })
        }
    }
    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>

                <div className="myrow justify_space_between">

                    <div className="" style={{ width: '48%', color: color.darkText }}>
                        <div class="menu-title" style={{ color: color.darkText, opacity: 1, fontSize: '15px' }}>NIFTY</div>
                        <hr style={{ borderColor: color.borderColor, opacity: 1 }} />

                        <div className="m-2">
                            <label className='modal_label' style={{ color: color.darkText }}>Resistance - 2</label>
                            <input value={payload.nr2} onChange={(e) => setPayload({ ...payload, nr2: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
                        </div>

                        <div className="m-2">
                            <label className='modal_label' style={{ color: color.darkText }}>Resistance - 1</label>
                            <input value={payload.nr1} onChange={(e) => setPayload({ ...payload, nr1: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
                        </div>

                        <div className="m-2">
                            <label className='modal_label' style={{ color: color.darkText }}>Pivot</label>
                            <input value={payload.np} onChange={(e) => setPayload({ ...payload, np: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
                        </div>

                        <div className="m-2">
                            <label className='modal_label' style={{ color: color.darkText }}>Support - 1</label>
                            <input value={payload.ns1} onChange={(e) => setPayload({ ...payload, ns1: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
                        </div>

                        <div className="m-2">
                            <label className='modal_label' style={{ color: color.darkText }}>Support - 2</label>
                            <input value={payload.ns2} onChange={(e) => setPayload({ ...payload, ns2: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
                        </div>
                    </div>

                    <div className="" style={{ width: '48%', color: color.darkText }}>
                        <div class="menu-title" style={{ color: color.darkText, opacity: 1, fontSize: '15px' }}>BANKNIFTY</div>
                        <hr style={{ borderColor: color.borderColor, opacity: 1 }} />

                        <div className="m-2">
                            <label className='modal_label' style={{ color: color.darkText }}>Resistance - 2</label>
                            <input value={payload.br2} onChange={(e) => setPayload({ ...payload, br2: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
                        </div>

                        <div className="m-2">
                            <label className='modal_label' style={{ color: color.darkText }}>Resistance - 1</label>
                            <input value={payload.br1} onChange={(e) => setPayload({ ...payload, br1: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
                        </div>

                        <div className="m-2">
                            <label className='modal_label' style={{ color: color.darkText }}>Pivot</label>
                            <input value={payload.bp} onChange={(e) => setPayload({ ...payload, bp: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
                        </div>

                        <div className="m-2">
                            <label className='modal_label' style={{ color: color.darkText }}>Support - 1</label>
                            <input value={payload.bs1} onChange={(e) => setPayload({ ...payload, bs1: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
                        </div>

                        <div className="m-2">
                            <label className='modal_label' style={{ color: color.darkText }}>Support - 2</label>
                            <input value={payload.bs2} onChange={(e) => setPayload({ ...payload, bs2: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
                        </div>

                    </div>

                </div>

                <div class="mt-3 m-2 text-center" style={{ borderTopColor: color.tableBorder }}>
                    <button type="button" class="primaryButton w-50" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSend}>{isLoading ?
                        <>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                            Please wait...
                        </>
                        : "SEND"}
                    </button>
                </div>

            </div>
        </>
    )
}

export default Sar