import React from 'react'
import './PerformanceList.css'
import { useColorStore } from '../../../../Store/ColorStore';
import Avatar from '@mui/material/Avatar';
import { numFormatter } from '../../../../Export';

const PerformanceList = (props) => {
    const {data} = props
    const { color } = useColorStore()
    return (<>
        <div className="myrow align_center mt-3">
            <div><Avatar src={data.proimg} alt={'Test'} sx={{ bgcolor: "transparent", color: "#0C768A", fontSize: 14, fontWeight: 700, width: '40px', height: '40px', border: "1px solid "+color.blue }} /></div>
            <div className='px-3'>
                <div className="reportingTitle">
                    <div style={{ fontSize: '14px', color: color.darkText,letterSpacing:'0.3px',lineHeight:'20px' }}>{data.name}</div>
                    <div style={{ fontSize: '11px', color: color.lightText,textTransform:'capitalize' }}>{data.user_type.toLowerCase()}</div>
                </div>
            </div>

            <div style={{ marginLeft: 'auto', color: color.green }}>₹ {numFormatter(data.amount)}</div>
        </div>

        <hr style={{borderColor:color.borderColor}} />
        </>)
}

export default PerformanceList