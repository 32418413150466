import React, { useState, useEffect } from 'react'
import './NotificationContainer.css'
import { Icon } from '@iconify/react';
import { useColorStore } from './../../../Store/ColorStore';
import { useNotificationStore } from '../../../Store/NotificationStore/NotificationStore';
import NotificationList from './NotificationList';

const NotificationContainer = () => {
    const { color } = useColorStore()
    const { unread_notification, fetchUnreadNotification } = useNotificationStore()
    useEffect(() => {
        fetchUnreadNotification()
    }, [])
    return (
        <>
            {unread_notification.length > 0 ?
                unread_notification.map((m) => {
                    return <NotificationList data={m} key={m.id} />
                })
                : <div className='p-3' style={{ color: color.green, fontSize: '14px' }}>👍 Yay ! There's no notification for today.</div>
            }
        </>
    )
}

export default NotificationContainer