import React, { useEffect } from 'react'
import { useColorStore } from '../../../../../Store/ColorStore'
import { useState } from 'react'
import { useLeadDetailStore } from '../../../../../Store/LeadStore/LeadDetailStore'
import { useToasts } from 'react-toast-notifications'
import ReactPaginate from 'react-paginate'
import { Suspense } from 'react'
import Ripples from 'react-ripples';
import { Icon } from '@iconify/react'
import { useProductStore } from './../../../../../Store/ProductStore/ProductStore';
import { stateOptions, userSegrigation, userType } from '../../../../../Export'
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import moment from 'moment'
import { useUserPermissionStore } from '../../../../../Store/UserPermissionStore/UserPermissionStore'
const ServiceList = React.lazy(() => { return import('./ServiceList') });
const animatedComponents = makeAnimated(); 

const Services = (props) => {
    const { addToast } = useToasts();
    const { product, fetchProduct } = useProductStore()
    const { users, isLoading, setIsLoading, leadUser, leadEnquiry, userId } = props
    const { color } = useColorStore()
    const { userServices, freeTrialStart, paidServiceStart, fetchUserServices, deleteUserServices, FreebiesServiceStart } = useLeadDetailStore()

    useEffect(() => {
        if (userServices.length > 0) {
            if (userServices[0].enquiry !== leadEnquiry.id) {
                fetchUserServices(leadEnquiry.id)
            }
        } else {
            fetchUserServices(leadEnquiry.id)
        }
    }, [leadEnquiry])

    // -----------------Permission Work------------------
    let token = JSON.parse(localStorage.getItem('user'))
    const [permission, setPermission] = useState(false)
    const [permission2, setPermission2] = useState(false)
    const { userPermissions } = useUserPermissionStore()

    useEffect(() => {
        let d = userPermissions.find((f) => {
            return f.permission_name === 'SERVICE_ADD_PERMISSION'
        })
        let x = userSegrigation.find((f) => {
            return f.key === token.user_type
        })
        if (d && x) {
            setPermission(d[x.name])
        }
    }, [userPermissions])

    useEffect(() => {
        let d = userPermissions.find((f) => {
            return f.permission_name === 'SERVICE_PERMISSION'
        })
        let x = userSegrigation.find((f) => {
            return f.key === token.user_type
        })
        if (d && x) {
            setPermission2(d[x.name])
        }
    }, [userPermissions])


    // ======================================Filter Works=======================================
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: color.inputBackground,
            // match with the menu
            borderRadius: 4,
            // Overwrittes the different states of border
            borderColor: color.borderColor,
            fontSize: 14,
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: color.borderColor
            }
        }),
        menu: base => ({
            ...base,
            background: color.inputBackground,
            color: color.darkText,
            fontSize: 14,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);

            return {
                ...styles,
                backgroundColor: isFocused ? color.blue : null,
                color: isFocused ? "#fff" : null,
                // color: "#333333"
            };
        }
    };
    const [filterMode, setFilterMode] = useState(false)
    const [filterData, setFilterData] = useState([])
    const [type, setType] = useState('')
    const [filterProduct, setFilterProduct] = useState([])
    const [status, setStatus] = useState("")
    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: '',
    })
    const [options, setOptions] = useState([])
    useEffect(() => {
        setOptions([])
        let d = []
        for (let i = 0; i < product.length; i++) {
            const u = product[i];
            d.push({
                value: u.id, label: u.name
            })
        }
        setOptions(d)
    }, [product])

    const handleRemoveFilterProduct = (index) => {
        setFilterProduct([
            ...filterProduct.slice(0, index),
            ...filterProduct.slice(index + 1, filterProduct.length)
        ]);
    }

    const applyFilter = () => {
        let data = userServices
        if (status.length > 0) {
            data = data.filter((f) => {
                return f.service_state === status;
            });
        }
        if (type.length > 0) {
            data = data.filter((f) => {
                return f.service_status === type;
            });
        }
        if (dateRange.startDate.length > 0 && dateRange.endDate.length > 0) {
            data = data.filter((f) => {
                const date = new Date(f.created_at).setHours(0, 0, 0, 0)
                return date <= new Date(dateRange.endDate).setHours(0, 0, 0, 0) && new Date(dateRange.startDate).setHours(0, 0, 0, 0) <= date
            });
        }
        if (filterProduct.length > 0) {
            let d = []
            for (let i = 0; i < filterProduct.length; i++) {
                const e = filterProduct[i];
                d.push(e.value)
            }
            data = data.filter((product) => {
                return d.indexOf(product.product_id) > -1;
            });

        }
        setFilterData(data)
    }

    useEffect(() => {
        if (type.length > 0 || filterProduct.length > 0 || status.length > 0 || dateRange.startDate.length > 0 && dateRange.endDate.length > 0) {
            setFilterMode(true)
            applyFilter()
        }
        else {
            setFilterMode(false)
            setFilterData([])
        }
    }, [type, status, dateRange, filterProduct])

    const clearFilter = () => {
        setStatus('')
        setFilterProduct([])
        setType("")
        setDateRange({ ...dateRange, startDate: '', endDate: '' })
        setFilterMode(false)
        setFilterData([])
    }

    // ======================================Pagination Works=======================================

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (filterMode) {
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(filterData.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(filterData.length / itemsPerPage));
            setitemLength(filterData.length)
            setItemEndSet(endOffset)
        } else {

            // Fetch items from another resources.
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(userServices.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(userServices.length / itemsPerPage));
            setItemEndSet(endOffset)
            setitemLength(userServices.length)
        }
    }, [itemOffset, itemsPerPage, userServices, filterMode, filterData,]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % userServices.length;
        setItemOffset(newOffset);
    };

    useEffect(() => {
        if (product.length === 0) {
            fetchProduct()
        }
    }, [])


    const [payload, setPayload] = useState({
        service_type: '',
        invoice_date: new Date().toISOString().substr(0, 10),
        tax_type: 'I',
        total_price: '',
        discounted_amount: '',
        discount: '',
        amount_recieved: '',
        gst: '',
        total_amount: '',
        payment_mode: '',
        client_gst: leadEnquiry ? leadEnquiry.gst ? leadEnquiry.gst : '' : '',
        address: leadEnquiry ? leadEnquiry.address ? leadEnquiry.address : '' : '',
        state: leadEnquiry ? leadEnquiry.state ? leadEnquiry.state : '' : ''
    })

    const clearState = () => {
        setPayload({
            ...payload,
            service_type: '',
            invoice_date: '',
            tax_type: 'I',
            total_price: '',
            discounted_amount: '',
            discount: '',
            amount_recieved: '',
            gst: '',
            total_amount: '',
            payment_mode: '',
            client_gst: leadEnquiry ? leadEnquiry.gst ? leadEnquiry.gst : '' : '',
            address: leadEnquiry ? leadEnquiry.address ? leadEnquiry.address : '' : '',
            payload: leadEnquiry ? leadEnquiry.states ? leadEnquiry.states : '' : ''
        })
    }

    const inputArr = [
        {
            value: '',
            from_date: '',
            to_date: '',
            monthly: '',
            quaterly: '',
        },
    ];

    const [arr, setArr] = useState(inputArr);

    const add_service_inpute = () => {
        setArr((s) => {
            const lastId = s[s.length - 1].id;
            return [
                ...s,
                {
                    value: '',
                    from_date: '',
                    to_date: '',
                    monthly: '',
                    quaterly: '',
                },
            ];
        });
    }

    const removeServiceInput = (i) => {
        setArr((s) => {
            const newArr = s.slice();
            newArr.splice(i, 1)
            return newArr;
        });
    }

    const handleServiceAdd = (e) => {
        const index = e.target.id;
        setArr((s) => {
            const newArr = s.slice();
            newArr[index].value = e.target.value;
            return newArr;
        });
    }

    const handleServiceFromDateAdd = (e) => {
        const index = e.target.id;
        setArr((s) => {
            const newArr = s.slice();
            newArr[index].from_date = e.target.value;
            return newArr;
        });
    }

    const handleServiceToDateAdd = (e) => {
        const index = e.target.id;
        setArr((s) => {
            const newArr = s.slice();
            newArr[index].to_date = e.target.value;
            return newArr;
        });
    }

    useEffect(() => {
        if (payload.total_price.length > 0 && payload.tax_type.length > 0 && payload.discounted_amount.length > 0) {

            if (payload.tax_type === 'I') {
                let discount = parseFloat(payload.total_price) - parseFloat(payload.discounted_amount)
                let amount_recieved = parseFloat(payload.discounted_amount) * 100 / 118
                let gst = parseFloat(payload.discounted_amount) * 18 / 118
                let total = parseFloat(payload.discounted_amount)
                setPayload({ ...payload, discount: parseFloat(discount).toFixed(2), amount_recieved: parseFloat(amount_recieved).toFixed(2), gst: parseFloat(gst).toFixed(2), total_amount: parseFloat(total).toFixed(2) })
            } else if (payload.tax_type === 'E') {
                let discount = parseFloat(payload.total_price) - parseFloat(payload.discounted_amount)
                let amount_recieved = parseFloat(payload.discounted_amount)
                let gst = parseFloat(payload.discounted_amount) * 18 / 100
                let total = parseFloat(payload.discounted_amount) * 118 / 100

                setPayload({ ...payload, discount: discount, amount_recieved: amount_recieved, gst: gst, total_amount: total })
            } else {
                setPayload({ ...payload, discount: '', amount_recieved: '', gst: '', total_amount: '' })
            }

        } else {
            setPayload({ ...payload, discount: '', amount_recieved: '', gst: '', total_amount: '' })
        }

    }, [payload.discounted_amount, payload.tax_type, payload.total_price])

    const handleService = () => {
        setIsLoading(true)
        if (leadUser.email.length > 0) {
            if (payload.service_type.length === 0 || arr.length === 0) {
                setIsLoading(false)
                addToast("All Fields Required...", { appearance: 'error' })
            }
            else {
                if (payload.service_type === 'F') {
                    freeTrialStart(leadEnquiry.id, arr, payload)
                        .then(res => {
                            fetchUserServices(leadEnquiry.id)
                            setArr((s) => {
                                const newArr = inputArr;
                                return newArr;
                            });
                            clearState()
                            setIsLoading(false)
                            addToast("Free Trial Start Successfully. !", { appearance: 'success' });
                        })
                        .catch(err => {
                            setIsLoading(false)
                            if (err.response.status === 500) {
                                addToast("All Fields Required...!", { appearance: 'error' });
                            } else {
                                addToast(err.response.data, { appearance: 'error' });
                            }
                        })

                } else if (payload.service_type === 'P' || payload.service_type === 'R') {
                    if (payload.tax_type.length === 0 || payload.total_price.length === 0 || payload.discounted_amount.length === 0 || payload.total_amount.length === 0 || payload.discount.length === 0 || payload.amount_recieved.length === 0 || payload.gst.length === 0 || payload.address.length === 0 || payload.state.length === 0 || payload.payment_mode.length === 0 || arr[0].from_date.length === 0 || arr[0].to_date.length === 0 || arr[0].value.length === 0) {
                        setIsLoading(false)
                        addToast("All Fields Required...!", { appearance: 'error' });
                    } else {
                        if (parseFloat(payload.total_price) < parseFloat(payload.discounted_amount)) {
                            setIsLoading(false)
                            addToast("Please Check your Discount...!", { appearance: 'error' });
                        }
                        else {
                            setIsLoading(true)
                            paidServiceStart(leadEnquiry.id, arr, payload)
                                .then(res => {
                                    fetchUserServices(leadEnquiry.id)
                                    setArr((s) => {
                                        const newArr = inputArr;
                                        return newArr;
                                    });
                                    clearState()
                                    setIsLoading(false)
                                    addToast("Service Start Successfully. !", { appearance: 'success' });
                                })
                                .catch(err => {
                                    setIsLoading(false)
                                    addToast(err.response.data, { appearance: 'error' });
                                })
                        }
                    }
                }
            }
        } else {
            setIsLoading(false)
            addToast("Email Id Required. First Update email.", { appearance: 'error' });
        }
    }

    // **************************************************************************************

    const [pr, setPr] = useState([])
    const [d, setd] = useState({
        from_date: '',
        to_date: '',
    })

    const handleFreebies = () => {
        if (pr.length > 0 && d.from_date.length > 0 && d.to_date.length > 0) {
            let xx = {
                pr: pr, d: d
            }
            FreebiesServiceStart(leadEnquiry.id, xx)
                .then(res => {
                    fetchUserServices(leadEnquiry.id)
                    setIsLoading(false)
                    setPr([])
                    setd({ ...d, from_date: '', to_date: '' })
                    addToast("Freebie Service Start Successfully. !", { appearance: 'success' });
                })
                .catch(err => {
                    setIsLoading(false)
                    if (err.response.status === 500) {
                        addToast("All Fields Required...!", { appearance: 'error' });
                    } else {
                        addToast(err.response.data, { appearance: 'error' });
                    }
                })
        } else {
            addToast("All Fields Required...!!!", { appearance: 'error' });
        }
    }

    return (
        <>
            <div className="table-responsive mt-3">
                <div className="text-end">
                    <Ripples color='rgba(255,255,255,0.2)' className='me-2' during={950}>
                        <button className="infoButton" data-bs-toggle="offcanvas" data-bs-target="#serviceFilterModal"><Icon icon="mdi:filter" fontSize={15} /> Filter</button>
                    </Ripples>
                    {permission &&
                        <Ripples color='rgba(255,255,255,0.2)' during={950}>
                            <button className="primaryButton" data-bs-toggle="modal" data-bs-target="#ServiceAddModal">Add New</button>
                        </Ripples>
                    }
                    {token.user_type === userType.admin &&
                        <Ripples color='rgba(255,255,255,0.2)' className='ms-2' during={950}>
                            <button className="dangerButton" data-bs-toggle="modal" data-bs-target="#FreeServiceAddModal">Freebies</button>
                        </Ripples>
                    }
                </div>

                {filterMode &&
                    <div className='filter_values_wrapper'>
                        <div className="filter_values_title" style={{ color: color.darkText }}>Filter by &nbsp;:<span onClick={clearFilter} className="clear_filter_link">Clear filter</span></div>
                        {status.length > 0 && <div className="filter_values">{status === "A" ? 'Active' : status === "P" ? 'Pending' : status === "E" ? 'Expire' : null} <Icon icon="fa6-solid:x" onClick={() => setStatus('')} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>}
                        {type.length > 0 ?
                            <div className="filter_values" >{type === "P" ? 'Paid' : type === "F" ? 'Free' : type === "R" ? 'Renew' : null} <Icon icon="fa6-solid:x" onClick={() => { setType('') }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            : null
                        }
                        {filterProduct.length > 0 ?
                            filterProduct.map((u, count) => {
                                return <div className="filter_values" key={count}>{u.label} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveFilterProduct(count) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            })
                            : null
                        }
                        {dateRange.startDate.length > 0 && dateRange.endDate.length > 0 &&
                            <div className="filter_values">
                                {moment(dateRange.startDate).format('ll')} - {moment(dateRange.endDate).format('ll')} <Icon icon="fa6-solid:x" onClick={() => { setDateRange({ ...dateRange, startDate: '', endDate: '' }) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} />
                            </div>
                        }

                    </div>
                }
                <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                    Show Entries - <input type="text" readOnly defaultValue="10" onChange={(e) => e.target.value === 0 || e.target.value === '0' ? setItemsPerPage() : setItemsPerPage(parseInt(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '35px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />
                    &nbsp; Out of - {itemLength}
                </div>

                <table className="table table-nowrap align-middle mb-2 mt-3">
                    <thead style={{ backgroundColor: color.tableHeader }}>
                        <tr style={{ color: color.darkText }}>
                            <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                S.No.
                            </th>
                            <th style={{ color: color.darkText }} scope="col">Payment Recieved By </th>
                            <th style={{ color: color.darkText }} scope="col">Segment</th>
                            <th style={{ color: color.darkText }} scope="col">Status</th>
                            <th style={{ color: color.darkText }} scope="col">Last Follow Up</th>
                            {permission2 &&
                                <th style={{ color: color.darkText }} scope="col">Action</th>
                            }
                        </tr>
                    </thead>

                    <tbody>
                        {currentItems.length > 0 ?
                            currentItems.map((c, count) => {
                                return <Suspense key={c.id} fallback={<></>}>
                                    <ServiceList data={c} count={itemOffset + count + 1} permission2={permission2} deleteUserServices={deleteUserServices} product={product} users={users} setIsLoading={setIsLoading} isLoading={isLoading} />
                                </Suspense>
                            })
                            :
                            <div style={{ color: color.darkText, fontSize: 14, textAlign: 'center' }}>No Record found....!</div>
                        }
                    </tbody>
                </table>
                {currentItems.length > 0 &&
                    <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                }

                <ReactPaginate
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="pagination-active"
                    renderOnZeroPageCount={null}
                />

            </div>

            {/* ==============================Service modal=================================== */}

            <div class="modal fade" id="ServiceAddModal" tabInex="-1" aria-labelledby="followUpModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="followUpModalLabel">New Service</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Service Type <span className="text-danger">*</span></label>
                                <select value={payload.service_type} onChange={(e) => setPayload({ ...payload, service_type: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">-----Choose Service Type-----</option>
                                    <option value="F">Free Trial</option>
                                    <option value="P">Paid</option>
                                    <option value="R">Renew</option>
                                </select>
                            </div>
                            {token.user_type === userType.admin && <>
                                {payload.service_type === 'P' || payload.service_type === 'R' ?
                                    <div className='mb-2'>
                                        <label className='modal_label' style={{ color: color.darkText }}>Invoice Date <span className="text-danger">*</span></label>
                                        <input type='date' value={payload.invoice_date} onChange={(e) => setPayload({ ...payload, invoice_date: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                    : null
                                }
                            </>
                            }

                            <div style={{ borderBottom: '1px solid ' + color.blue2, marginBottom: '10px' }}></div>
                            {arr.length > 0 ?
                                arr.map((a, i) => {
                                    return <div style={{ borderBottom: '1px solid ' + color.blue2, marginBottom: '10px' }}>
                                        <div className='mb-2'>
                                            <div className="d-flex align_center">
                                                <label className='modal_label' style={{ color: color.darkText }}>Service</label>
                                                {i === 0 ? <div className='add_inputs' onClick={add_service_inpute}>+</div> :
                                                    <div className='add_inputs2' onClick={() => removeServiceInput(i)}>x</div>}
                                            </div>
                                            <select value={a.value} id={i} onChange={handleServiceAdd} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                                <option value="">-----Choose Service-----</option>
                                                {product.map((d) => {
                                                    return <option key={d.id} value={d.id}>{d.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="myrow justify_space_between">
                                            <div style={{ width: '48%' }}>
                                                <div className='mb-2'>
                                                    <label className='modal_label' style={{ color: color.lightText }}>Start Date <span className="text-danger">*</span></label>
                                                    <input type='date' value={a.from_date} id={i} onChange={handleServiceFromDateAdd} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                                </div>
                                            </div>
                                            <div style={{ width: '48%' }}>
                                                <div className='mb-2'>
                                                    <label className='modal_label' style={{ color: color.lightText }}>End Date <span className="text-danger">*</span></label>
                                                    <input type='date' value={a.to_date} id={i} onChange={handleServiceToDateAdd} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                                : null}

                            {payload.service_type === 'P' || payload.service_type === 'R' ? <>
                                <div className='mb-2'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Total Price of Service (In Rs.) <span className="text-danger">*</span></label>
                                    <input type='text' value={payload.total_price} onChange={(e) => setPayload({ ...payload, total_price: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>
                                <div className="myrow justify_space_between">
                                    <div style={{ width: '48%' }}>
                                        <div className='mb-2'>
                                            <label className='modal_label' style={{ color: color.darkText }}>Amount after Discount (In Rs.) <span className="text-danger">*</span></label>
                                            <input type='text' value={payload.discounted_amount} onChange={(e) => setPayload({ ...payload, discounted_amount: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                        </div>
                                    </div>
                                    <div style={{ width: '48%' }}>
                                        <div className='mb-2'>
                                            <label className='modal_label' style={{ color: color.darkText }}>Discount (In Rs.) <span className="text-danger">*</span></label>
                                            <input type='text' value={payload.discount} onChange={(e) => setPayload({ ...payload, discount: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                        </div>
                                    </div>
                                </div>

                                <div className="myrow justify_space_between">
                                    <div style={{ width: '48%' }}>
                                        <div className='mb-2'>
                                            <label className='modal_label' style={{ color: color.darkText }}>Amount Recieved(In Rs.) <span className="text-danger">*</span></label>
                                            <input type='text' value={payload.amount_recieved} onChange={(e) => setPayload({ ...payload, amount_recieved: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                        </div>
                                    </div>
                                    <div style={{ width: '48%' }}>
                                        <div className='mb-2'>
                                            <label className='modal_label' style={{ color: color.darkText }}>GST <small>(18% Inc.)</small> <span className="text-danger">*</span></label>
                                            <input type='text' value={payload.gst} onChange={(e) => setPayload({ ...payload, gst: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                        </div>
                                    </div>
                                </div>

                                <div className='mb-2'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Total Payable Amount <span className="text-danger">*</span></label>
                                    <input type='text' value={payload.total_amount} onChange={(e) => setPayload({ ...payload, total_amount: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-2'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Client GST Number</label>
                                    <input type='text' value={payload.client_gst} onChange={(e) => setPayload({ ...payload, client_gst: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-2'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Address <span className="text-danger">*</span></label>
                                    <textarea value={payload.address} onChange={(e) => setPayload({ ...payload, address: e.target.value })} rows={5} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'></textarea>
                                </div>

                                <div className='mb-2'>
                                    <label className='modal_label' style={{ color: color.darkText }}>State <span className="text-danger">*</span></label>
                                    <select value={payload.state} onChange={(e) => setPayload({ ...payload, state: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                        <option value="">-----Choose State-----</option>
                                        {stateOptions.map((d, count) => {
                                            return <option key={count} value={d.key}>{d.name}</option>
                                        })}

                                    </select>
                                </div>

                                <div className='mb-2'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Payment Mode <span className="text-danger">*</span></label>
                                    <select value={payload.payment_mode} onChange={(e) => setPayload({ ...payload, payment_mode: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                        <option value="">-----Choose Payment Mode-----</option>
                                        <option value="CASH">CASH</option>
                                        <option value="GPAY">G-PAY</option>
                                        <option value="PHONEPE">PHONE PE</option>
                                        <option value="PAYTM">PAYTM</option>
                                        <option value="CREDITCARD">CREDIT CARD</option>
                                        <option value="HDFC">HDFC BANK TRANFER</option>
                                        <option value="ICICI">ICICI BANK TRANFER</option>
                                        <option value="KBL">KARNATAKA BANK TRANFER</option>
                                        <option value="SBI">SBI BANK TRANFER</option>
                                        <option value="KOTAK">KOTAK BANK TRANFER</option>
                                        <option value="IDFC">IDFC BANK TRANFER</option>
                                    </select>
                                </div>

                            </>
                                : null
                            }
                        </div>

                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleService}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Submit"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ==============================Freebies Service modal=================================== */}

            <div class="modal fade" id="FreeServiceAddModal" tabInex="-1" aria-labelledby="FreeServiceModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="FreeServiceModalLabel">Freebies</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            <div style={{ marginBottom: '10px' }}>
                                <div className='mb-2'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Service</label>
                                    <Select
                                        styles={customStyles}
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        options={options}
                                        onChange={(e) => setPr(e)}
                                        value={pr}
                                    />
                                </div>
                                <div className="myrow justify_space_between">
                                    <div style={{ width: '48%' }}>
                                        <div className='mb-2'>
                                            <label className='modal_label' style={{ color: color.lightText }}>Start Date <span className="text-danger">*</span></label>
                                            <input type='date' value={d.from_date} onChange={(e) => setd({ ...d, from_date: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                        </div>
                                    </div>
                                    <div style={{ width: '48%' }}>
                                        <div className='mb-2'>
                                            <label className='modal_label' style={{ color: color.lightText }}>End Date <span className="text-danger">*</span></label>
                                            <input type='date' value={d.to_date} onChange={(e) => setd({ ...d, to_date: e.target.value })} placeholder='Enter...' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleFreebies}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Submit"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ==============================Service Filter modal=================================== */}
            <div className="offcanvas offcanvas-end" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} tabIndex="-1" id="serviceFilterModal" aria-labelledby="serviceFilterModalLabel">
                <div className="modal-content">
                    <div className="modal-header px-3 pt-3" style={{ borderBottomColor: color.tableBorder }}>
                        <div className="modal_title" style={{ color: color.darkText }} id="offcanvasRightLabel">Filter</div>
                        <span type="button" id='canvaCloseId' className='modal_close' data-bs-dismiss="offcanvas" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>

                    </div>
                </div>
                <hr style={{ borderTop: "0.5px solid " + color.tableBorder, opacity: '1' }} />

                <div className="offcanvas-body">
                    <div className='mb-3'>
                        <label className='modal_label' style={{ color: color.darkText }}>Service Type</label>
                        <select value={type} onChange={(e) => setType(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                            <option value="">-----Choose Service Type-----</option>
                            <option value="F">Free Trial</option>
                            <option value="P">Paid</option>
                            <option value="R">Renew</option>
                        </select>
                    </div>

                    <div className='mb-3'>
                        <label className='modal_label' style={{ color: color.darkText }}>Service Status</label>
                        <select value={status} onChange={(e) => setStatus(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                            <option value="">-----Choose Service Status-----</option>
                            <option value="A">Active</option>
                            <option value="E">Expire</option>
                            <option value="P">Pending</option>
                        </select>
                    </div>

                    <div className='mb-3'>
                        <label className='modal_label' style={{ color: color.darkText }}>Product</label>
                        <Select
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={options}
                            onChange={(e) => setFilterProduct(e)}
                            value={filterProduct}
                        />
                    </div>

                    <div className="mb-3">
                        <label className='modal_label' style={{ color: color.darkText }}>Created At</label>
                        <div className="d-flex justify_space_between">
                            <div style={{ width: '48%' }}>
                                <label className='modal_label' style={{ color: color.lightText }}>From Date</label>
                                <input type='date' value={dateRange.startDate} onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>
                            <div style={{ width: '48%' }}>
                                <label className='modal_label' style={{ color: color.lightText }}>To Date</label>
                                <input type='date' value={dateRange.endDate} onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>
                        </div>
                    </div>

                    <div className="pt-4">

                        {/* <button className="primaryButton me-3" id='filterBtn' onClick={applyFilter}>Apply filter</button> */}
                        <button className="secondaryButton" onClick={clearFilter}>Clear filter</button>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Services