import React, { useEffect } from 'react'
import { useColorStore } from '../../../../../Store/ColorStore'
import { useState } from 'react'
import { useLeadDetailStore } from '../../../../../Store/LeadStore/LeadDetailStore'
import { useToasts } from 'react-toast-notifications'
import ReactPaginate from 'react-paginate'
import { Suspense } from 'react'

const HistoryList = React.lazy(() => { return import('./HistoryList') });
const History = (props) => {
    const { addToast } = useToasts();
    const { users, isLoading, setIsLoading, leadEnquiry, userId } = props
    const { color } = useColorStore()
    const { fetchLeadHistory, leadHistory, leadHistoryOther, setLeadHistory } = useLeadDetailStore()

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (leadHistoryOther) {
            const endOffset = itemOffset + itemsPerPage;
            setPageCount(leadHistoryOther.count / itemsPerPage);
            setItemEndSet(endOffset)
            setitemLength(leadHistoryOther.count)
        }
    }, [leadHistoryOther]);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1)
        const newOffset = (event.selected * itemsPerPage) % leadHistoryOther.count;
        setItemOffset(newOffset);
    };

    useEffect(() => {
        let u = `itemsPerPage=${itemsPerPage}&currentPage=${currentPage}`
        setIsLoading(true)
        fetchLeadHistory(leadEnquiry.id, u)
            .then(res => {
                setIsLoading(false)
                setLeadHistory(res)
            })
            .catch(err=>{
                setIsLoading(false)
            })

    }, [leadEnquiry, itemsPerPage, currentPage])

    return (
        <>

            <div className="table-responsive mt-3">
                <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                    Show Entries - <input type="text" readOnly defaultValue="10" onChange={(e) => e.target.value === 0 || e.target.value === '0' ? setItemsPerPage() : setItemsPerPage(parseInt(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '35px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />
                    &nbsp; Out of - {itemLength}
                </div>

                <table className="table table-nowrap align-middle mb-3 mt-3">
                    <thead style={{ backgroundColor: color.tableHeader }}>
                        <tr style={{ color: color.darkText }}>
                            <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                S.No.
                            </th>
                            <th style={{ color: color.darkText }} scope="col">Time</th>
                            <th style={{ color: color.darkText }} scope="col">Activity Log</th>
                            <th style={{ color: color.darkText }} scope="col">Service</th>
                            <th style={{ color: color.darkText }} scope="col">Duration</th>
                            <th style={{ color: color.darkText }} scope="col">Service Amount</th>
                        </tr>
                    </thead>

                    <tbody>
                        {leadHistory.length > 0 ?
                            leadHistory.map((c, count) => {
                                return <Suspense key={c.id} fallback={<></>}>
                                    <HistoryList data={c} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                </Suspense>
                            })
                            :
                            <div style={{ color: color.darkText, fontSize: 14, textAlign: 'center' }}>No Record found....!</div>
                        }
                    </tbody>
                </table>
                {leadHistory.length > 0 &&
                    <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                }

                <ReactPaginate
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="pagination-active"
                    renderOnZeroPageCount={null}
                />

            </div>



        </>
    )
}

export default History