import React, { useState, useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import DemoList from './DemoList';
import { useToasts } from 'react-toast-notifications';
import ReactPaginate from 'react-paginate';
import Loading from '../../../../Loading';
import { CSVLink } from 'react-csv';
import { useDemoStore } from './../../../../Store/WebsiteStore/DemoStore';

const Demo = () => {
    const { addToast } = useToasts();
    const { demos, fetchDemo } = useDemoStore()
    const { color } = useColorStore()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (demos.length === 0) {
            fetchDemo()
        }
    }, [])

    const [searchInput, setSearchInput] = useState("")
    const [searchParam] = useState(["name", "email"]);

    function search(items) {
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(searchInput.toLowerCase()) > -1
                );
            });
        });
    }

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (searchInput.length > 0) {
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(search(demos).slice(itemOffset, endOffset));
            setPageCount(Math.ceil(search(demos).length / itemsPerPage));
            setitemLength(search(demos).length)
            setItemEndSet(endOffset)
        }
        else {
            // Fetch items from another resources.
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(demos.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(demos.length / itemsPerPage));
            setItemEndSet(endOffset)
            setitemLength(demos.length)
        }
    }, [itemOffset, itemsPerPage, demos, searchInput]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % demos.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search" style={{ position: 'relative' }}>
                        <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} />
                        <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                    </div>
                    <Ripples color='rgba(255,255,255,0.2)' during={1000}>
                        <CSVLink data={demos} filename='Requested-Demo.csv'><button className="successButton"><Icon icon="ph:export-fill" fontSize={16} /> Export</button></CSVLink>
                    </Ripples>
                </div>

                {/* ------------------------------Table Work-------------------------------------- */}

                <div class="table-responsive">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <input type="text" defaultValue="10" onChange={(e) => e.target.value === 0 || e.target.value === '0' ? setItemsPerPage() : setItemsPerPage(parseInt(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '35px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />
                        &nbsp; Out of - {itemLength}
                    </div>
                    <table class="table align-middle">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Name</th>
                                <th style={{ color: color.darkText }} scope="col">Email</th>
                                <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {demos.length > 0 ?
                                currentItems.map((c, count) => {
                                    return <DemoList data={c} key={c.id} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                })
                                :
                                null}
                        </tbody>
                    </table>

                    {currentItems.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
            {isLoading &&
                <Loading />}

        </>
    )
}

export default Demo