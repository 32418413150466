import React, { useRef, useEffect, useState } from 'react'
import { useColorStore } from './../../../Store/ColorStore';
import { useAdminDashboardStore } from '../../../Store/DashboardStore.js/AdminDashboardStore';
import { useResearchDashboardStore } from '../../../Store/DashboardStore.js/ResearchDashboard';
import * as echarts from 'echarts';
import { Icon } from '@iconify/react';
import { numFormatter } from '../../../Export';
import PerformanceList from './../../Admin/Dashboard/PerformanceList/PerformanceList';
import ResearcherList from '../../../Store/DashboardStore.js/ResearcherList';
import Scrollbars from 'react-custom-scrollbars';
import { useProductStore } from './../../../Store/ProductStore/ProductStore';

const Dashboard = () => {
    const { product, fetchProduct } = useProductStore()
    const { fetchResearchTotals, researchTotals, fetchCallsOverview, calls_profit_overview, calls_loss_overview, clients_overview, fetchCallSeg, fetchResearcherListDashboard, performance, fetchCallsCounting, callsCounting, fetchClientsCounting, productWiseClients, segmentCallsOverviewP, segmentCallsOverviewL, fetchSegmentCallsOverview } = useResearchDashboardStore()
    const { color } = useColorStore()
    const chartRef = useRef(null);
    const chartRef3 = useRef(null);
    const chartRef4 = useRef(null);
    const chartRef5 = useRef(null);
    useEffect(() => {
        if (product.length === 0) {
            fetchProduct()
        }
    }, [])

    useEffect(() => {
        fetchResearchTotals()
        fetchCallsOverview()
        fetchCallSeg()
        fetchResearcherListDashboard()
        fetchCallsCounting()
        fetchClientsCounting()
    }, [])


    useEffect(() => {
        const chart = echarts.init(chartRef.current);
        const chart3 = echarts.init(chartRef3.current);

        // Sample data for the bar chart
        const pdata = calls_profit_overview;
        const ldata = calls_loss_overview;

        const option = {
            tooltip: {
                trigger: 'axis', backgroundColor: color.primaryBg, borderColor: 'none', color: color.darkText,
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                top: '5%',
                left: 'center', color: color.darkText, selectedMode: true,
                textStyle: {
                    color: color.lightText
                }
            },
            xAxis: {
                type: 'category',
                data: pdata.map(item => item.name),
            },
            yAxis: {
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: [color.tableBorder, color.tableBorder]
                    }
                }
            },
            series: [
                {
                    type: 'bar',
                    name: 'Profit in Rs.',
                    data: pdata.map(item => item.value),
                    showBackground: true,
                    itemStyle: {
                        color: color.blue,
                    },
                    backgroundStyle: {
                        color: color.tableBorder,
                    },
                    barWidth: 16,
                },
                {
                    type: 'bar',
                    name: 'Loss in Rs.',
                    data: ldata.map(item => item.value),
                    showBackground: true,
                    itemStyle: {
                        color: color.red,
                    },
                    backgroundStyle: {
                        color: color.tableBorder,
                    },
                    barWidth: 16,
                },

            ],
        };

        // Lead Pie Chart configuration
        const callsPieOptions = {
            title: {
                subtext: 'Current Month Performance'
            },
            tooltip: {
                show: false
            },
            legend: {
                top: '15%',
                left: 'center', color: color.darkText,
                textStyle: {
                    color: color.lightText
                }
            },
            series: [
                {
                    name: 'Calls',
                    type: 'pie',
                    radius: ['50%', '70%'],
                    center: ['50%', '65%'],
                    // adjust the start angle
                    startAngle: 180,
                    label: {
                        show: true,
                        color: "#fff",
                        position: 'inner', rotate: 'tangential',
                        formatter(param) {
                            // correct the percentage
                            return param.percent * 2 + '%';
                        }
                    },
                    data: [
                        { value: clients_overview.p, name: 'Positive', itemStyle: { color: color.blue } },
                        { value: clients_overview.n, name: 'Negative', itemStyle: { color: color.red } },
                        { value: clients_overview.un, name: 'Unexecuted', itemStyle: { color: color.borderColor } },
                        {
                            // make an record to fill the bottom 50%
                            value: clients_overview.p,
                            itemStyle: {
                                // stop the chart from rendering this piece
                                color: 'none',
                                decal: {
                                    symbol: 'none'
                                }
                            },
                            label: {
                                show: false
                            }
                        },
                        {
                            // make an record to fill the bottom 50%
                            value: clients_overview.n,
                            itemStyle: {
                                // stop the chart from rendering this piece
                                color: 'none',
                                decal: {
                                    symbol: 'none'
                                }
                            },
                            label: {
                                show: false
                            }
                        },
                        {
                            // make an record to fill the bottom 50%
                            value: clients_overview.un,
                            itemStyle: {
                                // stop the chart from rendering this piece
                                color: 'none',
                                decal: {
                                    symbol: 'none'
                                }
                            },
                            label: {
                                show: false
                            }
                        }
                    ]
                }
            ]
        };

        chart.setOption(option);
        chart3.setOption(callsPieOptions);

        // Clean up the chart when the component is unmounted
        return () => {
            chart.dispose();
            chart3.dispose();
        };
    }, [color, calls_profit_overview, clients_overview]);

    useEffect(() => {
        const chart5 = echarts.init(chartRef5.current);
        if (segmentCallsOverviewL.length > 0 || segmentCallsOverviewP.length > 0) {
            // Sample data for the bar chart
            const pdata = segmentCallsOverviewP;
            const ldata = segmentCallsOverviewL;
            const option2 = {
                tooltip: {
                    trigger: 'axis', backgroundColor: color.primaryBg, borderColor: 'none', color: color.darkText,
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    top: '5%',
                    left: 'center', color: color.darkText, selectedMode: true,
                    textStyle: {
                        color: color.lightText
                    }
                },
                xAxis: {
                    type: 'category',
                    data: pdata.map(item => item.name),
                },
                yAxis: {
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: [color.tableBorder, color.tableBorder]
                        }
                    }
                },
                series: [
                    {
                        type: 'bar',
                        name: 'Profit in Rs.',
                        data: pdata.map(item => item.value),
                        showBackground: true,
                        itemStyle: {
                            color: color.blue,
                        },
                        backgroundStyle: {
                            color: color.tableBorder,
                        },
                        barWidth: 16,
                    },
                    {
                        type: 'bar',
                        name: 'Loss in Rs.',
                        data: ldata.map(item => item.value),
                        showBackground: true,
                        itemStyle: {
                            color: color.red,
                        },
                        backgroundStyle: {
                            color: color.tableBorder,
                        },
                        barWidth: 16,
                    },

                ],
            };
            chart5.setOption(option2);
            // Clean up the chart when the component is unmounted
            return () => {
                chart5.dispose();
            };
        }
    }, [color, segmentCallsOverviewP]);

    const [first, setfirst] = useState()

    useEffect(() => {
        if (product.length > 0) {
            setfirst(product[0].id)
        }
    }, [product])

    useEffect(() => {
        if (first) {
            fetchSegmentCallsOverview(first)
        }
    }, [first])

    return (
        <>
            <div className="myrow justify_space_between">

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="solar:money-bag-bold" fontSize={32} color={color.blue} /></div>
                        </div>
                        <div>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Calls</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Current Month</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(researchTotals.total_calls_m)}</div>
                        </div>
                    </div>
                </div>

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="mdi:user-tie" fontSize={32} color={color.blue} /></div>
                        </div>
                        <div>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Calls</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>For Today</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(researchTotals.total_calls_t)}</div>
                        </div>
                    </div>
                </div>

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="mdi:user-online" fontSize={32} color={color.blue} /></div>
                        </div>
                        <div>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Positive Calls</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Current Month</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(researchTotals.positive_calls)}</div>
                        </div>
                    </div>
                </div>

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="ri:service-fill" fontSize={32} color={color.blue} /></div>
                        </div>
                        <div>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Negative Calls</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Current Month</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(researchTotals.negative_calls)}</div>
                        </div>
                    </div>
                </div>

            </div>
            {/* **********************Second Block Dashboard**************************** */}
            <div className="myrow justify_space_between mb-4">
                <div className="business_block1 p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <h6 style={{ color: color.darkText, marginTop: '5px' }}>Calls Overview</h6>
                    <div ref={chartRef} style={{ width: '100%', height: '400px' }} />
                </div>

                <div className="business_block2 p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <h6 style={{ color: color.darkText, marginTop: '5px', marginBottom: '10px' }}>Researcher's List</h6>
                    <hr style={{ borderColor: color.borderColor }} />
                    {performance.map((f, count) => {
                        return <ResearcherList data={f} key={count} />
                    })}

                </div>
            </div>

            {/* **********************Third Block Dashboard**************************** */}
            <div className="myrow justify_space_between" style={{ paddingTop: '10px' }}>

                <div className="dash_block_3 p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <Scrollbars autoHide={false}
                        renderThumbVertical={props => <div {...props} style={{ backgroundColor: "rgba(255,255,255,0.2)", borderRadius: '5px', height: '500px' }} />}
                    >
                        <h6 style={{ color: color.darkText, marginTop: '5px', marginBottom: '30px' }}>Product Wise Calls</h6>
                        <div className="table-responsive mt-3">
                            <table className="table table-nowrap align-middle">
                                <thead>
                                    <tr style={{ color: color.darkText, backgroundColor: color.tableHeader }}>
                                        <th style={{ color: color.darkText }} scope="col">Name</th>
                                        <th style={{ color: color.darkText }} scope="col">Today</th>
                                        <th style={{ color: color.darkText }} scope="col">Month</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {callsCounting.map((d) => {
                                        return <tr style={{ color: color.lightText }} key={d.id}>
                                            <td style={{ color: color.lightText, borderBottom: 'none' }}>{d.name}</td>
                                            <td style={{ color: color.lightText, borderBottom: 'none' }}>{d.today}</td>
                                            <td style={{ color: color.lightText, borderBottom: 'none' }}>{d.month}</td>
                                        </tr>
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </Scrollbars>
                </div>

                <div className="dash_block_3 p-3" style={{ backgroundColor: color.secondaryBg }}>

                    <h6 style={{ color: color.darkText, marginTop: '5px' }}>Calls Performance</h6>
                    <div ref={chartRef3} style={{ width: '100%', height: '400px' }} />

                    <div className='text-center' style={{ marginTop: '-180px' }}>
                        <div style={{ color: color.lightText, opacity: 0.8 }} className='menu-title p-0'>Total Calls</div>
                        <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(clients_overview.p + clients_overview.n + clients_overview.un)}</div>
                    </div>

                    <div className='myrow mt-5' style={{ justifyContent: 'space-evenly' }}>
                        <div className='w-25'>
                            <div className="progress nHeight mb-2" role="progressbar" aria-label="Example 1px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                <div className="progress-bar" style={{ width: (clients_overview.p * 100 / (clients_overview.p + clients_overview.n + clients_overview.un)) + "%", backgroundColor: color.blue }}></div>
                            </div>
                            <div style={{ color: color.lightText, opacity: 0.8, fontWeight: 'normal' }} className='menu-title p-0 pt-1'>Positive</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '16px' }}>{clients_overview.p}</div>
                        </div>

                        <div className='w-25'>
                            <div className="progress nHeight mb-2" role="progressbar" aria-label="Example 1px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                <div className="progress-bar" style={{ width: (clients_overview.n * 100 / (clients_overview.p + clients_overview.n + clients_overview.un)) + "%", backgroundColor: color.red }}></div>
                            </div>
                            <div style={{ color: color.lightText, opacity: 0.8, fontWeight: 'normal' }} className='menu-title p-0 pt-1'>Negative</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '16px' }}>{clients_overview.n}</div>
                        </div>

                        <div className='w-25'>
                            <div className="progress nHeight mb-2" role="progressbar" aria-label="Example 1px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                <div className="progress-bar" style={{ width: (clients_overview.un * 100 / (clients_overview.p + clients_overview.n + clients_overview.un)) + "%", backgroundColor: color.borderColor }}></div>
                            </div>
                            <div style={{ color: color.lightText, opacity: 0.8, fontWeight: 'normal' }} className='menu-title p-0 pt-1'>Unexecuted/No Updates</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '16px' }}>{clients_overview.un}</div>
                        </div>
                    </div>
                </div>

                <div className="dash_block_3 p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <Scrollbars autoHide={false}
                        renderThumbVertical={props => <div {...props} style={{ backgroundColor: "rgba(255,255,255,0.2)", borderRadius: '5px', height: '500px' }} />}
                    >
                        <h6 style={{ color: color.darkText, marginTop: '5px', marginBottom: '30px' }}>Active Clients</h6>
                        <div className="table-responsive mt-3">
                            <table className="table table-nowrap align-middle">
                                <thead>
                                    <tr style={{ color: color.darkText, backgroundColor: color.tableHeader }}>
                                        <th style={{ color: color.darkText }} scope="col">Product</th>
                                        <th style={{ color: color.darkText }} scope="col">No. of Clients</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productWiseClients.map((d) => {
                                        return <tr style={{ color: color.lightText }} key={d.id}>
                                            <td style={{ color: color.lightText, borderBottom: 'none' }}>{d.name}</td>
                                            <td style={{ color: d.clients > 0 ? color.green : color.red, borderBottom: 'none' }}>{d.clients}</td>
                                        </tr>
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </Scrollbars>
                </div>

            </div>

            {/* **********************Fourth Block Dashboard**************************** */}
            <div className="category_body mb-4 p-3" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between">
                    <h6 style={{ color: color.darkText, marginTop: '5px' }}>Product Wise Calls Overview</h6>
                    <div>
                        <select type="text" value={first} onChange={(e) => setfirst(e.target.value)} className='searchInput' style={{ fontSize: '13px', color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '200px', padding: '5px', borderRadius: '2px' }}>
                            {
                                product.map((d) => {
                                    return <option value={d.id} key={d.id}>{d.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div ref={chartRef5} style={{ width: '100%', height: '400px' }} />
            </div>


        </>
    )
}

export default Dashboard