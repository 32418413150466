import React, { useState, useRef } from 'react'
import { useColorStore } from '../../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { useDynamicEmailStore } from '../../../../Store/SpaceStore/DynamicEmailStore';
import { useToasts } from 'react-toast-notifications';
import { fileUrl } from '../../../../Export';
import parse from 'html-react-parser';
import { EmailEditor } from 'react-email-editor';

const DynamicEmailList = (props) => {
    const emailEditorRef = useRef(null);
    const { addToast } = useToasts();
    const { deleteDynamicEmail, fetchDynamicEmail, updateDynamicEmail } = useDynamicEmailStore()
    const { color } = useColorStore()
    const { data, count, isLoading, setIsLoading } = props

    const [payload, setPayload] = useState({
        name: data.email_name,
        subject: data.email_subject,
    })

    const [error, setError] = useState(false)
    const [content, setcontent] = useState("")
    const [jdata, setJdata] = useState("")

    const handleSubmit = async () => {
        if (payload.name.length === 0 || payload.subject.length === 0) {
            setError(true)
        } else {
            setError(false)
            setIsLoading(true)
            await emailEditorRef.current?.editor?.exportHtml((design) => {
                const { html } = design
                setcontent(html)
            });
            await emailEditorRef.current?.editor?.saveDesign((design) => {
                setJdata(JSON.stringify(design))
            });
            let newData = {
                email_name: payload.name,
                email_subject: payload.subject,
                content: content,
                jdata: jdata,
            }
            setIsLoading(false)
            if (newData.content.length > 0 && newData.jdata.length > 0) {
                setIsLoading(true)
                await updateDynamicEmail(newData, data.id)
                    .then(res => {
                        fetchDynamicEmail()
                        setIsLoading(false)
                        setPayload({ ...payload, name: payload.name, subject: payload.subject })
                        addToast('Success...', { appearance: 'success' });
                    }).catch(err => {
                        console.log(err);
                        setIsLoading(false)
                        addToast('Error...', { appearance: 'error' });
                    })
            }

        }
    }

    const handleDelete = () => {
        let d = document.getElementById("dltBtn" + data.id)
        d.click()
        setIsLoading(true)
        deleteDynamicEmail(data.id)
            .then(res => {
                fetchDynamicEmail()
                setIsLoading(false)
                addToast('Success...', { appearance: 'success' });
            }).catch(err => {
                console.log(err);
                setIsLoading(false)
                addToast('Error...', { appearance: 'error' });
            })
    }

    const onReady = () => {
        emailEditorRef.current.editor.loadDesign(JSON.parse(data.jdata));
    };


    return (
        <>
            <tr style={{ color: color.darkText }}>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
                    {count}
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>
                    <a style={{ color: color.darkText }}>{data.email_name}</a>
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>
                    <a style={{ color: color.darkText }}>{data.email_subject}</a>
                </td>

                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}><button data-bs-toggle="modal" data-bs-target={"#previewModal" + data.id} className='successButton'>Preview <Icon icon="icon-park-outline:preview-open" fontSize={17} /></button></td>

                <td style={{ borderBottom: '1px solid ' + color.tableBorder }}>
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <span type="button" class="px-2" data-bs-toggle="modal" data-bs-target={"#EditModal" + data.id} style={{ color: color.blue }}><Icon icon="akar-icons:edit" fontSize={18} /></span>
                        </li>
                        <li class="list-inline-item">
                            <span type={'button'} class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal" + data.id} style={{ color: color.red }}><Icon icon="ep:delete" fontSize={17} /></span>
                        </li>
                    </ul>
                </td>
            </tr>

            {/* ----------------------------Delete Modal------------------------ */}

            <div class="modal fade" id={"categoryDeleteModal" + data.id} tabindex="-1" aria-labelledby="categoryDeleteModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to delete ( {data.email_name} ) Email Template ? You would'nt be able to retrieve this.</div>
                        </div>

                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id={"dltBtn" + data.id} style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleDelete}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Delete"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* --------------------------------------Preview Email------------------------------------------ */}

            <div class="modal fade" id={"previewModal" + data.id} tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-xl">
                    <div class="modal-content" style={{ border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" id="categoryModalLabel">Preview</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            {parse(data.content)}
                        </div>

                    </div>
                </div>
            </div>


            {/* --------------------------------------Edit Dynamic Email------------------------------------------ */}

            <div class="modal fade" id={"EditModal" + data.id} tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-xl">
                    <div class="modal-content" style={{ border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" id="categoryModalLabel">Update Email</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="category_body" style={{ backgroundColor: "#fff" }}>
                                {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                                <div className='mb-3'>
                                    <label className='modal_label'>Email Name <span className="text-danger">*</span></label>
                                    <input placeholder='Enter Email Name' value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })} style={{ border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-4'>
                                    <label className='modal_label'>Subject <span className="text-danger">*</span></label>
                                    <input placeholder='Enter Subject' value={payload.subject} onChange={(e) => setPayload({ ...payload, subject: e.target.value })} style={{ border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className="mb-4">
                                    <label className='modal_label'>Content <span className="text-danger">*</span></label>
                                    <EmailEditor ref={emailEditorRef} onReady={onReady} />
                                </div>

                                <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                    <>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                        Please wait...
                                    </>

                                    : "Update"}</button>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default DynamicEmailList