import React, { useState } from 'react'
import { useColorStore } from '../../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { useUserStore } from '../../../../Store/UserStore/UserStore'
import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom';
import { AdminPath } from './../../AdminRoutes/AdminPath';
import moment from 'moment';

const UsersList = (props) => {
    const { addToast } = useToasts();
    const { updateUsers, deleteUsers, fetchUsers, users } = useUserStore()
    const { color } = useColorStore()
    const { data, count, isLoading, setIsLoading, cc } = props
    const [error, setError] = useState(false)
    const [payload, setPayload] = useState({
        name: data.name,
        email: data.email,
        contact: data.contact,
        user_type: data.user_type,
        reporting_to: data.user_related.length > 0 ? data.user_related[0].to : '',
    })

    const handleSubmit = () => {
        if (payload.name.length === 0 || payload.email.length === 0 || payload.contact.length === 0 || payload.user_type.length === 0) {
            setError(true)
        } else {
            setError(false)
            cc.click()
            setIsLoading(true)
            updateUsers(payload, data.id)
                .then(res => {
                    fetchUsers()
                    setIsLoading(false)
                    setPayload({
                        ...payload, name: payload.name,
                        email: payload.email,
                        contact: payload.contact,
                        password: payload.password,
                        user_type: payload.user_type,
                        reporting_to: payload.reporting_to,
                    })
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }
    }

    const handleDelete = () => {
        let d = document.getElementById("dltBtn")
        d.click()
        setIsLoading(true)
        deleteUsers(data.id)
            .then(res => {
                fetchUsers()
                setIsLoading(false)
                addToast('Success...', { appearance: 'success' });
            }).catch(err => {
                console.log(err);
                setIsLoading(false)
                addToast('Error...', { appearance: 'error' });
            })
    }

    const findReporting = (id) => {
        let d = users.find((f) => {
            return f.id === id
        })
        if (d) {
            return d.name
        } else {
            d = '-'
        }
    }

    return (
        <>
            <tr style={{ color: color.darkText }}>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
                    {count}
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
                    <Link to={AdminPath.USERS+'/'+data.id} style={{ color: color.darkText }}><Icon icon="solar:user-outline" fontSize={14} color={color.blue} /> {data.name} <br /> <span style={{ fontSize: '11px', color: color.lightText, display: 'flex', alignItems: 'center' }}><Icon icon="uiw:time-o" color={color.lightText} fontSize={11} /> <span style={{ marginLeft: '6px' }}>{moment(data.created_at).format('lll')}</span></span></Link>
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}><Icon icon="fluent:call-28-regular" fontSize={14} color={color.blue} /> {data.contact}<br />{data.email ? <> <Icon icon="fluent:mail-24-regular" fontSize={14} color={color.blue} /> <span style={{ fontSize: '13px', color: color.lightText }}> {data.email} </span></> : ''}</td>
                <td style={{borderBottom: '1px solid ' + color.tableBorder }}>{data.is_active?<span class="badge bg-success">&nbsp; Active &nbsp;</span>:<span class="badge bg-danger">Inactive</span>}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.user_type}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.user_related.length > 0 ? findReporting(data.user_related[0].to) : '-'}</td>

                <td style={{ borderBottom: '1px solid ' + color.tableBorder }}>
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <span type="button" className="px-2" data-bs-toggle="modal" data-bs-target={"#categoryModal" + data.id} style={{ color: color.blue }}><Icon icon="akar-icons:edit" fontSize={18} /></span>
                        </li>
                        <li className="list-inline-item">
                            <span type={'button'} className="px-2" data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal" + data.id} style={{ color: color.red }}><Icon icon="solar:user-block-bold-duotone" fontSize={17} /></span>
                        </li>

                    </ul>
                </td>
            </tr>

            {/* ----------------------Update Modal---------------------------- */}

            <div className="modal fade" id={"categoryModal" + data.id} tabIndex="-1" aria-labelledby={"categoryModalLabel"+data.id} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div className="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }} id={"categoryModalLabel"+data.id}>Update Category</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Name <span className="text-danger">*</span></label>
                                <input placeholder='Enter Name' value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Email <span className="text-danger">*</span></label>
                                <input placeholder='Enter Email' value={payload.email} onChange={(e) => setPayload({ ...payload, email: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Contact <span className="text-danger">*</span></label>
                                <input placeholder='Enter Phone' value={payload.contact} onChange={(e) => setPayload({ ...payload, contact: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>User Type <span className="text-danger">*</span></label>
                                <select value={payload.user_type} onChange={(e) => setPayload({ ...payload, user_type: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    
                                    <option value="ADMIN" disabled>ADMIN</option>
                                    <option value="SUB ADMIN">SUB ADMIN</option>
                                    <option value="RESEARCHER">RESEARCHER</option>
                                    <option value="SALES MANAGER">SALES MANAGER</option>
                                    <option value="SALES EXECUTIVE">SALES EXECUTIVE</option>
                                    <option value="COMPLIANCE">COMPLIANCE</option>
                                    <option value="HR">HR</option>
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Reporting To</label>
                                <select value={payload.reporting_to} onChange={(e) => setPayload({ ...payload, reporting_to: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">----------Select Reporting To-------------</option>
                                    {users.map((d) => {
                                        return <option value={d.id} key={d.id}>{d.name}</option>
                                    })}
                                </select>
                            </div>

                        </div>
                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Update"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------------------------Delete Modal------------------------ */}

            <div className="modal fade" id={"categoryDeleteModal" + data.id} tabIndex="-1" aria-labelledby="categoryDeleteModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to deactivate ( {data.name} ) User ?</div>
                        </div>


                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id="dltBtn" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleDelete}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Proceed"}</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default UsersList