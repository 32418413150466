import React, { useState, useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import ComplaintList from './ComplaintList';
import { useToasts } from 'react-toast-notifications';
import Loading from './../../../../Loading';
import { useComplaintStore } from './../../../../Store/WebsiteStore/ComplaintStore';
import ReactPaginate from 'react-paginate';

const Complaint = () => {
    const { addToast } = useToasts();
    const { complaint, fetchComplaint, createComplaint } = useComplaintStore()
    const { color } = useColorStore()
    let token = JSON.parse(localStorage.getItem('user'))

    const [isLoading, setIsLoading] = useState(false)
    const [payload, setPayload] = useState({
        types: 'Month',
        date: '',
        carried: '',
        recieved: '',
        resolved: '',
        pending: '',
    })


    const [error, setError] = useState(false)

    const handleSubmit = () => {
        if (payload.types.length === 0 || payload.date.length === 0 || payload.carried.length === 0 || payload.recieved.length === 0 || payload.resolved.length === 0 || payload.pending.length === 0) {
            setError(true)
            addToast('All Fields Required...', { appearance: 'error' });
        } else {
            setError(false)
            setIsLoading(true)
            createComplaint(payload)
                .then(res => {
                    fetchComplaint()
                    setPayload({ ...payload, types: '', date: '', carried: '', recieved: '', resolved: '', pending: '' })
                    setIsLoading(false)
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }
    }

    useEffect(() => {
        fetchComplaint()
    }, [])

    const [searchInput, setSearchInput] = useState("")
    const [searchParam] = useState(["date", "types"]);

    function search(items) {
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(searchInput.toLowerCase()) > -1
                );
            });
        });
    }

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (searchInput.length > 0) {
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(search(complaint).slice(itemOffset, endOffset));
            setPageCount(Math.ceil(search(complaint).length / itemsPerPage));
            setitemLength(search(complaint).length)
            setItemEndSet(endOffset)
        }
        else {
            // Fetch items from another resources.
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(complaint.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(complaint.length / itemsPerPage));
            setItemEndSet(endOffset)
            setitemLength(complaint.length)
        }
    }, [itemOffset, itemsPerPage, complaint, searchInput]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % complaint.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search" style={{ position: 'relative' }}>
                        <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} />
                        <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                    </div>
                    <Ripples color='rgba(255,255,255,0.2)' during={1000}>
                        <button className="primaryButton" data-bs-toggle="modal" data-bs-target="#categoryModal" >Add Complaint</button>
                    </Ripples>
                </div>

                {/* ------------------------------Table Work-------------------------------------- */}

                <div class="table-responsive">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <input type="text" defaultValue="10" onChange={(e) => e.target.value === 0 || e.target.value === '0' ? setItemsPerPage() : setItemsPerPage(parseInt(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '35px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />
                        &nbsp; Out of - {itemLength}
                    </div>
                    <table class="table table-nowrap align-middle">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Type</th>
                                <th style={{ color: color.darkText }} scope="col">Month/Year</th>
                                <th style={{ color: color.darkText }} scope="col">Carried</th>
                                <th style={{ color: color.darkText }} scope="col">Recieved</th>
                                <th style={{ color: color.darkText }} scope="col">Resolved</th>
                                <th style={{ color: color.darkText }} scope="col">Pending</th>
                                <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {complaint.length > 0 ?
                                currentItems.map((c, count) => {
                                    return <ComplaintList data={c} key={c.id} count={count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                })
                                :
                                null}
                        </tbody>
                    </table>
                    {currentItems.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
            {isLoading &&
                <Loading />}
            {/* ----------------------Add Modal---------------------------- */}

            <div class="modal fade" id="categoryModal" tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Add Complaint</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Select Type <span className="text-danger">*</span></label>
                                <select value={payload.types} onChange={(e) => setPayload({ ...payload, types: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="Month">Month</option>
                                    <option value="Year">Year</option>
                                </select>
                            </div>

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Month/Year <span className="text-danger">*</span></label>
                                <input value={payload.date} onChange={(e) => setPayload({ ...payload, date: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className="myrow justify_space_between">
                                <div className='mb-3' style={{ width: '49%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Carried <span className="text-danger">*</span></label>
                                    <input value={payload.carried} onChange={(e) => setPayload({ ...payload, carried: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-3' style={{ width: '49%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Recieved <span className="text-danger">*</span></label>
                                    <input value={payload.recieved} onChange={(e) => setPayload({ ...payload, recieved: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-3' style={{ width: '49%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Resolved <span className="text-danger">*</span></label>
                                    <input value={payload.resolved} onChange={(e) => setPayload({ ...payload, resolved: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-3' style={{ width: '49%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Pending <span className="text-danger">*</span></label>
                                    <input value={payload.pending} onChange={(e) => setPayload({ ...payload, pending: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Save"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Complaint