import React, { useState, useEffect } from 'react'
import { useColorStore } from '../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { useCategoryStore } from '../../../Store/ProductStore/CategoryStore'
import { useToasts } from 'react-toast-notifications';
import moment from 'moment/moment';
import { useResearchProductStore } from '../../../Store/ResearchStore/ResearchProductStore/ResearchProductStore';
import { callDetailsNumber, callDetailsWebsite } from '../../../Export';
import { DateTime } from 'luxon';
import { useResearchCallStore } from '../../../Store/ResearchStore/CallStore/CallStore';

const UpdatesList = (props) => {
    const { addToast } = useToasts();
    const { sendUpdate, fetchUpdates } = useResearchCallStore()
    const { color } = useColorStore()
    const { data, count, isLoading, setIsLoading, product, users, researchUpdate, updates } = props
    const [msgContent, setMsgContent] = useState('')
    const [payload, setPayload] = useState({
        cmp1: '',
        cmp2: '',
    })

    const replacecmp = () => {
        if (currUpdate) {
            setCurrUpdate({ ...currUpdate, update_format: currUpdate.update_format.replaceAll("{cmp}", payload.cmp1 ? payload.cmp1.toUpperCase() : '').replaceAll("{CMP}", payload.cmp1 ? payload.cmp1.toUpperCase() : '').replaceAll("{expiry_date}", DateTime.fromISO(data.expiry_date ? data.expiry_date : '').toFormat('dd MMMM').toUpperCase()).replaceAll("{EXPIRY_DATE}", DateTime.fromISO(data.expiry_date ? data.expiry_date : '').toFormat('dd MMMM').toUpperCase()).replaceAll("{stock_name}", data.stock_name ? data.stock_name.toUpperCase() : '').replaceAll("{STOCK_NAME}", data.stock_name ? data.stock_name.toUpperCase() : '').replaceAll("{BUY_SELL}", data.buy_sell ? data.buy_sell.toUpperCase() : '').replaceAll("{buy_sell}", data.buy_sell ? data.buy_sell.toUpperCase() : '').replaceAll("{CALL_PUT}", data.call_put ? data.call_put.toUpperCase() : '').replaceAll("{call_put}", data.call_put ? data.call_put.toUpperCase() : '').replaceAll("{STRIKE_PRICE}", data.strike_price ? data.strike_price.toUpperCase() : '').replaceAll("{strike_price}", data.strike_price ? data.strike_price.toUpperCase() : '') })
        }
    }

    const replacecmp2 = () => {
        if (currUpdate) {
            setCurrUpdate({ ...currUpdate, update_format: currUpdate.update_format.replaceAll("{cmp2}", payload.cmp2 ? payload.cmp2.toUpperCase() : '').replaceAll("{CMP2}", payload.cmp2 ? payload.cmp2.toUpperCase() : '').replaceAll("{expiry_date}", DateTime.fromISO(data.expiry_date ? data.expiry_date : '').toFormat('dd MMMM').toUpperCase()).replaceAll("{EXPIRY_DATE}", DateTime.fromISO(data.expiry_date ? data.expiry_date : '').toFormat('dd MMMM').toUpperCase()).replaceAll("{stock_name}", data.stock_name ? data.stock_name.toUpperCase() : '').replaceAll("{STOCK_NAME}", data.stock_name ? data.stock_name.toUpperCase() : '').replaceAll("{BUY_SELL}", data.buy_sell ? data.buy_sell.toUpperCase() : '').replaceAll("{buy_sell}", data.buy_sell ? data.buy_sell.toUpperCase() : '').replaceAll("{CALL_PUT}", data.call_put ? data.call_put.toUpperCase() : '').replaceAll("{call_put}", data.call_put ? data.call_put.toUpperCase() : '').replaceAll("{STRIKE_PRICE}", data.strike_price ? data.strike_price.toUpperCase() : '').replaceAll("{strike_price}", data.strike_price ? data.strike_price.toUpperCase() : '') })
        }
    }

    const findUsers = (id) => {
        let data = users.find((f) => {
            return f.id === id
        })
        if (data) {
            return data.name
        } else { return '-' }
    }
    const findProduct = (id) => {
        let data = product.find((f) => {
            return f.id === id
        })
        if (data) {
            return data.name
        } else { return '-' }
    }

    const [newUpdates, setNewUpdates] = useState([])

    useEffect(() => {
        let x = researchUpdate.filter((f) => {
            return f.product_category === data.ct
        })
        if (x) {
            setNewUpdates(x)
        }
    }, [researchUpdate])

    const [currUpdate, setCurrUpdate] = useState()

    const [execution, setExecution] = useState('')

    useEffect(() => {
        if (currUpdate) {
            if (currUpdate.execution && payload.cmp1) {
                if (data.ct === 'OPTION' || data.ct === 'FUTURE') {
                    let n = Number(payload.cmp1) - Number(data.stockPrice)
                    setExecution(parseFloat(n * Number(data.lotsize)).toFixed(2))
                } else if (data.ct === 'CASH') {
                    let n = Number(payload.cmp1) - Number(data.stockPrice)
                    setExecution(parseFloat(n * 100000 / Number(data.stockPrice)).toFixed(2))
                }
            } else {
                setExecution('')
            }
        }
    }, [payload.cmp1, currUpdate])

    useEffect(() => {
        if (newUpdates.length > 0) {
            setCurrUpdate(newUpdates[0])
        }
    }, [newUpdates])

    useEffect(() => {
        if (currUpdate) {
            setMsgContent("Update\n" + `${findProduct(data.segmant_id)}` + "\n" + `${currUpdate ? currUpdate.update_format ? currUpdate.update_format.toUpperCase() : '' : ''}`)
        }
    }, [currUpdate])

    function copyTextToClipboard() {
        addToast('Copied', { appearance: 'success' });
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(msgContent + "\n" + callDetailsWebsite + "\n" + callDetailsNumber);
        } else {
            return document.execCommand('copy', true, msgContent + "\n" + callDetailsWebsite + "\n" + callDetailsNumber);
        }
    }

    const handleUpdate = () => {
        let cmp1 = payload.cmp1
        let cmp2 = payload.cmp2
        copyTextToClipboard()
        setIsLoading(true)
        let d = document.getElementById('modalCloseUpdateBtn'+data.id)
        d.click()
        sendUpdate({ msgContent, data, currUpdate, cmp1, cmp2, execution })
            .then(res => {
                setIsLoading(false)
                fetchUpdates()
                if (execution.length > 0) {
                    data.profit_loss = execution
                }
                addToast('Success', { appearance: 'success' });
            })
            .catch(err => {
                setIsLoading(false)
                addToast('Error', { appearance: 'error' });
            })
    }

    const [fUpdates, setFUpdates] = useState([])
    useEffect(() => {
        let d = updates.filter((f) => {
            return f.call_id === data.id
        })
        setFUpdates(d)
    }, [updates])


    return (
        <>
            <tr style={{ color: color.darkText }}>

                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
                    {count}
                </td>

                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>
                    {findUsers(data.owner_id)}
                    <br /> <span style={{ fontSize: '11px', color: color.lightText, display: 'flex', alignItems: 'center', marginTop: '5px' }}><Icon icon="uiw:time-o" color={color.lightText} fontSize={11} />
                        <span style={{ marginLeft: '6px' }}>{moment(data.created_at).format('lll')}</span></span>
                </td>

                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>
                    {findProduct(data.segmant_id)}
                    <br /> <span style={{ fontSize: '11px', color: color.lightText, display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                    </span>
                </td>

                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, whiteSpace: 'pre-wrap' }}>
                    <small>{data.calls}</small>
                </td>

                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, whiteSpace: 'pre-wrap' }}>
                    <small type='button' data-bs-toggle="modal" data-bs-target={"#addServiceFollowUpModal" + data.id} style={{ color: color.blue, paddingBottom: '5px' }}>
                        <u>Add New...</u>
                    </small>
                    <br />
                    {fUpdates.map((d) => {
                        return <div key={d.id} className='mb-1'>
                            <small>{d.updates} <span style={{ fontSize: '11px', color: color.lightText, display: 'flex', alignItems: 'center', marginTop: '1px' }}><Icon icon="uiw:time-o" color={color.lightText} fontSize={11} />
                                <span style={{ marginLeft: '6px' }}>{moment(d.created_at).format('lll')}</span></span>
                            </small>
                        </div>
                    })}
                </td>

                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, width: '100px' }}>
                    {data.profit_loss ? Number(data.profit_loss) > 0 ? <span style={{ color: color.green }}>₹ {data.profit_loss}</span> : <span style={{ color: color.red }}>₹ {data.profit_loss}</span> : '-'}
                </td>
            </tr>

            {/* ----------------------Update Add Modal---------------------------- */}

            <div class="modal fade" id={"addServiceFollowUpModal" + data.id} tabindex="-1" aria-labelledby="addServiceFollowUpModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="addServiceFollowUpModalLabel">Recommendation Update</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            <div className="myrow">
                                {currUpdate ? <>
                                    {newUpdates.map((c) => {
                                        return <button type='button' key={c.id} style={{ color: c.id === currUpdate.id ? '' : color.darkText }} className={currUpdate.id === c.id ? "infoButton mx-1" : "lightButton mx-1"} onClick={(e) => setCurrUpdate(c)}>
                                            {c.title}
                                        </button>
                                    })}
                                </> : null}
                            </div>

                            <div className="myrow mt-3">
                                <div className="m-2">
                                    <label className='modal_label' style={{ color: color.darkText }}>CMP-1</label>
                                    <input onBlur={replacecmp} value={payload.cmp1} onChange={(e) => setPayload({ ...payload, cmp1: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
                                </div>
                                <div className="m-2">
                                    <label className='modal_label' style={{ color: color.darkText }}>CMP-2</label>
                                    <input onBlur={replacecmp2} value={payload.cmp2} onChange={(e) => setPayload({ ...payload, cmp2: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
                                </div>
                                <div className="m-2">
                                    <label className='modal_label' style={{ color: color.darkText }}>Profit/Loss Calculation</label>
                                    <input value={execution} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
                                </div>
                            </div>

                            <div className="myrow justify_space_between">

                                <div className="u_block1">
                                    <textarea value={`${msgContent}\n${callDetailsWebsite}\n${callDetailsNumber}`} onChange={(e) => setMsgContent(e.target.value)} readOnly={true} rows={6} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor, lineHeight: '25px', fontSize: '15px' }} className='modal_input'></textarea>
                                </div>
                                <div className="u_block2">
                                    <textarea value={findProduct(data.segmant_id) + "\n" + data.calls + "\n" + callDetailsWebsite + "\n" + callDetailsNumber} readOnly={true} rows={5} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor, lineHeight: '25px', fontSize: '15px' }} className='modal_input'></textarea>
                                </div>

                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id={'modalCloseUpdateBtn'+ data.id} style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleUpdate}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Update"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default UpdatesList