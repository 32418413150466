import React, { useState } from 'react'
import { useColorStore } from '../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { useCategoryStore } from '../../../Store/ProductStore/CategoryStore'
import { useToasts } from 'react-toast-notifications';
import moment from 'moment/moment';
import { useResearchUpdateStore } from '../../../Store/ResearchStore/UpdateStore/UpdateStore';

const ResearchUpdateList = (props) => {
    const { addToast } = useToasts();
    const { fetchResearchUpdate, updateResearchUpdate, deleteResearchUpdate } = useResearchUpdateStore()
    const { color } = useColorStore()
    const { data, count, isLoading, setIsLoading } = props
    const [error, setError] = useState(false)
    const [payload, setPayload] = useState({
        product_category: data.product_category,
        title: data.title,
        update_format: data.update_format,
        execution: data.execution?data.execution:'',
    })

    const handleSubmit = () => {
        if (payload.product_category.length === 0 || payload.title.length === 0 || payload.update_format.length === 0) {
            setError(true)
        } else {
            setIsLoading(true)
            updateResearchUpdate(payload, data.id)
                .then(res => {
                    fetchResearchUpdate()
                    setIsLoading(false)
                    setPayload({
                        ...payload, product_category: payload.product_category,
                        title: payload.title,
                        update_format: payload.update_format,
                    })
                    setIsLoading(false)
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }
    }

    const handleDelete = () => {
        let d = document.getElementById("dltBtn" + data.id)
        d.click()
        setIsLoading(true)
        deleteResearchUpdate(data.id)
            .then(res => {
                fetchResearchUpdate()
                setIsLoading(false)
                addToast('Success...', { appearance: 'success' });
            }).catch(err => {
                console.log(err);
                setIsLoading(false)
                addToast('Error...', { appearance: 'error' });
            })
    }

    function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            addToast('Copied...', { appearance: 'success' });
            return navigator.clipboard.writeText(text);
        } else {
            addToast('Copied...', { appearance: 'success' });
            return document.execCommand('copy', true, text);
        }
    }

    return (
        <>
            <tr style={{ color: color.darkText }}>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
                    {count}
                </td>

                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.product_category}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.title}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.update_format}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.execution?data.execution:'-'}</td>

                <td style={{ borderBottom: '1px solid ' + color.tableBorder }}>
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <span type="button" class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryModal" + data.id} style={{ color: color.blue }}><Icon icon="akar-icons:edit" fontSize={18} /></span>
                        </li>
                        <li class="list-inline-item">
                            <span type={'button'} class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal" + data.id} style={{ color: color.red }}><Icon icon="ep:delete" fontSize={17} /></span>
                        </li>
                    </ul>
                </td>
            </tr>

            {/* ----------------------Update Modal---------------------------- */}

            <div class="modal fade" id={"categoryModal" + data.id} tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Update Research Update</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Category <span className="text-danger">*</span></label>
                                <select value={payload.product_category} onChange={(e) => setPayload({ ...payload, product_category: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="CASH">Cash</option>
                                    <option value="FUTURE">Future</option>
                                    <option value="OPTION">Option</option>
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Title</label>
                                <input placeholder='Enter...' value={payload.title} onChange={(e) => setPayload({ ...payload, title: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div>
                                <label className='modal_label' style={{ color: color.darkText }}>Variables</label>
                                <div className="myrow" style={{ fontSize: '12px', color: color.darkText }}>
                                    <div className='m-2 lightButton p-1' style={{ fontSize: '12px' }} type='button' onClick={() => copyTextToClipboard('{STOCK_NAME}')}>STOCK_NAME</div>
                                    <div className='m-2 lightButton p-1' style={{ fontSize: '12px' }} type='button' onClick={() => copyTextToClipboard('{STRIKE_PRICE}')}>STRIKE_PRICE</div>
                                    <div className='m-2 lightButton p-1' style={{ fontSize: '12px' }} type='button' onClick={() => copyTextToClipboard('{BUY_SELL}')}>BUY_SELL</div>
                                    <div className='m-2 lightButton p-1' style={{ fontSize: '12px' }} type='button' onClick={() => copyTextToClipboard('{CMP}')}>CMP</div>
                                    <div className='m-2 lightButton p-1' style={{ fontSize: '12px' }} type='button' onClick={() => copyTextToClipboard('{CMP2}')}>CMP2</div>
                                    <div className='m-2 lightButton p-1' style={{ fontSize: '12px' }} type='button' onClick={() => copyTextToClipboard('{EXPIRY_DATE}')}>EXPIRY_DATE</div>
                                    <div className='m-2 lightButton p-1' style={{ fontSize: '12px' }} type='button' onClick={() => copyTextToClipboard('{CALL_PUT}')}>CALL_PUT</div>
                                </div>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Update Format</label>
                                <textarea placeholder='Enter...' rows={6} value={payload.update_format} onChange={(e) => setPayload({ ...payload, update_format: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'></textarea>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Execution</label>
                                <select value={payload.execution} onChange={(e) => setPayload({ ...payload, execution: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">None</option>
                                    <option value="POSITIVE">Positive</option>
                                    <option value="NEGATIVE">Negative</option>
                                </select>
                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Update"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------------------------Delete Modal------------------------ */}

            <div class="modal fade" id={"categoryDeleteModal" + data.id} tabindex="-1" aria-labelledby="categoryDeleteModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to delete ( {data.title} ) Research Update ? You would'nt be able to retrieve this.</div>
                        </div>


                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id={"dltBtn" + data.id} style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleDelete}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Delete"}</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ResearchUpdateList