import React, { useState, useEffect } from 'react'
import Sidebar from '../../Admin/Sidebar/Sidebar'
import Header from '../../Admin/Header/Header'
import { useColorStore } from '../../../Store/ColorStore'
import { useUserPermissionStore } from '../../../Store/UserPermissionStore/UserPermissionStore'
import { SalesRoutes } from './../../Sales/SalesRoutes/SalesRoutes';
import ResearchRoutes from '../ResearchRoutes/ResearchRoutes'
import { useSocketStore } from '../../../Store/SocketStore/SocketStore'
import { useToasts } from 'react-toast-notifications'
import { useLeadDetailStore } from '../../../Store/LeadStore/LeadDetailStore'

const ResearchDashboard = (props) => {
  const { addToast } = useToasts()
  const { user } = props
  const { color } = useColorStore();
  const { fetchUserPermissions, userPermissions } = useUserPermissionStore()
  const { serviceUpdation } = useLeadDetailStore()

  useEffect(() => {
    serviceUpdation()
  }, [])

  useEffect(() => {
    fetchUserPermissions()
  }, [])

  // --------------------Socket Work------------------------------------------
  const { connectSocket, state, newMarketUpdate } = useSocketStore()
  const check = () => {
    if (state) {
      const { ws } = state;
      if (!ws || ws.readyState == WebSocket.CLOSED); //check if websocket instance is closed, if so call `connect` function.
    }
  };

  useEffect(() => {
    if (!state || state.readyState == WebSocket.CLOSED) {
      connectSocket();
    }
  }, [state])

  const handleConnectSocket = () => connectSocket()

  useEffect(() => {
    if (newMarketUpdate) {
      addToast(newMarketUpdate, {
        appearance: 'info',
        autoDismiss: false,
      })

    }
  }, [newMarketUpdate])

  if (userPermissions.length > 0) {
    return (
      <>
        {!state || state.readyState == WebSocket.CLOSED ?
          <div class="position-fixed top-0 end-0 pt-3" style={{ zIndex: "999999" }} >
            <div id="liveToast" class="toast show w-100" role="alert" aria-live="assertive" aria-atomic="true">
              <div class="toast-header" style={{ backgroundColor: color.yellow, color: color.darkText }}>
                <strong class="me-auto">Warning</strong>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
              <div class="toast-body" style={{ backgroundColor: color.tableBorder, color: color.darkText }}>
                WebSocket Not Connected, Please click on connect button to connect now.
                <div class="mt-2 pt-2">
                  <button type="button" onClick={handleConnectSocket} class="btn btn-primary btn-sm">Connect Now</button>
                </div>
              </div>

            </div>
          </div>
          : null}

        <div className="layoutWrapper">
          <div className="desktop">
            <Sidebar user={user} />
          </div>
          <div className='main-body' style={{ backgroundColor: color.primaryBg }}>
            <Header user={user} />
            <div className="content-wrapper">
              <ResearchRoutes />
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return <>Loading...</>
  }
}

export default ResearchDashboard