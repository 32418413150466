import React, { useState, useEffect } from 'react'
import './Category.css'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import CategoryList from './CategoryList';
import { useToasts } from 'react-toast-notifications';
import { useCategoryStore } from '../../../Store/ProductStore/CategoryStore';
import Loading from '../../../Loading';
import { useUserPermissionStore } from '../../../Store/UserPermissionStore/UserPermissionStore';
import { userSegrigation } from '../../../Export';

const Category = () => {
  const { addToast } = useToasts();
  const { category, fetchCategory, createCategory } = useCategoryStore()
  const { color } = useColorStore()
  const { userPermissions } = useUserPermissionStore()
  let token = JSON.parse(localStorage.getItem('user'))

  const [isLoading, setIsLoading] = useState(false)
  const [payload, setPayload] = useState({
    name: '',
    code: '',
  })

  // -----------------Permission Work------------------
  const [permission, setPermission] = useState(false)

  useEffect(() => {
    let d = userPermissions.find((f) => {
      return f.permission_name === 'CATEGORY_PERMISSION'
    })
    let x = userSegrigation.find((f) => {
      return f.key === token.user_type
    })
    if (d && x) {
      setPermission(d[x.name])
    }
  }, [userPermissions])

  const [error, setError] = useState(false)

  const handleSubmit = () => {
    if (payload.name.length === 0 || payload.code.length === 0) {
      setError(true)
    } else {
      setError(false)
      setIsLoading(true)
      createCategory(payload)
        .then(res => {
          fetchCategory()
          setPayload({ ...payload, code: '', name: '' })
          setIsLoading(false)
          addToast('Success...', { appearance: 'success' });
        }).catch(err => {
          console.log(err);
          setIsLoading(false)
          addToast('Error...', { appearance: 'error' });
        })

    }
  }

  useEffect(() => {
    fetchCategory()
  }, [])

  const [searchInput, setSearchInput] = useState("")
  const [searchParam] = useState(["code", "name"]);

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]
            .toString()
            .toLowerCase()
            .indexOf(searchInput.toLowerCase()) > -1
        );
      });
    });
  }

  return (
    <>
      <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
        <div className="myrow justify_space_between mb-4">
          <div className="ico-search" style={{ position: 'relative' }}>
            <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} />
            <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
          </div>
          {permission &&
            <Ripples color='rgba(255,255,255,0.2)' during={1000}>
              <button className="primaryButton" data-bs-toggle="modal" data-bs-target="#categoryModal" >Add Category</button>
            </Ripples>
          }
        </div>

        {/* ------------------------------Table Work-------------------------------------- */}

        <div class="table-responsive">
          <table class="table table-nowrap align-middle mb-0">
            <thead style={{ backgroundColor: color.tableHeader }}>
              <tr style={{ color: color.darkText }}>
                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                  S.No.
                </th>
                <th style={{ color: color.darkText }} scope="col">Name</th>
                <th style={{ color: color.darkText }} scope="col">Code</th>
                {permission &&
                  <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th>
                }
              </tr>
            </thead>
            <tbody>
              {category.length > 0 ?
                search(category).map((c, count) => {
                  return <CategoryList data={c} permission={permission} key={c.id} count={count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                })
                :
                null}
            </tbody>
          </table>
        </div>
      </div>
      {isLoading &&
        <Loading />}
      {/* ----------------------Add Modal---------------------------- */}

      <div class="modal fade" id="categoryModal" tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
            <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
              <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Add Category</div>
              <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
            </div>
            <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

              {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

              <div className='mb-3'>
                <label className='modal_label' style={{ color: color.darkText }}>Name <span className="text-danger">*</span></label>
                <input placeholder='Enter Category Name' value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
              </div>

              <div className='mb-4'>
                <label className='modal_label' style={{ color: color.darkText }}>Code <span className="text-danger">*</span></label>
                <input placeholder='Enter Category Code' value={payload.code} onChange={(e) => setPayload({ ...payload, code: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
              </div>

            </div>
            <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
              <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
              <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                  Please wait...
                </>

                : "Save"}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Category