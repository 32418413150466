import React, { useState, useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import ProductList from './ProductList';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../Loading';
import { useProductStore } from './../../../Store/ProductStore/ProductStore';
import { useCategoryStore } from './../../../Store/ProductStore/CategoryStore';
import { useSubCategoryStore } from './../../../Store/ProductStore/SubCategoryStore';
import { Scrollbars } from 'react-custom-scrollbars';
import { userSegrigation } from '../../../Export';
import { useUserPermissionStore } from '../../../Store/UserPermissionStore/UserPermissionStore';
import ReactPaginate from 'react-paginate';

const Product = () => {
    const { addToast } = useToasts();
    const { product, fetchProduct, createProduct } = useProductStore()
    const { category, fetchCategory } = useCategoryStore()
    const { subcategory, fetchSubCategory } = useSubCategoryStore()
    const { color } = useColorStore()

    // -----------------Permission Work------------------
    let token = JSON.parse(localStorage.getItem('user'))
    const [permission, setPermission] = useState(false)
    const { userPermissions } = useUserPermissionStore()
    useEffect(() => {
        let d = userPermissions.find((f) => {
            return f.permission_name === 'PRODUCT_PERMISSION'
        })
        let x = userSegrigation.find((f) => {
            return f.key === token.user_type
        })
        if (d && x) {
            setPermission(d[x.name])
        }
    }, [userPermissions])

    const [isLoading, setIsLoading] = useState(false)
    const [payload, setPayload] = useState({
        name: '',
        code: '',
        category: '',
        subcategory: '',
        monthly: '',
        quaterly: '',
        premium: false,
        web_visible: false,
        active: true,
    })

    const [error, setError] = useState(false)

    const handleSubmit = () => {
        if (payload.name.length === 0 || payload.code.length === 0 || payload.monthly.length === 0 || payload.quaterly.length === 0 || payload.category.length === 0 || payload.subcategory.length === 0) {
            setError(true)
        } else {
            setError(false)
            setIsLoading(true)
            createProduct(payload)
                .then(res => {
                    fetchProduct()
                    setPayload({ ...payload, code: '', name: '' })
                    setIsLoading(false)
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }
    }

    useEffect(() => {
        fetchProduct()
        if (category.length === 0) {
            fetchCategory()
        }
        if (subcategory.length === 0) {
            fetchSubCategory()
        }
    }, [])

    const [searchInput, setSearchInput] = useState("")
    const [searchParam] = useState(["code", "name"]);

    function search(items) {
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(searchInput.toLowerCase()) > -1
                );
            });
        });
    }

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (searchInput.length > 0) {
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(search(product).slice(itemOffset, endOffset));
            setPageCount(Math.ceil(search(product).length / itemsPerPage));
            setitemLength(search(product).length)
            setItemEndSet(endOffset)
        }
        else {
            // Fetch items from another resources.
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(product.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(product.length / itemsPerPage));
            setItemEndSet(endOffset)
            setitemLength(product.length)
        }
    }, [itemOffset, itemsPerPage, product, searchInput]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % product.length;
        setItemOffset(newOffset);
    };



    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search" style={{ position: 'relative' }}>
                        <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} />
                        <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                    </div>
                    {permission &&
                        <Ripples color='rgba(255,255,255,0.2)' during={1000}>
                            <button className="primaryButton" data-bs-toggle="modal" data-bs-target="#productModal" >Add Product</button>
                        </Ripples>
                    }
                </div>

                {/* ------------------------------Table Work-------------------------------------- */}

                <div class="table-responsive">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <input type="text" defaultValue="10" onChange={(e) => e.target.value === 0 || e.target.value === '0' ? setItemsPerPage() : setItemsPerPage(parseInt(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '35px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />
                        &nbsp; Out of - {itemLength}
                    </div>
                    <table class="table align-middle mb-0">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Name</th>
                                <th style={{ color: color.darkText }} scope="col">Code</th>
                                <th style={{ color: color.darkText }} scope="col">Category</th>
                                <th style={{ color: color.darkText }} scope="col">Prices</th>
                                <th style={{ color: color.darkText }} scope="col">Premium</th>
                                <th style={{ color: color.darkText }} scope="col">Website Visible</th>
                                <th style={{ color: color.darkText }} scope="col">Active</th>
                                {permission &&
                                    <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {product.length > 0 ?
                                currentItems.map((c, count) => {
                                    return <ProductList data={c} key={c.id} permission={permission} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} category={category} subcategory={subcategory} />
                                })
                                :
                                null}
                        </tbody>
                    </table>

                    {currentItems.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
            {isLoading &&
                <Loading />}
            {/* ----------------------Add Modal---------------------------- */}

            <div class="modal fade" id="productModal" tabindex="-1" aria-labelledby="productModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">

                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="productModalLabel">Add Product</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }} id='style-3'>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Name <span className="text-danger">*</span></label>
                                <input placeholder='Enter Product Name' value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Code <span className="text-danger">*</span></label>
                                <input placeholder='Enter Product Code' value={payload.code} onChange={(e) => setPayload({ ...payload, code: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Category <span className="text-danger">*</span></label>
                                <select value={payload.category} onChange={(e) => setPayload({ ...payload, category: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">----------Select Category-------------</option>
                                    {category.map((d) => {
                                        return <option value={d.id}>{d.name}</option>
                                    })}
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Sub Category <span className="text-danger">*</span></label>
                                <select value={payload.subcategory} onChange={(e) => setPayload({ ...payload, subcategory: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">----------Select Sub Category-------------</option>
                                    {subcategory.map((d) => {
                                        return <option value={d.id}>{d.name}</option>
                                    })}
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Monthly Price <span className="text-danger">*</span></label>
                                <input placeholder='Enter Product Monthly Price' value={payload.monthly} onChange={(e) => setPayload({ ...payload, monthly: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Quarterly Price <span className="text-danger">*</span></label>
                                <input placeholder='Enter Product Quarterly Price' value={payload.quaterly} onChange={(e) => setPayload({ ...payload, quaterly: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Premium <span className="text-danger">*</span></label>
                                <select value={payload.premium} onChange={(e) => setPayload({ ...payload, premium: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Active <span className="text-danger">*</span></label>
                                <select value={payload.active} onChange={(e) => setPayload({ ...payload, active: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Web Visibility <span className="text-danger">*</span></label>
                                <select value={payload.web_visible} onChange={(e) => setPayload({ ...payload, web_visible: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value={true}>On</option>
                                    <option value={false}>Off</option>
                                </select>
                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Save"}</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Product