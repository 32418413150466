import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useUserBankDetailsStore = create((set) => ({
    usersBankDetails: {},
    fetchUsersBankDetails: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'auth/view-user-bank-details/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ usersBankDetails: data });
    },
    createUsersBankDetails: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'auth/create-user-bank-details/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    
}));