import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useUserPermissionStore = create((set) => ({
    userPermissions: [],
    fetchUserPermissions: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'auth/fetch-user-permissions/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ userPermissions: data })
    },
    createUserPermission: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'auth/create-user-permissions/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    updateUserPermission: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'auth/update-user-permissions/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
}));