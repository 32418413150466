import React, { useRef, useEffect, useState } from 'react'
import { useColorStore } from './../../../Store/ColorStore';
import { useAdminDashboardStore } from '../../../Store/DashboardStore.js/AdminDashboardStore';
import * as echarts from 'echarts';
import { Icon } from '@iconify/react';
import { numFormatter } from '../../../Export';
import PerformanceList from './../../Admin/Dashboard/PerformanceList/PerformanceList';

const Dashboard = () => {
  const { fetchTotals, totals, fetchBusinessOverview, fetchClientsOverview, business_overview, clients_overview, fetchUserPerformance, performance, dashLeadsData, fetchDashboardLeadsData, fetchDashboardSalesPerformance, salesPerformance } = useAdminDashboardStore()
  const { color } = useColorStore()
  const chartRef = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const chartRef4 = useRef(null);
  const chartRef5 = useRef(null);

  useEffect(() => {
    fetchTotals()
    fetchBusinessOverview()
    fetchClientsOverview()
    fetchUserPerformance()
    fetchDashboardLeadsData()
    fetchDashboardSalesPerformance()
  }, [])


  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    const chart2 = echarts.init(chartRef2.current);

    // Sample data for the bar chart
    const data = business_overview;

    const option = {
      tooltip: {
        trigger: 'axis', backgroundColor: color.primaryBg, borderColor: 'none', color: color.darkText,
        axisPointer: {
          type: 'shadow'
        }
      },
      title: {
        text: '',
      },
      xAxis: {
        type: '',
        data: data.map(item => item.name),
      },
      yAxis: {
        splitLine: {
          show: true,
          lineStyle: {
            color: [color.tableBorder, color.tableBorder]
          }
        }
      },
      series: [{
        type: 'bar',
        name: 'Rupees',
        data: data.map(item => item.value),
        showBackground: true,
        itemStyle: {
          color: color.blue,
        },
        backgroundStyle: {
          color: color.tableBorder,
        },
        barWidth: 16,
      }],
    };

    // Pie Chart configuration
    const pieOptions = {
      tooltip: {
        show: false
      },
      legend: {
        bottom: '10%',
        left: 'center', color: color.darkText, selectedMode: true,
        textStyle: {
          color: color.lightText
        }
      },
      series: [
        {
          name: 'Clients',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: color.secondaryBg,
            borderWidth: 4
          },
          label: {
            show: false,
            position: 'center',
            formatter(param) {
              // correct the percentage
              return param.name + '\n' + param.value + ' (' + param.percent + '%)';
            }
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 13,
              fontWeight: '600', color: color.lightText, lineHeight: 18
            }
          },
          data: clients_overview ? [
            { value: clients_overview.paid, name: 'Paid Client', itemStyle: { color: color.blue } },
            { value: clients_overview.renew, name: 'Renew Client', itemStyle: { color: color.green } },
            { value: clients_overview.free, name: 'Free Trials', itemStyle: { color: color.red } },
          ]
            : [
              { value: 300, name: 'Paid Client', itemStyle: { color: color.blue } },
              { value: 200, name: 'Renew Client', itemStyle: { color: color.green } },
              { value: 600, name: 'Free Trials', itemStyle: { color: color.red } },
            ]
        }
      ]
    };
    
    chart.setOption(option);
    chart2.setOption(pieOptions);

    // Clean up the chart when the component is unmounted
    return () => {
      chart.dispose();
      chart2.dispose();
    };
  }, [color, business_overview, clients_overview]);

  return (
    <>
      <div className="myrow justify_space_between">

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="solar:money-bag-bold" fontSize={32} color={color.blue} /></div>
            </div>
            <div>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Earnings</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Current Month</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>₹ {totals.total_earnings?numFormatter(totals.total_earnings):0}</div>
            </div>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="mdi:user-tie" fontSize={32} color={color.blue} /></div>
            </div>
            <div>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Clients</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Today</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals.total_clients)}</div>
            </div>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="mdi:user-online" fontSize={32} color={color.blue} /></div>
            </div>
            <div>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Active Clients</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>For Today</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals.active_clients)}</div>
            </div>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="ri:service-fill" fontSize={32} color={color.blue} /></div>
            </div>
            <div>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Services</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Today</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals.total_services)}</div>
            </div>
          </div>
        </div>

      </div>
      {/* **********************Second Block Dashboard**************************** */}
      <div className="myrow justify_space_between mb-4">
        <div className="business_block1 p-3" style={{ backgroundColor: color.secondaryBg }}>
          <h6 style={{ color: color.darkText, marginTop: '5px' }}>Business Overview</h6>

          <div className="myrow">
            <div className="ww100">
              <div ref={chartRef} style={{ width: '100%', height: '400px' }} />
            </div>
            <div className="ww100">
              <div ref={chartRef2} style={{ width: '100%', height: '400px' }} />
            </div>
          </div>
        </div>

        <div className="business_block2 p-3" style={{ backgroundColor: color.secondaryBg }}>
          <h6 style={{ color: color.darkText, marginTop: '5px', marginBottom: '10px' }}>Top Performers</h6>
          <hr style={{ borderColor: color.borderColor }} />
          {performance.length > 0 ? <>
            {performance.map((f, count) => {
              return <PerformanceList data={f} key={count} />
            })}
          </> :
            <div style={{ color: color.red, fontSize: '14px' }}>No Payment for Now.</div>
          }


        </div>
      </div>


    </>
  )
}

export default Dashboard