import React, { useState } from 'react'
import { useColorStore } from '../../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { useBlogStore } from '../../../../Store/WebsiteStore/BlogStore'
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { fileUrl, mediaUrl } from './../../../../Export';

var options = {
    modules: {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }], [{ align: [] }],
            ['link', 'image', 'video'],
            ['clean'],

            [{ 'color': [] }, { 'background': [] }],
        ],
    },
    placeholder: 'Compose an epic...',
    readOnly: true,
    theme: 'snow'
}
const BlogList = (props) => {
    const { addToast } = useToasts();
    const { updateBlog, deleteBlog, fetchBlog } = useBlogStore()
    const { color } = useColorStore()
    const { data, count, isLoading, setIsLoading } = props
    const [error, setError] = useState(false)
    const [payload, setPayload] = useState({
        author: 'Scoutstack',
        title: data.title,
        description: data.description,
        cover_image: undefined,
        post: data.post,
    })
    const [blogsImages, setBlogsImages] = useState([])

    const handleSubmit = () => {
        if (payload.author.length === 0 || payload.title.length === 0 || payload.description.length === 0 || payload.post.length === 0) {
            setError(true)
            addToast('All Fields Required...', { appearance: 'error' });
        } else {
            setError(false)
            setIsLoading(true)
            let ndata = new FormData()
            ndata.append('author', payload.author)
            ndata.append('title', payload.title)
            ndata.append('description', payload.description)
            if (payload.cover_image) {
                ndata.append('cover_image', payload.cover_image)
            }
            ndata.append('post', payload.post)
            if (blogsImages.length > 0) {
                for (let i = 0; i < blogsImages.length; i++) {
                    const element = blogsImages[i];
                    ndata.append('blog_images', element)
                }
            } else {
                ndata.append('blog_images', '')
            }
            updateBlog(ndata, data.id)
                .then(res => {
                    fetchBlog()
                    setIsLoading(false)
                    setPayload({
                        ...payload, author: 'Scoutstack',
                        title: payload.title,
                        description: payload.description,
                        cover_image: undefined,
                        post: payload.post,
                    })
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }
    }

    const handleDelete = () => {
        let d = document.getElementById("dltBtnCom" + data.id)
        d.click()
        setIsLoading(true)
        deleteBlog(data.id)
            .then(res => {
                fetchBlog()
                setIsLoading(false)
                addToast('Success...', { appearance: 'success' });
            }).catch(err => {
                console.log(err);
                setIsLoading(false)
                addToast('Error...', { appearance: 'error' });
            })
    }
    return (
        <>
            <tr style={{ color: color.darkText }}>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
                    {count}
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '25px' }}>
                    {data.author} <br /> <span style={{ fontSize: '11px', color: color.lightText, display: 'flex', alignItems: 'center' }}><Icon icon="uiw:time-o" color={color.lightText} fontSize={11} /> <span style={{ marginLeft: '6px' }}>{moment(data.created_at).format('lll')}</span></span>
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.title}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.description}</td>

                <td style={{ borderBottom: '1px solid ' + color.tableBorder }}>
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <span type="button" class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryModal" + data.id} style={{ color: color.blue }}><Icon icon="akar-icons:edit" fontSize={18} /></span>
                        </li>
                        <li class="list-inline-item">
                            <span type={'button'} class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal" + data.id} style={{ color: color.red }}><Icon icon="ep:delete" fontSize={17} /></span>
                        </li>
                    </ul>
                </td>
            </tr>

            {/* ----------------------Update Modal---------------------------- */}

            <div class="modal fade" id={"categoryModal" + data.id} tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-fullscreen">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Update Complaints</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>

                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Blog Title <span className="text-danger">*</span></label>
                                <input value={payload.title} onChange={(e) => setPayload({ ...payload, title: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Description <span className="text-danger">*</span></label>
                                <input value={payload.description} onChange={(e) => setPayload({ ...payload, description: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>CHange Cover Image <span className="text-danger">*</span></label>
                                <input type='file' onChange={(e) => setPayload({ ...payload, cover_image: e.target.files[0] })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Add New Blog Images <span className="text-danger">*</span></label>
                                <input type='file' multiple onChange={(e) => setBlogsImages(e.target.files)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Blog Content <span className="text-danger">*</span></label>
                                <div style={{ width: '100%', background: '#fff' }}>
                                    <ReactQuill value={payload.post} modules={options.modules} onChange={(e) => setPayload({ ...payload, post: e })} />
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Update"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------------------------Delete Modal------------------------ */}

            <div class="modal fade" id={"categoryDeleteModal" + data.id} tabindex="-1" aria-labelledby="categoryDeleteModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to delete this Data ? You would'nt be able to retrieve this.</div>
                        </div>


                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id={"dltBtnCom" + data.id} style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleDelete}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Delete"}</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BlogList