import React, { useEffect } from 'react'
import { useColorStore } from '../../../../../Store/ColorStore'
import { useState } from 'react'
import { useLeadDetailStore } from '../../../../../Store/LeadStore/LeadDetailStore'
import { useToasts } from 'react-toast-notifications'
import ReactPaginate from 'react-paginate'
import { Suspense } from 'react'

const InvoicesList = React.lazy(() => { return import('./InvoicesList') });
const Invoices = (props) => {
    const { addToast } = useToasts();
    const { users, isLoading, setIsLoading, leadUser, leadEnquiry, userId, permission,permission2 } = props
    const { color } = useColorStore()
    const { fetchUserInvoices, userInvoices } = useLeadDetailStore()

    useEffect(() => {
        fetchUserInvoices(leadEnquiry.id)
    }, [leadEnquiry])

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {

        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
        setCurrentItems(userInvoices.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(userInvoices.length / itemsPerPage));
        setItemEndSet(endOffset)
        setitemLength(userInvoices.length)

    }, [itemOffset, itemsPerPage, userInvoices]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % userInvoices.length;
        setItemOffset(newOffset);
    };

    return (
        <>

            <div className="table-responsive mt-3">
                <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                    Show Entries - <input type="text" readOnly defaultValue="10" onChange={(e) => e.target.value === 0 || e.target.value === '0' ? setItemsPerPage() : setItemsPerPage(parseInt(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '35px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />
                    &nbsp; Out of - {itemLength}
                </div>

                <table className="table table-nowrap align-middle mb-3 mt-3">
                    <thead style={{ backgroundColor: color.tableHeader }}>
                        <tr style={{ color: color.darkText }}>
                            <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                S.No.
                            </th>
                            <th style={{ color: color.darkText }} scope="col">Time</th>
                            <th style={{ color: color.darkText }} scope="col">Invoice Number & Issue Date</th>
                            <th style={{ color: color.darkText }} scope="col">Invoice</th>              
                        </tr>
                    </thead>

                    <tbody>
                        {currentItems.length > 0 ?
                            currentItems.map((c, count) => {
                                return <Suspense key={c.id} fallback={<></>}>
                                    <InvoicesList data={c} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                </Suspense>
                            })
                            :
                            <div style={{ color: color.darkText, fontSize: 14, textAlign: 'center' }}>No Record found....!</div>
                        }
                    </tbody>
                </table>
                {currentItems.length > 0 &&
                    <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                }

                <ReactPaginate
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="pagination-active"
                    renderOnZeroPageCount={null}
                />

            </div>



        </>
    )
}

export default Invoices