import React, { useState, useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import BlogList from './BlogList';
import { useToasts } from 'react-toast-notifications';
import Loading from './../../../../Loading';
import ReactPaginate from 'react-paginate';
import { useBlogStore } from './../../../../Store/WebsiteStore/BlogStore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Blogs = () => {
    const { addToast } = useToasts();
    const { blogs, fetchBlog, createBlog } = useBlogStore()
    const { color } = useColorStore()
    let token = JSON.parse(localStorage.getItem('user'))
    var options = {
        modules: {
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }], [{ align: [] }],
                ['link', 'image', 'video'],
                ['clean'],

                [{ 'color': [] }, { 'background': [] }],
            ],
        },
        placeholder: 'Compose an epic...',
        readOnly: true,
        theme: 'snow'
    }

    const [isLoading, setIsLoading] = useState(false)
    const [payload, setPayload] = useState({
        author: 'Scoutstack',
        title: '',
        description: '',
        cover_image: undefined,
        post: '',
    })


    const [error, setError] = useState(false)
    const [blogsImages, setBlogsImages] = useState([])

    const handleSubmit = () => {
        if (payload.author.length === 0 || payload.title.length === 0 || payload.description.length === 0 || payload.post.length === 0) {
            setError(true)
            addToast('All Fields Required...', { appearance: 'error' });
        } else {
            let ndata = new FormData()
            ndata.append('author', payload.author)
            ndata.append('title', payload.title)
            ndata.append('description', payload.description)
            ndata.append('cover_image', payload.cover_image)
            ndata.append('post', payload.post)
            if (blogsImages.length > 0) {
                for (let i = 0; i < blogsImages.length; i++) {
                    const element = blogsImages[i];
                    ndata.append('blog_images', element)
                }
            } else {
                ndata.append('blog_images', '')
            }
            setError(false)
            setIsLoading(true)
            createBlog(ndata)
                .then(res => {
                    fetchBlog()
                    setPayload({ ...payload, author: 'Scoutstack', title: '', description: '', cover_image: undefined, post: '' })
                    setIsLoading(false)
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }
    }

    // console.log(payload);

    useEffect(() => {
        fetchBlog()
    }, [])

    const [searchInput, setSearchInput] = useState("")
    const [searchParam] = useState(["title", "description"]);

    function search(items) {
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(searchInput.toLowerCase()) > -1
                );
            });
        });
    }

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (searchInput.length > 0) {
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(search(blogs).slice(itemOffset, endOffset));
            setPageCount(Math.ceil(search(blogs).length / itemsPerPage));
            setitemLength(search(blogs).length)
            setItemEndSet(endOffset)
        }
        else {
            // Fetch items from another resources.
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(blogs.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(blogs.length / itemsPerPage));
            setItemEndSet(endOffset)
            setitemLength(blogs.length)
        }
    }, [itemOffset, itemsPerPage, blogs, searchInput]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % blogs.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search" style={{ position: 'relative' }}>
                        <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} />
                        <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                    </div>
                    <Ripples color='rgba(255,255,255,0.2)' during={1000}>
                        <button className="primaryButton" data-bs-toggle="modal" data-bs-target="#categoryModal" >Create Blog</button>
                    </Ripples>
                </div>

                {/* ------------------------------Table Work-------------------------------------- */}

                <div class="table-responsive">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <input type="text" defaultValue="10" onChange={(e) => e.target.value === 0 || e.target.value === '0' ? setItemsPerPage() : setItemsPerPage(parseInt(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '35px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />
                        &nbsp; Out of - {itemLength}
                    </div>
                    <table class="table table-nowrap align-middle">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Author</th>
                                <th style={{ color: color.darkText }} scope="col">Title</th>
                                <th style={{ color: color.darkText }} scope="col">Description</th>
                                <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {blogs.length > 0 ?
                                currentItems.map((c, count) => {
                                    return <BlogList data={c} key={c.id} count={count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                })
                                :
                                null}
                        </tbody>
                    </table>
                    {currentItems.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
            {isLoading &&
                <Loading />}
            {/* ----------------------Add Modal---------------------------- */}

            <div class="modal fade" id="categoryModal" tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-fullscreen">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Create Blog</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Blog Title <span className="text-danger">*</span></label>
                                <input value={payload.title} onChange={(e) => setPayload({ ...payload, title: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Description <span className="text-danger">*</span></label>
                                <input value={payload.description} onChange={(e) => setPayload({ ...payload, description: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>
                            <div className="myrow justify_space_between">

                                <div className='mb-3' style={{ width: '49%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Cover Image <span className="text-danger">*</span></label>
                                    <input type='file' onChange={(e) => setPayload({ ...payload, cover_image: e.target.files[0] })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-3' style={{ width: '49%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Blog Images <span className="text-danger">*</span></label>
                                    <input type='file' multiple onChange={(e) => setBlogsImages(e.target.files)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                            </div>

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Blog Content <span className="text-danger">*</span></label>
                                <div style={{ width: '100%', background: '#fff' }}>
                                    <ReactQuill modules={options.modules} onChange={(e) => setPayload({ ...payload, post: e })} />
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Save"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blogs