import React, { useState, useEffect, useRef } from 'react'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import { AdminPath } from '../Admin/AdminRoutes/AdminPath'
import Inbox from './Inbox'
import './Email.css'
import { useColorStore } from '../../Store/ColorStore'
import { Icon } from '@iconify/react'
import Starred from './Starred';
import SentMail from './SentMail';
import Trash from './Trash';
import Labels from './Labels';
import { useUserStore } from './../../Store/UserStore/UserStore';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// import { Editor } from '@tinymce/tinymce-react';
import { useEmailStore } from '../../Store/EmailStore/EmailStore'
import { useToasts } from 'react-toast-notifications'
import CurrentEmail from './CurrentEmail'
import { useSocketStore } from './../../Store/SocketStore/SocketStore';
import { tinyApiKey } from '../../Export'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const animatedComponents = makeAnimated();
const Email = () => {
    const { addToast } = useToasts();
    const editorRef = useRef(null);
    const { color } = useColorStore()
    let location = useLocation();
    const { users, fetchUsers } = useUserStore()
    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState([])
    let u = JSON.parse(localStorage.getItem('user'))
    const { email, sendEmail, fetchEmail } = useEmailStore()

    useEffect(() => {
        setOptions([])
        let d = []
        for (let i = 0; i < users.length; i++) {
            const u = users[i];
            d.push({
                value: u.id, label: u.name
            })
        }
        setOptions(d)
    }, [users])

    useEffect(() => {
        if (users.length === 0) {
            fetchUsers()
        }
    }, [])

    const { newNotification } = useSocketStore()
    useEffect(() => {
        fetchEmail()
    }, [])

    useEffect(() => {
        if (newNotification) {
            fetchEmail()
        }
    }, [newNotification])


    const c = [color.blue2, color.yellow, color.blue, color.green]

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: color.inputBackground,
            // match with the menu
            borderRadius: 4,
            // Overwrittes the different states of border
            borderColor: color.borderColor,
            fontSize: 14,
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: color.borderColor
            }
        }),
        menu: base => ({
            ...base,
            background: color.inputBackground,
            color: color.darkText,
            fontSize: 14,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);

            return {
                ...styles,
                backgroundColor: isFocused ? color.blue : null,
                color: isFocused ? "#fff" : null,
                // color: "#333333"
            };
        }
    };

    // ---------------------
    const [reciepent, setReciepent] = useState([])
    const [subject, setSubject] = useState('')
    const [content, setContent] = useState()
    const [error, setError] = useState(false)
    const [files, setFiles] = useState([])

    const handleSubmit = () => {
        if (subject.length === 0 || reciepent.length === 0 || !content) {
            setError(true)
        } else {
            setError(false)
            setIsLoading(true)
            const data = new FormData()
            for (let i = 0; i < reciepent.length; i++) {
                const element = reciepent[i];
                data.append('to', element.value)
            }
            data.append('subject', subject)
            data.append('mail', content)
            data.append('owner', u.id)
            for (let i = 0; i < files.length; i++) {
                const element = files[i];
                data.append('ff', element)
            }
            let d = document.getElementById('closeBtn')
            d.click()
            sendEmail(data)
                .then(res => {
                    setReciepent([])
                    fetchEmail()
                    setIsLoading(false)
                    addToast('Success...', { appearance: 'success' });
                })
                .catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }
    }

    useEffect(() => {
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const f = files[i];
                if (f.size > 5242880) {
                    setFiles([])
                    addToast('File size must be below 5 MB.', { appearance: 'error' });
                }
            }
        }
    }, [files])

    let Editor = {
        modules: {
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],[{ align: [] }],
                ['link', 'image', 'video'],
                ['clean']
            ],
            clipboard: { matchVisual: false, }
        },
        formats: ['header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video']
    };

    return (
        <>

            <div className="d-flex">
                <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="email_sidebar">
                        <div type={'button'} className="dangerButton" data-bs-toggle="modal" data-bs-target="#emailModal">Compose</div>

                        <div className="email_nav_wrapper">
                            <Link to={AdminPath.INBOX} style={{ padding: "12px 0px", marginLeft: '0px' }} className={location.pathname === AdminPath.INBOX ? "myrow align_center email-menu-item email-menu-item-active justify_space_between" : "myrow align_center email-menu-item justify_space_between"}>
                                <div className="d-flex align_center"><Icon icon="mingcute:inbox-fill" fontSize={18} />&nbsp;&nbsp; Inbox</div>
                                {/* <div>></div> */}
                            </Link>

                            <Link to={AdminPath.STARRED} style={{ padding: "12px 0px", marginLeft: '0px' }} className={location.pathname === AdminPath.STARRED ? "myrow align_center email-menu-item email-menu-item-active justify_space_between" : "myrow align_center email-menu-item justify_space_between"}>
                                <div className="d-flex align_center"><Icon icon="ic:twotone-star" fontSize={18} />&nbsp;&nbsp; Starred</div>
                                {/* <div>></div> */}
                            </Link>

                            <Link to={AdminPath.SENTMAIL} style={{ padding: "12px 0px", marginLeft: '0px' }} className={location.pathname === AdminPath.SENTMAIL ? "myrow align_center email-menu-item email-menu-item-active justify_space_between" : "myrow align_center email-menu-item justify_space_between"}>
                                <div className="d-flex align_center"><Icon icon="ic:twotone-mark-email-read" fontSize={18} />&nbsp;&nbsp; Sent Mail</div>
                                {/* <div>></div> */}
                            </Link>

                            <Link to={AdminPath.TRASH} style={{ padding: "12px 0px", marginLeft: '0px' }} className={location.pathname === AdminPath.TRASH ? "myrow align_center email-menu-item email-menu-item-active justify_space_between" : "myrow align_center email-menu-item justify_space_between"}>
                                <div className="d-flex align_center"><Icon icon="basil:trash-solid" fontSize={18} />&nbsp;&nbsp; Trash</div>
                                {/* <div>></div> */}
                            </Link>

                            {/* ----------------------------------------Label Url------------------------------------------------ */}

                            <div className="menu-title mt-3" style={{ padding: "12px 0px", color: color.darkText, opacity: 1, fontSize: '12px' }}>Labels</div>

                            <Link to={AdminPath.LABELS + "Client Invoice"} style={{ padding: "12px 0px", marginLeft: '0px' }} className={location.pathname === AdminPath.LABELS + 'Client%20Invoice' ? "myrow align_center email-menu-item email-menu-item-active justify_space_between" : "myrow align_center email-menu-item justify_space_between"}>
                                <div className="d-flex align_center">Client Invoice</div>
                                <div><Icon icon={location.pathname === AdminPath.LABELS + "Client%20Invoice" ? "ph:circle-duotone" : "akar-icons:circle"} color={c[0]} /></div>
                            </Link>

                            <Link to={AdminPath.LABELS + "Edit Enquiry"} style={{ padding: "12px 0px", marginLeft: '0px' }} className={location.pathname === AdminPath.LABELS + "Edit%20Enquiry" ? "myrow align_center email-menu-item email-menu-item-active justify_space_between" : "myrow align_center email-menu-item justify_space_between"}>
                                <div className="d-flex align_center">Edit Enquiry</div>
                                <div><Icon icon={location.pathname === AdminPath.LABELS + "Edit%20Enquiry" ? "ph:circle-duotone" : "akar-icons:circle"} color={c[1]} /></div>
                            </Link>

                            <Link to={AdminPath.LABELS + "Regarding Services"} style={{ padding: "12px 0px", marginLeft: '0px' }} className={location.pathname === AdminPath.LABELS + "Regarding%20Services" ? "myrow align_center email-menu-item email-menu-item-active justify_space_between" : "myrow align_center email-menu-item justify_space_between"}>
                                <div className="d-flex align_center">Regarding Services</div>
                                <div><Icon icon={location.pathname === AdminPath.LABELS + "Regarding%20Services" ? "ph:circle-duotone" : "akar-icons:circle"} color={c[2]} /></div>
                            </Link>

                            <Link to={AdminPath.LABELS + "EOD"} style={{ padding: "12px 0px", marginLeft: '0px' }} className={location.pathname === AdminPath.LABELS + "EOD" ? "myrow align_center email-menu-item email-menu-item-active justify_space_between" : "myrow align_center email-menu-item justify_space_between"}>
                                <div className="d-flex align_center">EOD</div>
                                <div><Icon icon={location.pathname === AdminPath.LABELS + "EOD" ? "ph:circle-duotone" : "akar-icons:circle"} color={c[3]} /></div>
                            </Link>

                        </div>
                    </div>
                </div>
                <div className="category_body email_main_body" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="">
                        <Routes>
                            <Route path={':uid'} element={<CurrentEmail />} />
                            <Route path={'/inbox'} element={<Inbox />} />
                            <Route path={'/starred'} element={<Starred />} />
                            <Route path={'/sent-mail'} element={<SentMail />} />
                            <Route path={'/trash'} element={<Trash />} />
                            <Route path={'/labels/:id'} element={<Labels />} />
                        </Routes>
                    </div>
                </div>
            </div>

            {/* -------------------------------------------------------------------------------------------------------- */}

            <div className="modal fade" id="emailModal" tabIndex="-1" aria-labelledby="emailModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div className="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }} id="emailModalLabel">Compose Mail</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>To <span className="text-danger">*</span></label>
                                <Select
                                    styles={customStyles}
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={options}
                                    onChange={(e) => setReciepent(e)}
                                    value={reciepent}
                                />
                            </div>

                            <div className='mb-4'>
                                <label className='modal_label' style={{ color: color.darkText }}>Subject <span className="text-danger">*</span></label>
                                <input placeholder='Enter subject...' value={subject} onChange={(e) => setSubject(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className="mb-3">
                                <label className='modal_label' style={{ color: color.darkText }}>Content <span className="text-danger">*</span></label>
                                <ReactQuill style={{ backgroundColor: '#fff' }}
                                    theme='snow'
                                    modules={Editor.modules}
                                    formats={Editor.formats}
                                    bounds={'.app'}
                                    value={content} onChange={(e) => setContent(e)} />
                            </div>

                            <div className='mb-4'>
                                <label className='modal_label' style={{ color: color.darkText }}>Attach Files</label>
                                <input type='file' multiple={true} onChange={(e) => setFiles(e.target.files)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                        </div>
                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id='closeBtn' style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Send"}</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Email