import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useLotsizeStore = create((set) => ({
    lotsize: [],
    fetchLotsize: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'research/fetch-lotsize/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ lotsize: data });
    },
    createLotsize: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'research/create-lotsize/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    addLotsize: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'research/add-lotsize/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updateLotsize: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'research/update-lotsize/'+id+'/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    },
    deleteLotsize: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'research/delete-lotsize/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    }
}));