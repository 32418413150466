import React, { useState } from 'react'
import { useColorStore } from '../../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { useDemoStore } from '../../../../Store/WebsiteStore/DemoStore'
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

const DemoList = (props) => {
    const { addToast } = useToasts();
    const { deleteDemo, fetchDemo } = useDemoStore()
    const { color } = useColorStore()
    const { data, count, isLoading, setIsLoading } = props

    const handleDelete = () => {
        let d = document.getElementById("dltBtnReqDemo"+data.id)
        d.click()
        setIsLoading(true)
        deleteDemo(data.id)
            .then(res => {
                fetchDemo()
                setIsLoading(false)
                addToast('Success...', { appearance: 'success' });
            }).catch(err => {
                console.log(err);
                setIsLoading(false)
                addToast('Error...', { appearance: 'error' });
            })
    }
    return (
        <>
            <tr style={{ color: color.darkText }}>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
                    {count}
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '25px' }}>
                    <Icon icon="solar:user-outline" fontSize={14} color={color.blue} /> {data.name} <br /> <span style={{ fontSize: '11px', color: color.lightText, display: 'flex', alignItems: 'center' }}><Icon icon="uiw:time-o" color={color.lightText} fontSize={11} /> <span style={{ marginLeft: '6px' }}>{moment(data.created_at).format('lll')}</span></span>
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '26px' }}>
                    <Icon icon="fluent:call-28-regular" fontSize={14} color={color.blue} /> {data.phone}<br />{data.email ? <> <Icon icon="fluent:mail-24-regular" fontSize={15} color={color.blue} /> {data.email} </> : ''}
                </td>

                <td style={{ borderBottom: '1px solid ' + color.tableBorder }}>
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <span type={'button'} class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal" + data.id} style={{ color: color.red }}><Icon icon="ep:delete" fontSize={17} /></span>
                        </li>
                    </ul>
                </td>

            </tr>

            {/* ----------------------------Delete Modal------------------------ */}

            <div class="modal fade" id={"categoryDeleteModal" + data.id} tabindex="-1" aria-labelledby="categoryDeleteModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to delete ( {data.name} ) Requested Demo ? You would'nt be able to retrieve this.</div>
                        </div>

                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id={"dltBtnReqDemo"+data.id} style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleDelete}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Delete"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DemoList