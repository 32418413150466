import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useAdminDashboardStore = create((set) => ({
    totals:{total_clients:0,active_clients:0,total_earnings:0,total_services:0},
    business_overview:[],clients_overview:{},performance:[],dashLeadsData:{},salesPerformance:[],
    fetchTotals: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'dashboard/dashboard-totals/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ totals:data});
    },
    fetchBusinessOverview: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'dashboard/dashboard-business-overview/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ business_overview:data});
    },
    fetchClientsOverview: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'dashboard/dashboard-clients-overview/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ clients_overview:data});
    },
    fetchUserPerformance: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'dashboard/dashboard-user-performance/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ performance:data});
    },
    fetchDashboardLeadsData: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'dashboard/dashboard-leads-data/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ dashLeadsData:data});
    },
    fetchDashboardSalesPerformance: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'dashboard/dashboard-sales-performance/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ salesPerformance:data});
    },
    
}));