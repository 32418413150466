import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { AdminPath } from './AdminPath'
import AdminDashboard from './../AdminDashboard/AdminDashboard';
import Category from '../Category/Category';
import Dashboard from './../Dashboard/Dashboard';
import SubCategory from '../SubCategory/SubCategory';
import Product from '../Product/Product';
import PdfFiles from '../Spaces/PdfFiles/PdfFiles';
import ProductBrochure from '../Spaces/ProductBrochure/ProductBrochure';
import QrScanner from '../Spaces/QrScanner/QrScanner';
import DynamicEmail from '../Spaces/DynamicEmail/DynamicEmail';
import IpAddress from '../Spaces/IpAddress/IpAddress';
import Users from '../Accounts/Users/Users';
import UserDetails from '../Accounts/Users/UserDetails/UserDetails';
import Email from '../../Email/Email';
import Inbox from '../../Email/Inbox';
import AllLeads from '../Leads/AllLeads/AllLeads';
import LeadsDetails from '../Leads/LeadsDetails/LeadsDetails';
import Services from '../Leads/Services/Services';
import AssignedLeads from '../Leads/AssignedLeads/AssignedLeads';
import ScrollToTop from './ScrollToTop';
import Compliance from '../Compliance/Compliance';
import UserPermissions from '../UserPermissions/UserPermissions';
import Settings from '../Settings/Settings';
import WebEnquiry from '../Website/WebEnquiry/WebEnquiry';
import NewsLetter from '../Website/NewsLetter/NewsLetter';
import Demo from '../Website/Demo/Demo';
import PaymentUser from '../Website/PaymentUser/PaymentUser';
import Complaint from '../Website/Complaint/Complaint';
import ComplaintData from '../Website/ComplaintData/ComplaintData';
import Blogs from '../Website/Blogs/Blogs';
import Recommendation from '../../Research/Recommendation/Recommendation';
import FollowUp from '../Leads/FollowUp/FollowUp';
import NewLeads from './../Leads/AssignedLeads/NewLeads';

export const AdminRoutes = () => {
    return (
        <>
        <ScrollToTop />
            <Routes>
                <Route path={AdminPath.DASHBOARD} element={<Dashboard />} />
                <Route path={AdminPath.CATEGORY} element={<Category />} />
                <Route path={AdminPath.SUBCATEGORY} element={<SubCategory />} />
                <Route path={AdminPath.PRODUCT} element={<Product />} />
                <Route path={AdminPath.PDFFILES} element={<PdfFiles />} />
                <Route path={AdminPath.PRODUCTBROCHURE} element={<ProductBrochure />} />
                <Route path={AdminPath.QRSCANNER} element={<QrScanner />} />
                <Route path={AdminPath.DYNAMICEMAIL} element={<DynamicEmail />} />
                <Route path={AdminPath.IPADDRESS} element={<IpAddress />} />
                <Route path={AdminPath.USERS} element={<Users />} />
                <Route path={AdminPath.USERS + "/:userId"} element={<UserDetails />} />
                <Route path={AdminPath.PROFILE + "/:userId"} element={<UserDetails />} />
                <Route path={AdminPath.EMAIL} element={<Email />} />
                <Route path={AdminPath.ALLLEADS} element={<AllLeads />} />
                <Route path={AdminPath.ASSIGNEDLEADS} element={<AssignedLeads />} />
                <Route path={AdminPath.NEWLEADS} element={<NewLeads />} />
                <Route path={AdminPath.FOLLOWUPLEADS+ "/:fid"} element={<FollowUp />} />
                <Route path={AdminPath.ALLLEADS+ "/:userId"} element={<LeadsDetails />} />
                <Route path={AdminPath.LEADSSERVICES} element={<Services />} />
                <Route path={AdminPath.ALLINVOICES} element={<Compliance />} />
                <Route path={AdminPath.USERPERMISSION} element={<UserPermissions />} />
                <Route path={AdminPath.SETTINGS} element={<Settings />} />
                <Route path={AdminPath.WEBENQUIRY} element={<WebEnquiry />} />
                <Route path={AdminPath.NEWSLETTER} element={<NewsLetter />} />
                <Route path={AdminPath.REQDEMO} element={<Demo />} />
                <Route path={AdminPath.PAYMENTUSER} element={<PaymentUser />} />
                <Route path={AdminPath.COMPLAINT} element={<Complaint />} />
                <Route path={AdminPath.COMPLAINTDATA} element={<ComplaintData />} />
                <Route path={AdminPath.BLOGS} element={<Blogs />} />
                <Route path={AdminPath.RECOMMENDATION} element={<Recommendation />} />
            </Routes>
        </>
    )
}
