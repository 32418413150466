import React, { useState, useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import UpdatesList from './UpdatesList.js';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../Loading';
import './Updates.css'
import { useProductStore } from './../../../Store/ProductStore/ProductStore';
import { useResearchCallStore } from './../../../Store/ResearchStore/CallStore/CallStore';
import { useUserStore } from './../../../Store/UserStore/UserStore';
import { useResearchUpdateStore } from '../../../Store/ResearchStore/UpdateStore/UpdateStore.js';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import ReactPaginate from 'react-paginate';

const animatedComponents = makeAnimated();

const Updates = () => {
  const { addToast } = useToasts();
  const location = useLocation()
  const navigate = useNavigate();
  const { calls, fetchCall, fetchUpdates, updates } = useResearchCallStore()
  const { researchUpdate, fetchResearchUpdate } = useResearchUpdateStore()
  const { fetchProduct, product } = useProductStore()
  const { fetchUsers, users } = useUserStore()
  const { color } = useColorStore()
  let token = JSON.parse(localStorage.getItem('user'))

  const [isLoading, setIsLoading] = useState(false)
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: color.inputBackground,
      // match with the menu
      borderRadius: 4,
      // Overwrittes the different states of border
      borderColor: color.borderColor,
      fontSize: 14,
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: color.borderColor
      }
    }),
    menu: base => ({
      ...base,
      background: color.inputBackground,
      color: color.darkText,
      fontSize: 14,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isFocused ? color.blue : null,
        color: isFocused ? "#fff" : null,
        // color: "#333333"
      };
    }
  };

  useEffect(() => {
    fetchCall()
  }, [])

  useEffect(() => {
    if (product.length === 0) {
      fetchProduct()
    }
  }, [])

  useEffect(() => {
    if (researchUpdate.length === 0) {
      fetchResearchUpdate()
    }
  }, [])

  useEffect(() => {
    if (users.length === 0) {
      fetchUsers()
    }
  }, [])

  useEffect(() => {
    if (updates.length === 0) {
      fetchUpdates()
    }
  }, [])

  const [searchInput, setSearchInput] = useState("")
  const [searchParam] = useState(['calls']);

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]
            .toString()
            .toLowerCase()
            .indexOf(searchInput.toLowerCase()) > -1
        );
      });
    });
  }

  // ========================================================Filter WOrk===================================
  const [filterMode, setFilterMode] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [changeNotify, setChangeNotify] = useState(false)
  const [owner, setOwner] = useState([])
  const [filterProduct, setFilterProduct] = useState([])
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  })
  // -------Options-----------
  const [options, setOptions] = useState([])
  const [segment, setSegment] = useState([])

  const handleRemoveOwner = (index) => {
    setOwner([
      ...owner.slice(0, index),
      ...owner.slice(index + 1, owner.length)
    ]);
  }

  const handleRemoveFilterProduct = (index) => {
    setFilterProduct([
      ...filterProduct.slice(0, index),
      ...filterProduct.slice(index + 1, filterProduct.length)
    ]);
  }

  useEffect(() => {
    setOptions([])
    let d = []
    for (let i = 0; i < users.length; i++) {
      const u = users[i];
      d.push({
        value: u.id, label: u.name
      })
    }
    setOptions(d)
  }, [users])

  useEffect(() => {
    setSegment([])
    let d = []
    for (let i = 0; i < product.length; i++) {
      const u = product[i];
      d.push({
        value: u.id, label: u.name
      })
    }
    setSegment(d)
  }, [product])

  const handleFilter = () => {
    let data = calls
    if (filterProduct.length > 0) {
      let d = []
      for (let i = 0; i < filterProduct.length; i++) {
        const e = filterProduct[i];
        d.push(e.value)
      }
      data = data.filter((p) => {
        return d.indexOf(p.segmant_id) > -1;
      });
    }
    if (owner.length > 0) {
      let d = []
      for (let i = 0; i < owner.length; i++) {
        const e = owner[i];
        d.push(e.value)
      }
      data = data.filter((user) => {
        return d.indexOf(user.owner_id) > -1;
      });
    }

    if (dateRange.startDate.length > 0 && dateRange.endDate.length > 0) {
      data = data.filter((f) => {
        const date = new Date(f.created_at).setHours(0, 0, 0, 0)
        return date <= new Date(dateRange.endDate).setHours(0, 0, 0, 0) && new Date(dateRange.startDate).setHours(0, 0, 0, 0) <= date
      });
    }
    setFilterData(data)
  }

  useEffect(() => {
    if (location.search.length > 0 && options.length > 0 && segment.length > 0) {
      const filterParams = new URLSearchParams(location.search);
      const nowner = filterParams.get('owner');
      const nsegment = filterParams.get('filterProduct');
      const nstartDate = filterParams.get('startDate');
      const nendDate = filterParams.get('endDate');
      setFilterProduct(segment.filter((f) => {
        return nsegment.split(',').indexOf(String(f.value)) > -1;
      }))
      setOwner(options.filter((f) => {
        return nowner.split(',').indexOf(String(f.value)) > -1;
      }))
      setDateRange({ ...dateRange, startDate: nstartDate, endDate: nendDate })
      setChangeNotify(true)
    }
    else {
      if (location.search.length === 0) {
        clearFilter()
      }
    }
  }, [location.search, calls, options, segment])

  const applyFilter = () => {
    setChangeNotify(false)
    if (owner.length > 0 || filterProduct.length > 0 || dateRange.startDate.length > 0 && dateRange.endDate.length > 0) {
      setFilterMode(true)
      navigate(`?owner=${owner.map((f) => { return f.value })}&filterProduct=${filterProduct.map((f) => { return f.value })}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`);
      handleFilter()
    } else {
      clearFilter()
    }
  }

  const clearFilter = () => {
    setOwner([])
    setFilterProduct([])
    setDateRange({ ...dateRange, startDate: '', endDate: '' })
    navigate('')
    setFilterMode(false)
    setFilterData([])
  }

  useEffect(() => {
    if (changeNotify === true) {
      applyFilter()
    }
  }, [changeNotify])

  // --------------------Pagination Works------------------------------

  // Pagination Work Start

  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemEndSet, setItemEndSet] = useState(0)
  const [itemLength, setitemLength] = useState(0)

  useEffect(() => {
    if (searchInput.length > 0) {
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
      setCurrentItems(search(calls).slice(itemOffset, endOffset));
      setPageCount(Math.ceil(search(calls).length / itemsPerPage));
      setitemLength(search(calls).length)
      setItemEndSet(endOffset)
    }
    else if (filterMode) {
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
      setCurrentItems(filterData.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(filterData.length / itemsPerPage));
      setitemLength(filterData.length)
      setItemEndSet(endOffset)
    } else {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
      setCurrentItems(calls.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(calls.length / itemsPerPage));
      setItemEndSet(endOffset)
      setitemLength(calls.length)
    }

  }, [itemOffset, itemsPerPage, calls, filterMode, filterData, searchInput]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % calls.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
        <div className="myrow justify_space_between mb-4">
          <div className="ico-search" style={{ position: 'relative' }}>
            <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} />
            <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
          </div>
          <div>
            <Ripples color='rgba(255,255,255,0.2)' during={1000}>
              <button className="primaryButton" data-bs-toggle="offcanvas" data-bs-target="#LeadsFilterCanvas" aria-controls="LeadsFilterCanvas" >Filter</button>
            </Ripples>

          </div>
        </div>

        {filterMode &&
          <div className='filter_values_wrapper'>
            <div className="filter_values_title" style={{ color: color.darkText }}>Filter by &nbsp;:<span onClick={clearFilter} className="clear_filter_link">Clear filter</span></div>

            {owner.length > 0 ?
              owner.map((u, count) => {
                return <div className="filter_values" key={count}>{u.label.toLowerCase()} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveOwner(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
              })
              : null
            }
            {filterProduct.length > 0 ?
              filterProduct.map((u, count) => {
                return <div className="filter_values" key={count}>{u.label} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveFilterProduct(count); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
              })
              : null
            }
            {dateRange.startDate.length > 0 && dateRange.endDate.length > 0 &&
              <div className="filter_values">
                {moment(dateRange.startDate).format('ll')} - {moment(dateRange.endDate).format('ll')} <Icon icon="fa6-solid:x" onClick={() => { setDateRange({ ...dateRange, startDate: '', endDate: '' }); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} />
              </div>
            }

          </div>
        }

        {/* ------------------------------Table Work-------------------------------------- */}

        <div class="table-responsive mt-3">
          <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
            Show Entries - <input type="text" defaultValue="10" onChange={(e) => e.target.value === 0 || e.target.value === '0' ? setItemsPerPage() : setItemsPerPage(parseInt(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '35px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />
            &nbsp; Out of - {itemLength}
          </div>
          <table class="table table-nowrap align-middle mb-0">
            <thead style={{ backgroundColor: color.tableHeader }}>
              <tr style={{ color: color.darkText }}>
                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                  S.No.
                </th>
                <th style={{ color: color.darkText }} scope="col">Owner</th>
                <th style={{ color: color.darkText }} scope="col">Product</th>
                <th style={{ color: color.darkText }} scope="col">Recommendation</th>
                <th style={{ color: color.darkText }} scope="col">Updates</th>
                <th style={{ color: color.darkText,width:'100px'}} scope="col">Profit/Loss</th>
              </tr>
            </thead>
            <tbody>
              {calls.length > 0 ?
                currentItems.map((c, count) => {
                  return <UpdatesList data={c} updates={updates} researchUpdate={researchUpdate} product={product} users={users} key={c.id} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                })
                :
                null}
            </tbody>
          </table>
          {currentItems.length > 0 &&
            <div style={{ color: color.lightText, fontSize: '13px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
          }

          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="pagination-active"
            renderOnZeroPageCount={null}
          />

        </div>
      </div>
      {isLoading &&
        <Loading />}

      {/* ------------------------------Filter modal----------------------------------- */}

      <div className="offcanvas offcanvas-end" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} tabIndex="-1" id="LeadsFilterCanvas" aria-labelledby="offcanvasRightLabel">
        <div className="modal-content">
          <div className="modal-header px-3 pt-3" style={{ borderBottomColor: color.tableBorder }}>
            <div className="modal_title" style={{ color: color.darkText }} id="offcanvasRightLabel">Filter User</div>
            <span type="button" id='canvaCloseId' className='modal_close' data-bs-dismiss="offcanvas" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>

          </div>
        </div>
        <hr style={{ borderTop: "0.5px solid " + color.tableBorder, opacity: '1' }} />
        <div className="offcanvas-body">

          <div className="mb-4">
            <label className='modal_label' style={{ color: color.darkText }}>Call Owner</label>
            <Select
              styles={customStyles}
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={options}
              onChange={(e) => setOwner(e)}
              value={owner}
            />
          </div>

          <div className="mb-4">
            <label className='modal_label' style={{ color: color.darkText }}>Product</label>
            <Select
              styles={customStyles}
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={segment}
              onChange={(e) => setFilterProduct(e)}
              value={filterProduct}
            />
          </div>

          <label className='modal_label' style={{ color: color.lightText }}>Recommendation Date Range</label>
          <div className="mb-4 mt-1">
            <label className='modal_label' style={{ color: color.darkText }}>Start Date</label>
            <input type="date" value={dateRange.startDate} onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className="mb-4">
            <label className='modal_label' style={{ color: color.darkText }}>End Date</label>
            <input type="date" value={dateRange.endDate} onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className="pt-4">

            <button className="primaryButton me-3" id='filterBtn' onClick={applyFilter}>Apply filter</button>
            <button className="secondaryButton" onClick={clearFilter}>Clear filter</button>

          </div>
        </div>
      </div>


    </>
  )
}

export default Updates