import React, { useState } from 'react'
import { useColorStore } from '../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { useCategoryStore } from '../../../Store/ProductStore/CategoryStore'
import { useToasts } from 'react-toast-notifications';
import moment from 'moment/moment';
import { useLotsizeStore } from '../../../Store/LotsizeStore/LotsizeStore';

const LotsizeList = (props) => {
    const { addToast } = useToasts();
    const { fetchLotsize, updateLotsize, deleteLotsize } = useLotsizeStore()
    const { color } = useColorStore()
    const { data, count, isLoading, setIsLoading } = props
    const [error, setError] = useState(false)
    const [payload, setPayload] = useState({
        stock_name: data.stock_name,
        lotsize: data.lotsize,
        exp: data.exp,
    })

    const handleSubmit = () => {
        if (payload.stock_name.length === 0 || payload.lotsize.length === 0 || payload.exp.length === 0) {
            setError(true)
        } else {
            setError(false)
            setIsLoading(true)
            updateLotsize(payload, data.id)
                .then(res => {
                    fetchLotsize()
                    setIsLoading(false)
                    setPayload({ ...payload, stock_name: payload.stock_name, lotsize: payload.lotsize, exp: payload.exp })
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }
    }

    const handleDelete = () => {
        let d = document.getElementById("dltBtn")
        d.click()
        setIsLoading(true)
        deleteLotsize(data.id)
            .then(res => {
                fetchLotsize()
                setIsLoading(false)
                addToast('Success...', { appearance: 'success' });
            }).catch(err => {
                console.log(err);
                setIsLoading(false)
                addToast('Error...', { appearance: 'error' });
            })
    }

    return (
        <>
            <tr style={{ color: color.darkText }}>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
                    {count}
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>
                    <a style={{ color: color.darkText }}>{data.stock_name}</a>
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.lotsize}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{moment(data.exp).format('ll')}</td>

                <td style={{ borderBottom: '1px solid ' + color.tableBorder }}>
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <span type="button" class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryModal" + data.id} style={{ color: color.blue }}><Icon icon="akar-icons:edit" fontSize={18} /></span>
                        </li>
                        <li class="list-inline-item">
                            <span type={'button'} class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal" + data.id} style={{ color: color.red }}><Icon icon="ep:delete" fontSize={17} /></span>
                        </li>
                    </ul>
                </td>
            </tr>

            {/* ----------------------Update Modal---------------------------- */}

            <div class="modal fade" id={"categoryModal" + data.id} tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Update Lotsize</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Name <span className="text-danger">*</span></label>
                                <input placeholder='Enter Stock Name' value={payload.stock_name} onChange={(e) => setPayload({ ...payload, stock_name: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-4'>
                                <label className='modal_label' style={{ color: color.darkText }}>Code <span className="text-danger">*</span></label>
                                <input placeholder='Enter Lotsize' value={payload.lotsize} onChange={(e) => setPayload({ ...payload, lotsize: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-4'>
                                <label className='modal_label' style={{ color: color.darkText }}>Expiry Date <span className="text-danger">*</span></label>
                                <input type='date' value={payload.exp} onChange={(e) => setPayload({ ...payload, exp: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Update"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------------------------Delete Modal------------------------ */}

            <div class="modal fade" id={"categoryDeleteModal" + data.id} tabindex="-1" aria-labelledby="categoryDeleteModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to delete ( {data.stock_name} ) lotsize ? You would'nt be able to retrieve this.</div>
                        </div>


                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id="dltBtn" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleDelete}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Delete"}</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LotsizeList