import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useComplianceStore = create((set) => ({
    invoiceList: [],invoiceEnq:[],invoiceUsers:[],
    fetchInvoices: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/view-invoices/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ invoiceList: data.data,invoiceEnq:data.enquiry,invoiceUsers:data.users });
    },
    updateInvoices: async (id,payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/update-invoice/'+id+'/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    updateInvoiceList: async (list,payload) => {
        let items = [...list];
        for (let i = 0; i < payload.length; i++) {
            const e = payload[i];
            var foundIndex = await items.findIndex(x => x.id === e.id);
            items[foundIndex] = e
        }
        set({ invoiceList: items });
    }
        
}));