import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'
import { useUserStore } from '../../../../../Store/UserStore/UserStore'
import { useToasts } from 'react-toast-notifications'
import { useUserJoiningInfoStore } from '../../../../../Store/UserStore/UserJoiningInfoStore'

const JoiningInfo = (props) => {
  const { addToast } = useToasts()
  const { color, isLoading, setIsLoading, currentUser, permission } = props
  const { users, fetchUsers } = useUserStore()
  const { usersJoiningInfo, fetchUsersJoiningInfo, createUsersJoiningInfo } = useUserJoiningInfoStore()
  const [error, setError] = useState(false)
  const [payload, setPayload] = useState({
    user: currentUser.id,
    emp_id: usersJoiningInfo.emp_id,
    joining_date: usersJoiningInfo.joining_date,
    aadhar: usersJoiningInfo.aadhar,
    pan: usersJoiningInfo.pan,
    company_number: usersJoiningInfo.company_number,
    company_whatsapp: usersJoiningInfo.company_whatsapp,
    special_feedback: usersJoiningInfo.special_feedback,
    qualification: usersJoiningInfo.qualification,
    degree: usersJoiningInfo.degree,
    exp_year: usersJoiningInfo.exp_year,
  })

  const handleSubmit = () => {
    if (payload.emp_id.length === 0 || payload.joining_date.length === 0) {
      setError(true)
    } else {
      setError(false)
      createUsersJoiningInfo(payload)
        .then(res => {
          fetchUsersJoiningInfo(currentUser.id)
          setIsLoading(false)
          setPayload({
            ...payload,
            user: currentUser.id,
            emp_id: payload.emp_id,
            joining_date: payload.joining_date,
            aadhar: payload.aadhar,
            pan: payload.pan,
            company_number: payload.company_number,
            company_whatsapp: payload.company_whatsapp,
            special_feedback: payload.special_feedback,
            qualification: payload.qualification,
            degree: payload.degree,
            exp_year: payload.exp_year,
          })
          addToast('Success...', { appearance: 'success' });
        }).catch(err => {
          console.log(err);
          setIsLoading(false)
          addToast('Error...', { appearance: 'error' });
        })
    }
  }

  useEffect(() => {
    if (usersJoiningInfo) {
      setPayload({
        ...payload,
        user: currentUser.id,
        emp_id: usersJoiningInfo.emp_id,
        joining_date: usersJoiningInfo.joining_date,
        aadhar: usersJoiningInfo.aadhar,
        pan: usersJoiningInfo.pan,
        company_number: usersJoiningInfo.company_number,
        company_whatsapp: usersJoiningInfo.company_whatsapp,
        special_feedback: usersJoiningInfo.special_feedback,
        qualification: usersJoiningInfo.qualification,
        degree: usersJoiningInfo.degree,
        exp_year: usersJoiningInfo.exp_year,
      })
    } else {
      setPayload({
        ...payload,
        user: currentUser.id,
        emp_id: "",
        joining_date: "",
        aadhar: "",
        pan: "",
        company_number: "",
        company_whatsapp: "",
        special_feedback: "",
        qualification: "",
        degree: "",
        exp_year: "",
      })
    }
  }, [usersJoiningInfo])

  useEffect(() => {
    fetchUsersJoiningInfo(currentUser.id)
  }, [])


  return (
    <>
      <div className="mt-4">
        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>

          {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>Employee ID and Joining Date Required...!</div>}

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Employee ID</label>
            <input placeholder='Enter employee id' value={payload.emp_id} disabled={!permission} onChange={(e) => setPayload({ ...payload, emp_id: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Joining Date</label>
            <input type='date' placeholder='Enter ' value={payload.joining_date} disabled={!permission} onChange={(e) => setPayload({ ...payload, joining_date: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Aadhar Number</label>
            <input type='text' placeholder='Enter aadhar number ' value={payload.aadhar} disabled={!permission} onChange={(e) => setPayload({ ...payload, aadhar: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Pan Number</label>
            <input type='text' placeholder='Enter pan number ' value={payload.pan} disabled={!permission} onChange={(e) => setPayload({ ...payload, pan: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Company Number</label>
            <input type='text' placeholder='Enter company number ' value={payload.company_number} disabled={!permission} onChange={(e) => setPayload({ ...payload, company_number: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Company Whatsapp</label>
            <input type='text' placeholder='Enter company whatsapp ' value={payload.company_whatsapp} disabled={!permission} onChange={(e) => setPayload({ ...payload, company_whatsapp: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Qualification</label>
            <input type='text' placeholder='Enter company whatsapp ' value={payload.qualification} disabled={!permission} onChange={(e) => setPayload({ ...payload, qualification: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Qualification Title</label>
            <input type='text' placeholder='Enter company whatsapp ' value={payload.degree} disabled={!permission} onChange={(e) => setPayload({ ...payload, degree: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Year of Experience</label>
            <input type='text' placeholder='Enter company whatsapp ' value={payload.exp_year} disabled={!permission} onChange={(e) => setPayload({ ...payload, exp_year: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Special Feedback</label>
            <textarea placeholder='Special feedback...' rows={4} value={payload.special_feedback} disabled={!permission} onChange={(e) => setPayload({ ...payload, special_feedback: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
            </textarea>
          </div>

        </div>

        {permission &&
          <div className=" pt-4 pb-3" style={{ borderTopColor: color.tableBorder }}>
            <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
              <>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                Please wait...
              </>

              : "Submit"}</button>
          </div>
        }
      </div>
    </>
  )
}

export default JoiningInfo