import React, { useState, useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import PastPerformanceList from './PastPerformanceList';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../Loading';
import * as XLSX from "xlsx";
import { usePastPerformanceStore } from '../../../Store/ResearchStore/PastPerformanceStore/PastPerformanceStore';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';
import { useProductStore } from './../../../Store/ProductStore/ProductStore';

const PastPerformance = () => {
    const { addToast } = useToasts();
    const { pastPerformance, fetchPastPerformance, createPastPerformance } = usePastPerformanceStore()
    const { product, fetchProduct } = useProductStore()
    const { color } = useColorStore()
    let token = JSON.parse(localStorage.getItem('user'))

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (product.length === 0) {
            fetchProduct()
        }
    }, [])

    useEffect(() => {
        fetchPastPerformance()
    }, [])

    const [segment, setSegment] = useState('')
    const [file, setFile] = useState()
    const [error2, setError2] = useState(false)

    const handleImport = () => {
        if (segment.length === 0 || !file) {
            setError2(true)
        } else {
            setError2(false)
            setIsLoading(true)
            let formData = new FormData()
            formData.append('segment', segment)
            formData.append('files', file)
            createPastPerformance(formData)
                .then(res => {
                    fetchPastPerformance()
                    setFile()
                    setSegment('')
                    setIsLoading(false)
                    let d = document.getElementById('mclosebtn')
                    d.click()
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })


        }
    }

    useEffect(() => {
        fetchPastPerformance()
    }, [])

    const [searchInput, setSearchInput] = useState("")
    const [searchParam] = useState(["stock_name", "pastPerformance"]);

    function search(items) {
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(searchInput.toLowerCase()) > -1
                );
            });
        });
    }

    let alist = [{
        stock_name: '',
        pastPerformance: '',
        exp: ''
    }]

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (searchInput.length > 0) {
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(search(pastPerformance).slice(itemOffset, endOffset));
            setPageCount(Math.ceil(search(pastPerformance).length / itemsPerPage));
            setitemLength(search(pastPerformance).length)
            setItemEndSet(endOffset)
        }
        else {
            // Fetch items from another resources.
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(pastPerformance.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(pastPerformance.length / itemsPerPage));
            setItemEndSet(endOffset)
            setitemLength(pastPerformance.length)
        }

    }, [itemOffset, itemsPerPage, pastPerformance, searchInput]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % pastPerformance.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search" style={{ position: 'relative' }}>
                        {/* <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} />
                        <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span> */}
                    </div>
                    <div>
                        <Ripples color='rgba(255,255,255,0.2)' className='mx-1' during={1000}>
                            <button className="primaryButton" data-bs-toggle="modal" data-bs-target="#lotsizeImportModal" >Add New</button>
                        </Ripples>
                    </div>
                </div>

                {/* ------------------------------Table Work-------------------------------------- */}

                <div class="table-responsive">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <input type="text" defaultValue="10" onChange={(e) => e.target.value === 0 || e.target.value === '0' ? setItemsPerPage() : setItemsPerPage(parseInt(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '35px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />
                        &nbsp; Out of - {itemLength}
                    </div>
                    <table class="table table-nowrap align-middle mb-0">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Product</th>
                                <th style={{ color: color.darkText }} scope="col">File</th>
                                <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pastPerformance.length > 0 ?
                                currentItems.map((c, count) => {
                                    return <PastPerformanceList data={c} product={product} key={c.id} count={count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                })
                                :
                                null}
                        </tbody>
                    </table>

                    {currentItems.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px', marginTop: '5px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
            {isLoading &&
                <Loading />}

            {/* ----------------------Import Modal---------------------------- */}

            <div class="modal fade" id="lotsizeImportModal" tabindex="-1" aria-labelledby="lotsizeImportModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="lotsizeImportModalLabel">Add Past Performance</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error2 && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>Please Select CSV File...!</div>}

                            <div className='mb-4'>
                                <label className='modal_label' style={{ color: color.darkText }}>Product <span className="text-danger">*</span></label>
                                <select value={segment} onChange={(e) => setSegment(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">--------Select Product---------</option>
                                    {product.map((s) => {
                                        return <option value={s.id} key={s.id}>{s.name}</option>
                                    })}
                                </select>
                            </div>
                            <div className='mb-4'>
                                <label className='modal_label' style={{ color: color.darkText }}>Performance File <span className="text-danger">*</span></label>
                                <input type='file' onChange={(e) => setFile(e.target.files[0])} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id='mclosebtn' style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleImport}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : " Save "}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PastPerformance