import React, { useState, useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import ComplianceMsgList from './ComplianceMsgList.js';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../Loading';
import ReactPaginate from 'react-paginate';
import { useComplianceMsgStore } from './../../../Store/ResearchStore/ComplianceMsgStore/ComplianceMsgStore';

const ComplianceMsg = () => {
    const { addToast } = useToasts();
    const { complianceMsg, fetchComplianceMsg, createComplianceMsg } = useComplianceMsgStore()
    const { color } = useColorStore()
    let token = JSON.parse(localStorage.getItem('user'))

    const [isLoading, setIsLoading] = useState(false)

    const [payload, setPayload] = useState({
        name: '',
        msg: '',
    })

    const [error, setError] = useState(false)

    const handleSubmit = () => {
        if (payload.name.length === 0 || payload.msg.length === 0) {
            setError(true)
        } else {
            setIsLoading(true)
            createComplianceMsg(payload)
                .then(res => {
                    fetchComplianceMsg()
                    setIsLoading(false)
                    setPayload({
                        ...payload,name: '',
                        msg: '',
                    })
                    setIsLoading(false)
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }
    }

    useEffect(() => {
        fetchComplianceMsg()
    }, [])

    const [searchInput, setSearchInput] = useState("")
    const [searchParam] = useState(["name"]);

    function search(items) {
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(searchInput.toLowerCase()) > -1
                );
            });
        });
    }

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (searchInput.length > 0) {
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(search(complianceMsg).slice(itemOffset, endOffset));
            setPageCount(Math.ceil(search(complianceMsg).length / itemsPerPage));
            setitemLength(search(complianceMsg).length)
            setItemEndSet(endOffset)
        }
        else {
            // Fetch items from another resources.
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(complianceMsg.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(complianceMsg.length / itemsPerPage));
            setItemEndSet(endOffset)
            setitemLength(complianceMsg.length)
        }

    }, [itemOffset, itemsPerPage, complianceMsg, searchInput]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % complianceMsg.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search" style={{ position: 'relative' }}>
                        <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} />
                        <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                    </div>
                    <div>
                        <Ripples color='rgba(255,255,255,0.2)' during={1000}>
                            <button className="primaryButton" data-bs-toggle="modal" data-bs-target="#categoryModal" >Add New</button>
                        </Ripples>

                    </div>
                </div>

                {/* ------------------------------Table Work-------------------------------------- */}

                <div class="table-responsive">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <input type="text" defaultValue="10" onChange={(e) => e.target.value === 0 || e.target.value === '0' ? setItemsPerPage() : setItemsPerPage(parseInt(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '35px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />
                        &nbsp; Out of - {itemLength}
                    </div>
                    <table class="table table-nowrap align-middle mb-0">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Template Name</th>
                                <th style={{ color: color.darkText }} scope="col">Message</th>
                                <th style={{ color: color.darkText }} scope="col">Send</th>
                                <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {complianceMsg.length > 0 ?
                                currentItems.map((c, count) => {
                                    return <ComplianceMsgList data={c} key={c.id} count={count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                })
                                :
                                null}
                        </tbody>
                    </table>
                    {currentItems.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px',marginTop:'5px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
            {isLoading &&
                <Loading />}
            {/* ----------------------Add Modal---------------------------- */}

            <div class="modal fade" id="categoryModal" tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Add Compliance Message</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Template Name</label>
                                <input placeholder='Enter...' value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Message Format</label>
                                <textarea placeholder='Enter...' rows={6} value={payload.msg} onChange={(e) => setPayload({ ...payload, msg: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'></textarea>
                            </div>

                        </div>

                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Save"}</button>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default ComplianceMsg