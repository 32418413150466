import React, { useState, useEffect } from 'react'
import { useColorStore } from '../../../Store/ColorStore';
import { useToasts } from 'react-toast-notifications';
import Cash from './Cash/Cash';
import Future from './Future/Future';
import Options from './Options/Options';
import { useResearchProductStore } from '../../../Store/ResearchStore/ResearchProductStore/ResearchProductStore';
import { useProductStore } from '../../../Store/ProductStore/ProductStore';
import { useLotsizeStore } from '../../../Store/LotsizeStore/LotsizeStore';

const Calls = () => {
  const { addToast } = useToasts();
  const { color } = useColorStore()
  const { researchProduct, fetchResearchProduct } = useResearchProductStore()
  const { product, fetchProduct } = useProductStore()
  const {fetchLotsize,lotsize} = useLotsizeStore()
  useEffect(() => {
    if (researchProduct.length === 0) {
      fetchResearchProduct()
    }
  }, [])
  useEffect(() => {
    if (product.length === 0) {
      fetchProduct()
    }
  }, [])
  useEffect(() => {
    if (lotsize.length === 0) {
      fetchLotsize()
    }
  }, [])

  let token = JSON.parse(localStorage.getItem('user'))
  const [pageRender, setPageRender] = useState('CASH')
  return (
    <>
      <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
        <div className="myrow">
          <div>
            <button className={pageRender === 'CASH' ? "successButton mx-1" : "lightButton mx-1"} style={{ color: pageRender === 'CASH' ? '' : color.darkText }} onClick={() => setPageRender('CASH')}>
              CASH
            </button>
            <button className={pageRender === 'FUTURE' ? "warningButton mx-1" : "lightButton mx-1"} style={{ color: pageRender === 'FUTURE' ? '' : color.darkText }} onClick={() => setPageRender('FUTURE')}>
              FUTURES
            </button>
            <button className={pageRender === 'OPTION' ? "dangerButton mx-1" : "lightButton mx-1"} style={{ color: pageRender === 'OPTION' ? '' : color.darkText }} onClick={() => setPageRender('OPTION')}>
              OPTIONS
            </button>
          </div>
        </div>

        <hr style={{ borderColor: color.borderColor, opacity: 1 }} />

        <div>
          {pageRender === 'CASH' ? <Cash /> : null}
          {pageRender === 'FUTURE' ? <Future /> : null}
          {pageRender === 'OPTION' ? <Options /> : null}
        </div>

      </div>
    </>
  )
}

export default Calls