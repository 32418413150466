import React, { useState } from 'react'
import { useColorStore } from '../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { useCategoryStore } from '../../../Store/ProductStore/CategoryStore'
import { useToasts } from 'react-toast-notifications';
import moment from 'moment/moment';
import { usePastPerformanceStore } from '../../../Store/ResearchStore/PastPerformanceStore/PastPerformanceStore';
import { fileUrl } from '../../../Export';

const PastPerformanceList = (props) => {
  const { addToast } = useToasts();
  const { fetchPastPerformance, updatePastPerformance, deletePastPerformance } = usePastPerformanceStore()
  const { color } = useColorStore()
  const { data, count, isLoading, setIsLoading, product } = props
  const [error, setError] = useState(false)
  const [segment, setSegment] = useState(data.segment)
  const [file, setFile] = useState()

  const handleSubmit = () => {
    if (!file) {
      setError(true)
    } else {
      setError(false)
      setIsLoading(true)
      let formData = new FormData()
      formData.append('segment', segment)
      formData.append('files', file)
      updatePastPerformance(formData, data.id)
        .then(res => {
          fetchPastPerformance()
          setIsLoading(false)
          setSegment(segment)
          setFile()
          addToast('Success...', { appearance: 'success' });
        }).catch(err => {
          console.log(err);
          setIsLoading(false)
          addToast('Error...', { appearance: 'error' });
        })
    }
  }

  const handleDelete = () => {
    let d = document.getElementById("dltBtn")
    d.click()
    setIsLoading(true)
    deletePastPerformance(data.id)
      .then(res => {
        fetchPastPerformance()
        setIsLoading(false)
        addToast('Success...', { appearance: 'success' });
      }).catch(err => {
        console.log(err);
        setIsLoading(false)
        addToast('Error...', { appearance: 'error' });
      })
  }

  const findProduct = (id) => {
    let x = product.find((f) => {
      return f.id === id
    })
    if (x) {
      return x.name
    } else {
      return null
    }
  }

  return (
    <>
      <tr style={{ color: color.darkText }}>
        <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
          {count}
        </td>
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>
          {findProduct(data.segment)}
        </td>
        <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}> <a target='_blank' href={fileUrl + data.files} className="infoButton" >File</a></td>

        <td style={{ borderBottom: '1px solid ' + color.tableBorder }}>
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <span type="button" class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryModal" + data.id} style={{ color: color.blue }}><Icon icon="akar-icons:edit" fontSize={18} /></span>
            </li>
            <li class="list-inline-item">
              <span type={'button'} class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal" + data.id} style={{ color: color.red }}><Icon icon="ep:delete" fontSize={17} /></span>
            </li>
          </ul>
        </td>
      </tr>

      {/* ----------------------Update Modal---------------------------- */}

      <div class="modal fade" id={"categoryModal" + data.id} tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
            <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
              <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Update Past Performance</div>
              <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
            </div>
            <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

              {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All Fields Required...!</div>}

              <div className='mb-4'>
                <label className='modal_label' style={{ color: color.darkText }}>Product <span className="text-danger">*</span></label>
                <select value={segment} disabled={true} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                  <option value="">--------Select Product---------</option>
                  {product.map((s) => {
                    return <option value={s.id} key={s.id}>{s.name}</option>
                  })}
                </select>
              </div>
              <div className='mb-4'>
                <label className='modal_label' style={{ color: color.darkText }}>Performance File <span className="text-danger">*</span></label>
                <input type='file' onChange={(e) => setFile(e.target.files[0])} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
              </div>

            </div>
            <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
              <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
              <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                  Please wait...
                </>

                : "Update"}</button>
            </div>
          </div>
        </div>
      </div>

      {/* ----------------------------Delete Modal------------------------ */}

      <div class="modal fade" id={"categoryDeleteModal" + data.id} tabindex="-1" aria-labelledby="categoryDeleteModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

            <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
              <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

              <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to delete ( {findProduct(data.segment)} ) Performance Sheet ? You would'nt be able to retrieve this.</div>
            </div>


            <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
              <button type="button" id="dltBtn" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
              <button type="button" class="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleDelete}>{isLoading ?
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                  Please wait...
                </>
                : "Delete"}</button>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default PastPerformanceList