import React, { useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../Store/ColorStore';
import { Icon } from '@iconify/react';
import Email from './Email';
import { Scrollbars } from 'react-custom-scrollbars';
import { useEmailStore } from '../../Store/EmailStore/EmailStore';
import { Suspense } from 'react';
import { useState } from 'react';
import Loading from '../../Loading'
import { useToasts } from 'react-toast-notifications';
import ReactPaginate from 'react-paginate';

const EmailList = React.lazy(() => import('./EmailList'));

const Trash = () => {
  const { addToast } = useToasts()
  const { trashemail, fetchTrashEmail, trashemailList, trashEmail, starredEmail } = useEmailStore()
  const { color } = useColorStore()
  const [ids, setIds] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchTrashEmail()
  }, [])

  // -------------------Search Work--------------------------------
  const [searchInput, setSearchInput] = useState("")
  const [searchParam] = useState(["subject", "mail"]);

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]
            .toString()
            .toLowerCase()
            .indexOf(searchInput.toLowerCase()) > -1
        );
      });
    });
  }

  // --------------------Pagination Works------------------------------

  // Pagination Work Start

  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemEndSet, setItemEndSet] = useState(0)
  const [itemLength, setitemLength] = useState(0)

  useEffect(() => {
    if (searchInput.length > 0) {
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
      setCurrentItems(search(trashemail).slice(itemOffset, endOffset));
      setPageCount(Math.ceil(search(trashemail).length / itemsPerPage));
      setitemLength(search(trashemail).length)
      setItemEndSet(endOffset)
    }
    else {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
      setCurrentItems(trashemail.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(trashemail.length / itemsPerPage));
      setItemEndSet(endOffset)
      setitemLength(trashemail.length)
    }
  }, [itemOffset, itemsPerPage, trashemail, searchInput]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % trashemail.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="myrow justify_space_between mb-4 align_center">
        <h6 style={{ color: color.darkText, marginTop: '5px' }}>Trash Mail</h6>
        <div className="ico-search" style={{ position: 'relative' }}>
          <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
          <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
        </div>
      </div>
      <div style={{ height: '100vh' }}>

        {currentItems.length > 0 ?

          <Scrollbars autoHide={true}>

            {currentItems.map((e, count) => {
              return <Suspense key={e} fallback={<div></div>}>
                <EmailList data={e} ids={ids} page={'T'} setIds={setIds} isLoading={isLoading} setIsLoading={setIsLoading} list2={trashemailList} />
              </Suspense>
            })
            }


            {currentItems.length > 0 &&
              <div style={{ color: color.lightText, fontSize: '13px', marginTop: '20px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
            }

            <ReactPaginate
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="pagination-active"
              renderOnZeroPageCount={null}
            />
          </Scrollbars>
          : <><div style={{ color: color.darkText, fontSize: '14px', textAlign: 'left' }}>No Record Found...!</div></>
        }
      </div>

      {isLoading && <Loading />}
    </>
  )
}

export default Trash