import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Category from '../../Admin/Category/Category';
import Dashboard from '../Dashboard/Dashboard';
import SubCategory from '../../Admin/SubCategory/SubCategory';
import Product from '../../Admin/Product/Product';
import { AdminPath } from '../../Admin/AdminRoutes/AdminPath';
import Email from '../../Email/Email';
import UserDetails from '../../Admin/Accounts/Users/UserDetails/UserDetails';
import ScrollToTop from '../../Admin/AdminRoutes/ScrollToTop';
import Lotsize from '../Lotsize/Lotsize';
import ResearchProduct from '../ResearchProduct/ResearchProduct';
import ResearchUpdate from '../ResearchUpdate/ResearchUpdate';
import Calls from './../Calls/Calls';
import Updates from './../Updates/Updates';
import Sar from '../Sar/Sar';
import ComplianceMsg from '../ComplianceMsg/ComplianceMsg';
import PastPerformance from '../PastPerformance/PastPerformance';

const ResearchRoutes = () => {
  return (
    <>
        <ScrollToTop />
            <Routes>
                <Route path={AdminPath.DASHBOARD} element={<Dashboard />} />
                <Route path={AdminPath.CATEGORY} element={<Category />} />
                <Route path={AdminPath.SUBCATEGORY} element={<SubCategory />} />
                <Route path={AdminPath.PRODUCT} element={<Product />} />
                <Route path={AdminPath.EMAIL} element={<Email />} />
                <Route path={AdminPath.PROFILE + "/:userId"} element={<UserDetails />} />
                <Route path={AdminPath.LOTSIZE} element={<Lotsize />} />
                <Route path={AdminPath.RESEARCHPRODUCT} element={<ResearchProduct />} />
                <Route path={AdminPath.RESEARCHPUPDATE} element={<ResearchUpdate />} />
                <Route path={AdminPath.RESEARCHCALL} element={<Calls />} />
                <Route path={AdminPath.RESEARCHUPDATES} element={<Updates />} />
                <Route path={AdminPath.SAR} element={<Sar />} />
                <Route path={AdminPath.COMPLIANCEMSG} element={<ComplianceMsg />} />
                <Route path={AdminPath.PASTPERFORMANCE} element={<PastPerformance />} />
                
            </Routes>
        </>
  )
}

export default ResearchRoutes