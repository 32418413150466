import React, { useState, useEffect } from 'react'
import { useColorStore } from '../../../../Store/ColorStore'
import { useNavigate, useParams } from 'react-router-dom'
import { Icon } from '@iconify/react'
import './LeadsDetails.css'
import { useUserStore } from './../../../../Store/UserStore/UserStore';
import Loading from './../../../../Loading';
import Profile from './SubPages/Profile';
import { Avatar } from '@mui/material'
import Ripples from 'react-ripples';
import { useLeadDetailStore } from '../../../../Store/LeadStore/LeadDetailStore'
import { useLeadActionStore } from '../../../../Store/LeadStore/LeadAction'
import { useToasts } from 'react-toast-notifications'
import moment from 'moment'
import History from './History/History'
import Services from './Services/Services'
import Invoices from './Invoices/Invoices'
import { useUserPermissionStore } from './../../../../Store/UserPermissionStore/UserPermissionStore';
import { userSegrigation, userType } from '../../../../Export'
import UserCalls from './UserCalls/UserCalls'

const LeadsDetails = () => {
    const { addToast } = useToasts();
    const navigation = useNavigate()
    const { restoreLeads, trashLeads, assignLeads, bulkAssignLeads } = useLeadActionStore()
    const { color } = useColorStore()
    let { userId } = useParams();
    const { users, fetchUsers } = useUserStore()
    const { leadUser, leadEnquiry, fetchLeadDetails, addFollowUp } = useLeadDetailStore()
    const [isLoading, setIsLoading] = useState(false)
    const [checked, setChecked] = useState([])

    // -----------------Permission Work------------------
    let token = JSON.parse(localStorage.getItem('user'))
    const [permission, setPermission] = useState(false)
    const [permission2, setPermission2] = useState(false)
    const { userPermissions } = useUserPermissionStore()
    useEffect(() => {
        let d = userPermissions.find((f) => {
            return f.permission_name === 'LEADS_PERMISSION'
        })
        let x = userSegrigation.find((f) => {
            return f.key === token.user_type
        })
        if (d && x) {
            setPermission(d[x.name])
        }
    }, [userPermissions])

    useEffect(() => {
        let d = userPermissions.find((f) => {
            return f.permission_name === 'OWNER_CHANGE_PERMISSION'
        })
        let x = userSegrigation.find((f) => {
            return f.key === token.user_type
        })
        if (d && x) {
            setPermission2(d[x.name])
        }
    }, [userPermissions])

    // --------------------------------------------

    useEffect(() => {
        if (leadUser) {
            setChecked([leadUser.id])
        }
    }, [leadUser])

    const t = {
        profile: 'profile',
        history: 'history',
        invoice: 'invoice',
        services: 'services',
        callHistory: 'callHistory',
    }

    const [currentUser, setCurrentUser] = useState()

    useEffect(() => {
        if (users.length === 0) {
            fetchUsers()
        }
    }, [])

    useEffect(() => {
        fetchLeadDetails(userId)
    }, [userId])


    useEffect(() => {
        if (leadEnquiry) {
            const d = users.find((f) => {
                return f.id === Number(leadEnquiry.assign_to_id)
            })
            if (d) {
                setCurrentUser(d)
            } else {
                setCurrentUser()
            }
        }
    }, [users, leadEnquiry])

    const [tabs, setTabs] = useState(t.profile)

    const findFollowUp = (val) => {
        if (val === 'SS') {
            return 'Service Taken'
        }
        else if (val === 'INT') {
            return 'Interested'
        }
        else if (val === 'N-INT') {
            return 'Not Interested'
        }
        else if (val === 'NPC') {
            return 'NPC'
        }
        else if (val === 'NNE') {
            return 'Number not Exist'
        } else if (val === 'WD') {
            return 'Wasted Data'
        } else if (val === 'CB') {
            return 'Call Back'
        }
        
        else {
            return ''
        }
    }

    const handleLeadTrash = () => {
        if (checked.length > 0) {
            let d = document.getElementById("dltBtn")
            d.click()
            setIsLoading(true)
            trashLeads(checked)
                .then(res => {
                    fetchLeadDetails(leadUser.id)
                    setIsLoading(false)
                    addToast('Leads Sent to Trash...', { appearance: 'success' });
                })
                .catch(err => {
                    setIsLoading(false)
                    addToast('Something went wrong...', { appearance: 'error' });
                })
        } else {
            addToast('Please select leads to trash...', { appearance: 'error' });
        }
    }
    const handleLeadRestore = () => {
        if (checked.length > 0) {
            let d = document.getElementById("restoreBtn")
            d.click()
            setIsLoading(true)
            restoreLeads(checked)
                .then(res => {
                    fetchLeadDetails(leadUser.id)
                    setIsLoading(false)
                    addToast('Lead Restore Success...', { appearance: 'success' });
                })
                .catch(err => {
                    setIsLoading(false)
                    addToast('Something went wrong...', { appearance: 'error' });
                })
        } else {
            addToast('Please select leads to restore...', { appearance: 'error' });
        }
    }

    // -------------Lead Assign Work-------------------------------
    const [assignTo, setAssignTo] = useState("")
    const [error, setError] = useState(false)

    useEffect(() => {
        if (currentUser) {
            setAssignTo(currentUser.id)
        } else {
            setAssignTo("")
        }
    }, [currentUser])

    const handleLeadAssign = () => {
        if (checked.length > 0 && assignTo.length > 0) {
            let d = document.getElementById("dltBtn")
            d.click()
            setIsLoading(true)
            let ndata = {
                checked: checked,
                assign_to: assignTo
            }
            assignLeads(ndata)
                .then(res => {
                    fetchLeadDetails(leadUser.id)
                    setIsLoading(false)
                    addToast('Owner Changed...', { appearance: 'success' });
                })
                .catch(err => {
                    setIsLoading(false)
                    addToast('Something went wrong...', { appearance: 'error' });
                })

        } else {
            addToast('Please select user to assign leads...', { appearance: 'error' });
        }
    }

    // ----------------------------------Follow up Work------------------------------

    const [followupPayload, setFollowupPayload] = useState({
        follow_up_state: '',
        last_follow_up: '',
        reminder_date: '',
        reminder_message: '',
    })

    const handleAddFollowUp = () => {
        if (followupPayload.follow_up_state.length > 0 && followupPayload.last_follow_up.length > 0) {
            setIsLoading(true)
            setError(false)
            addFollowUp(leadUser.id, followupPayload)
                .then(res => {
                    setFollowupPayload({
                        ...followupPayload, follow_up_state: '',
                        last_follow_up: '',
                        reminder_date: '',
                        reminder_message: '',
                    })
                    fetchLeadDetails(leadUser.id)
                    setIsLoading(false)
                    addToast('Success...', { appearance: 'success' });
                })
                .catch(err => {
                    setIsLoading(false)
                    addToast('Something went wrong...', { appearance: 'error' });
                })

        } else {
            setError(true)
        }
    }

    const [leadsource, setLeadsource] = useState('')
    useEffect(() => {
        if (leadEnquiry) {
            if (leadEnquiry.lead_source === 'OT') {
                setLeadsource('Other')
            }
            else if (leadEnquiry.lead_source === 'FB') {
                setLeadsource('Facebook')
            }
            else if (leadEnquiry.lead_source === 'G') {
                setLeadsource('Google')
            }
            else if (leadEnquiry.lead_source === 'O') {
                setLeadsource('Organic')
            }
            else if (leadEnquiry.lead_source === 'B') {
                setLeadsource('Bulk')
            }
        }

    }, [leadEnquiry])

    if (leadUser && leadEnquiry) {
        return (
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="mb-2" style={{ marginTop: '-10px' }}>
                    <Icon icon="cil:arrow-left" className='modal_close font-30' color={color.darkText} type='button' onClick={() => navigation(-1)} />
                </div>

                <div className="myrow justify_space_between">
                    <div className='myrow'>
                        <Avatar
                            sx={{ color: "#fff", bgcolor: "#0C768A", fontSize: 30, fontWeight: 500, width: '75px', height: '75px', margin: '' }}
                            alt={leadUser.name}
                        // src={fileUrl + currentUser.proimg}
                        />

                        <div className="ms-3">
                            <div style={{ color: color.darkText, fontSize: '17px', fontWeight: 450, marginBottom: '5px' }}>{leadUser.name} &nbsp; {token.user_type === userType.admin || token.user_type === userType.sub_admin ? <span><small className='px-2 py-1 text-success-emphasis bg-success-subtle border border-success-subtle rounded-2' style={{ fontSize: '11px' }}>{leadsource}</small></span> : null}</div>
                            <div style={{ color: color.lightText, fontSize: '14px', fontWeight: 400, textTransform: 'lowercase', marginBottom: '2px' }}><Icon icon="fluent:call-28-regular" fontSize={14} style={{ marginRight: '5px' }} color={color.blue} /> +91-{leadUser.contact}</div>
                            <div style={{ color: color.lightText, fontSize: '14px', fontWeight: 400, textTransform: 'lowercase', marginBottom: '1px' }}><Icon icon="fluent:mail-24-regular" fontSize={15} style={{ marginRight: '5px' }} color={color.blue} /> {leadUser.email ? leadUser.email : '-'}</div>
                            <div className="d-flex mt-3">
                                <div style={{ color: color.lightText, fontSize: '13px', fontWeight: 400, textTransform: 'capitalize', marginBottom: '1px', paddingRight: '15px' }}><Icon icon="solar:user-check-broken" fontSize={16} style={{ marginRight: '5px' }} color={color.blue} /> {currentUser ? currentUser.name : leadEnquiry.state === 'T' ? 'Trash' : leadEnquiry.state === 'A' ? 'Assigned' : 'Un-Assigned'}</div>
                                {leadEnquiry.follow_up_state &&
                                    <div style={{ color: color.lightText, fontSize: '13px', fontWeight: 400, textTransform: 'capitalize', paddingLeft: '15px', borderLeft: "0.5px solid " + color.lightText }}><Icon icon="simple-line-icons:check" style={{ marginRight: '5px' }} fontSize={14} color={color.blue} />{findFollowUp(leadEnquiry.follow_up_state)} {leadEnquiry.service_state ? leadEnquiry.service_state === 'P' ? '(Paid Client)' : leadEnquiry.service_state === 'F' ? "(Free Trial)" : leadEnquiry.service_state === 'R' ? '(Renewed Client)' : null : null}</div>
                                }
                            </div>

                            {leadEnquiry.last_followup &&
                                <div className='followUp_message' style={{ backgroundColor: color.inputBackground, color: color.darkText, border: "1px solid " + color.tableBorder }}>
                                    <div>{leadEnquiry.last_followup}</div>
                                    <div className="text-start" style={{ color: color.lightText, fontSize: '11px', marginTop: '5px' }}>
                                        <Icon icon="uiw:time-o" color={color.lightText} style={{ marginTop: '-1px' }} fontSize={11} /> &nbsp;{moment(leadEnquiry.follow_up_time).format('lll')}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <div className='myrow'>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={650}>
                                <button data-bs-toggle="modal" disabled={leadEnquiry.follow_up_state ? leadEnquiry.follow_up_state === 'SS' ? true : false : false} data-bs-target="#followUpModal" className={permission || permission2 ? "primaryButton right_border_radius" : "primaryButton"} style={{ marginRight: '1px' }}><Icon icon="icon-park-outline:add-two" fontSize={18} /></button>
                            </Ripples>

                            {permission2 && <>
                                <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={650}>
                                    <button data-bs-toggle="modal" data-bs-target="#leadAssignModal" className="primaryButton left_border_radius right_border_radius" style={{ marginRight: '1px' }}><Icon icon="mingcute:user-edit-line" fontSize={18} /></button>
                                </Ripples>

                                <Ripples color='rgba(255,255,255,0.2)' className='dis_block' during={650}>
                                    <button data-bs-toggle="modal" data-bs-target="#leadRestoreModal" className={permission ? "primaryButton left_border_radius right_border_radius" : "primaryButton left_border_radius right_border_radius"} style={{ marginRight: '0.7px' }}><Icon icon="ic:round-settings-backup-restore" fontSize={18} /></button>
                                </Ripples>
                            </>}
                            {permission &&
                                <Ripples color='rgba(255,255,255,0.2)' className='me-4 dis_block' during={650}>
                                    <button data-bs-toggle="modal" data-bs-target="#leadTrashModal" className="primaryButton left_border_radius" ><Icon icon="basil:trash-solid" fontSize={18} /></button>
                                </Ripples>
                            }
                        </div>
                    </div>

                </div>

                <div className='myrow mt-4'>
                    <div className={tabs === t.profile ? "userTab_active" : "userTab"} onClick={() => setTabs(t.profile)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}><Icon icon="ri:shield-user-line" fontSize={23} style={{ marginRight: '5px' }} /> <span className="tabTitle">Profile</span></div>
                    <div className={tabs === t.history ? "userTab_active" : "userTab"} onClick={() => setTabs(t.history)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}><Icon icon="material-symbols:browse-activity-outline" fontSize={22} style={{ marginRight: '5px' }} /> <span className="tabTitle">History</span></div>
                    <div className={tabs === t.services ? "userTab_active" : "userTab"} onClick={() => setTabs(t.services)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}><Icon icon="file-icons:service-fabric" fontSize={21} style={{ marginRight: '5px' }} /> <span className="tabTitle">Services</span></div>
                    <div className={tabs === t.invoice ? "userTab_active" : "userTab"} onClick={() => setTabs(t.invoice)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}><Icon icon="vaadin:invoice" fontSize={23} style={{ marginRight: '5px' }} /> <span className="tabTitle">Invoices</span></div>
                    <div className={tabs === t.callHistory ? "userTab_active" : "userTab"} onClick={() => setTabs(t.callHistory)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}><Icon icon="material-symbols:recommend-outline" fontSize={23} style={{ marginRight: '5px' }} /> <span className="tabTitle">Call History</span></div>
                </div>

                <div className='mt-2'>
                    {tabs === t.profile &&
                        <Profile leadUser={leadUser} permission={permission} permission2={permission2} leadEnquiry={leadEnquiry} userId={userId} color={color} users={users} isLoading={isLoading} setIsLoading={setIsLoading} />
                    }
                    {tabs === t.history &&
                        <History leadUser={leadUser} permission={permission} permission2={permission2} leadEnquiry={leadEnquiry} userId={userId} color={color} users={users} isLoading={isLoading} setIsLoading={setIsLoading} />
                    }
                    {tabs === t.services &&
                        <Services leadUser={leadUser} leadEnquiry={leadEnquiry} userId={userId} color={color} users={users} isLoading={isLoading} setIsLoading={setIsLoading} />
                    }
                    {tabs === t.invoice &&
                        <Invoices leadUser={leadUser} permission={permission} permission2={permission2} leadEnquiry={leadEnquiry} userId={userId} color={color} users={users} isLoading={isLoading} setIsLoading={setIsLoading} />
                    }
                    {tabs === t.callHistory &&
                        <UserCalls leadUser={leadUser} permission={permission} permission2={permission2} leadEnquiry={leadEnquiry} userId={userId} color={color} users={users} isLoading={isLoading} setIsLoading={setIsLoading} />
                    }

                </div>


                {isLoading &&
                    <Loading />}

                {/* ------------------------------Delete modal----------------------------------- */}

                <div className="modal fade" id={"leadTrashModal"} tabIndex="-1" aria-labelledby="leadTrashModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                            <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                                <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                                <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to Trash this Leads ?</div>
                            </div>
                            <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                                <button type="button" id="dltBtn" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleLeadTrash}>{isLoading ?
                                    <>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                        Please wait...
                                    </>
                                    : "Proceed"}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ------------------------------Lead Restore modal----------------------------------- */}

                <div className="modal fade" id={"leadRestoreModal"} tabIndex="-1" aria-labelledby="leadRestoreModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                            <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                                <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>
                                <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to Restore this Lead ? This lead will be taken back from the Lead Owner.</div>
                            </div>
                            <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                                <button type="button" id="restoreBtn" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleLeadRestore}>{isLoading ?
                                    <>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                        Please wait...
                                    </>
                                    : "Restore"}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ------------------------------Lead Assign modal----------------------------------- */}

                <div class="modal fade" id="leadAssignModal" tabInex="-1" aria-labelledby="leadAssignModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                            <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                                <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Change Owner</div>
                                <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                            </div>
                            <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                                <div className='mb-3'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Name <span className="text-danger">*</span></label>
                                    <select value={assignTo} onChange={(e) => setAssignTo(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                        <option value="">----------Select User to Assign-------------</option>
                                        {users.map((d) => {
                                            return <option value={d.id} key={d.id}>{d.name}</option>
                                        })}
                                    </select>
                                </div>

                            </div>
                            <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                                <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleLeadAssign}>{isLoading ?
                                    <>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                        Please wait...
                                    </>

                                    : "Save"}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ------------------------------Follow Up modal----------------------------------- */}

                <div class="modal fade" id="followUpModal" tabInex="-1" aria-labelledby="followUpModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                            <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                                <div class="modal_title" style={{ color: color.darkText }} id="followUpModalLabel">Follow Up</div>
                                <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                            </div>
                            <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                                {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>Please fill Required fields...!</div>}

                                <div className='mb-3'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Follow Up State <span className="text-danger">*</span></label>
                                    <select value={followupPayload.follow_up_state} onChange={(e) => setFollowupPayload({ ...followupPayload, follow_up_state: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                        <option value="">-----Select Status-----</option>
                                        <option value="INT">Interested</option>
                                        <option value="N-INT">Not Interested</option>
                                        <option value="NPC">NPC</option>
                                        <option value="CB">Call Back</option>
                                        <option value="NNE">Number Not Exist</option>
                                        <option value="WD">Wasted Data</option>
                                    </select>
                                </div>

                                <div className='mb-3'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Follow Up Message <span className="text-danger">*</span></label>
                                    <textarea value={followupPayload.last_follow_up} onChange={(e) => setFollowupPayload({ ...followupPayload, last_follow_up: e.target.value })} placeholder='Enter...' rows={5} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'></textarea>
                                </div>

                                <div className='mb-3'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Reminder Date</label>
                                    <input type='date' value={followupPayload.reminder_date} onChange={(e) => setFollowupPayload({ ...followupPayload, reminder_date: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-3'>
                                    <label className='modal_label' style={{ color: color.darkText }}>Reminder Message (Should not be more than 150 Characters.)</label>
                                    <input type='text' value={followupPayload.reminder_message} onChange={(e) => setFollowupPayload({ ...followupPayload, reminder_message: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                            </div>
                            <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                                <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleAddFollowUp}>{isLoading ?
                                    <>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                        Please wait...
                                    </>

                                    : "Save"}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    } else {
        return <div style={{ color: color.lightText, fontSize: '14px', textAlign: 'center', padding: '20px' }}>No Details Found...!</div>
    }
}

export default LeadsDetails