import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useUserStore = create((set) => ({
    users: [],
    fetchUsers: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'auth/view-users/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ users: data });
    },
    createUsers: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'auth/create-users/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updateUsers: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'auth/update-users/'+id+'/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    changePassword: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'auth/change-password/'+id+'/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    
    deleteUsers: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'auth/delete-users/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    },

    removeProfilePic: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'auth/remove-profile-pic/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    },
    
    changeProfilePic: async (id,payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        let formData = new FormData()
        formData.append('proimg',payload)
        const response = await axios.post(url + 'auth/change-profile-pic/'+id+'/', formData,{
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    },
}));