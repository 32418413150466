import React, { useState, useEffect } from 'react'
import './Sidebar.css'
import logo from '../../../Images/logo-light.png'
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Scrollbars } from 'react-custom-scrollbars';
import { Icon } from '@iconify/react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Ripples from 'react-ripples'
import { useColorStore } from '../../../Store/ColorStore';
import { Link, useLocation } from 'react-router-dom';
import { AdminPath } from './../AdminRoutes/AdminPath';
import { useUserStore } from '../../../Store/UserStore/UserStore';
import { fileUrl, mediaUrl, userType } from '../../../Export';


const Sidebar = (props) => {
  const { user } = props
  let location = useLocation();
  const { color } = useColorStore()
  let token = JSON.parse(localStorage.getItem('user'))
  // ------------------------------------------------------ Notification Work Is Here--------------------------------------------------------
  const [showDropDownNotification, setShowDropDownNotification] = useState(false)
  // document.onclick = function () {
  //   setShowDropDownNotification(false)
  // }
  // =============================handle Logout=========================
  const [isLoading, setIsLoading] = useState(false)
  const handleLogout = () => {
    localStorage.removeItem('user')
    window.location.reload();
  }

  if (user) {
    return (
      <>
        <div className="sidebar">
          <div className="logowrapper">
            <img src={logo} alt="" />
          </div>

          <div style={{ height: '9vh' }}>
            <div className="reportingWrapper">
              <div className="d-flex align_center">
                <div><Avatar sx={{ bgcolor: "#fff", color: "#0C768A", fontSize: 13, fontWeight: 700, width: '30px', height: '30px' }}>{user.name[0]}</Avatar></div>
                <div className='px-3' style={{ textOverflow: "ellipsis", overflow: 'hidden', width: '100%', position: 'relative' }}>
                  <div className="reportingTitle mb-1 text-capitalize">
                    {user.user_type.toLowerCase()}
                  </div>
                  <p className="reportingName mb-0" style={{ textOverflow: "ellipsis", overflow: 'hidden', width: '100%', position: 'relative' }}>{user.email}</p>
                </div>

              </div>
            </div>
          </div>

          <div className="menuWrapper">
            <Scrollbars autoHide={true}
              renderThumbVertical={props => <div {...props} style={{ backgroundColor: "rgba(255,255,255,0.2)", borderRadius: '5px' }} />}
            >
              {/* ======================================================================Dashboard Block========================================================= */}
              <div>
                <div className="menu-title">Menu</div>

                <Ripples color={color.rippleColor} during={800}>
                  <Link to={AdminPath.DASHBOARD} className={location.pathname === AdminPath.DASHBOARD ? "menu-item menu-active" : "menu-item"}>
                    <div className=""><Icon icon="uim:layer-group" fontSize={17} />&nbsp; Dashboard</div>
                  </Link>
                </Ripples>

                <div id="accordionExample">

                  <Ripples color={color.rippleColor} during={800} data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <div className={location.pathname === AdminPath.EMAIL || location.pathname === AdminPath.INBOX || location.pathname === AdminPath.SENTMAIL || location.pathname === AdminPath.TRASH || location.pathname === AdminPath.STARRED ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className="d-flex align_center"><Icon icon="fluent:mail-48-filled" fontSize={18} />&nbsp; Email</div>
                      <div style={{ marginLeft: 'auto' }}><KeyboardArrowDownRoundedIcon fontSize='small' /></div>
                    </div>
                  </Ripples>

                  <div id="collapseOne" className="accordion-collapse collapse" style={{ paddingLeft: '15px' }} data-bs-parent="#accordionExample">
                    <Ripples color={color.rippleColor} during={800}>
                      <Link to={AdminPath.INBOX} className={location.pathname === AdminPath.INBOX ? "myrow align_center menu-item2 menu-active" : "myrow align_center menu-item2"}>
                        <div className="d-flex align_center"><Icon icon="mingcute:inbox-fill" fontSize={18} />&nbsp; Inbox</div>
                      </Link>
                    </Ripples>

                    <Ripples color={color.rippleColor} during={800}>
                      <Link to={AdminPath.SENTMAIL} className={location.pathname === AdminPath.SENTMAIL ? "myrow align_center menu-item2 menu-active" : "myrow align_center menu-item2"}>
                        <div className="d-flex align_center"><Icon icon="ic:round-mark-email-read" fontSize={18} />&nbsp; Sent Mail</div>
                      </Link>
                    </Ripples>

                    <Ripples color={color.rippleColor} during={800}>
                      <Link to={AdminPath.TRASH} className={location.pathname === AdminPath.TRASH ? "myrow align_center menu-item2 menu-active" : "myrow align_center menu-item2"}>
                        <div className="d-flex align_center"><Icon icon="basil:trash-solid" fontSize={18} />&nbsp; Trash</div>
                      </Link>
                    </Ripples>

                  </div>
                </div>
              </div>
              {/* ======================================================================Research Calculator Block========================================================= */}

              {user.user_type === userType.researcher ?
                <div>
                  <div className="menu-title">RESEARCH</div>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.RESEARCHCALL} className={location.pathname === AdminPath.RESEARCHCALL ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="ic:twotone-tips-and-updates" fontSize={18} />&nbsp; Recommendation</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.RESEARCHUPDATES} className={location.pathname === AdminPath.RESEARCHUPDATES ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="solar:smartphone-update-bold" fontSize={18} />&nbsp; Updates</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.SAR} className={location.pathname === AdminPath.SAR ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="file-icons:dbase" fontSize={18} />&nbsp; Support & Resistance</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.COMPLIANCEMSG} className={location.pathname === AdminPath.COMPLIANCEMSG ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="solar:folder-security-bold-duotone" fontSize={18} />&nbsp; Compliance Message</div>
                    </Link>
                  </Ripples>
                </div>
                : null}

              {/* ======================================================================Compliance Block========================================================= */}
              {user.user_type === userType.compliance &&
                <div>
                  <div className="menu-title">Leads</div>
                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.LEADSSERVICES} className={location.pathname === AdminPath.LEADSSERVICES ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className=""><Icon icon="file-icons:service-fabric" fontSize={17} />&nbsp; Service List</div>
                    </Link>
                  </Ripples>
                </div>
              }

              {/* ======================================================================Product Block========================================================= */}
              <div>
                <div className="menu-title">Service</div>

                <Ripples color={color.rippleColor} during={800}>
                  <Link to={AdminPath.CATEGORY} className={location.pathname === AdminPath.CATEGORY ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                    <div className=""><Icon icon="iconamoon:category-duotone" fontSize={18} />&nbsp; Category</div>
                  </Link>
                </Ripples>
                {user.user_type === userType.admin ?
                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.SUBCATEGORY} className={location.pathname === AdminPath.SUBCATEGORY ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className=""><Icon icon="ic:twotone-category" fontSize={17} />&nbsp; Sub-Category</div>
                    </Link>
                  </Ripples>
                  : null}

                <Ripples color={color.rippleColor} during={800}>
                  <Link to={AdminPath.PRODUCT} className={location.pathname === AdminPath.PRODUCT ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                    <div className=""><Icon icon="uim:apps" fontSize={17} />&nbsp; Product</div>
                  </Link>
                </Ripples>

              </div>
              {/* ======================================================================Space Block========================================================= */}

              {user.user_type === userType.admin || user.user_type === userType.sub_admin ?
                <div>
                  <div className="menu-title">Content</div>

                  <div id="accordionExample">

                    <Ripples color={color.rippleColor} during={800} data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                      <div className={location.pathname === AdminPath.PDFFILES || location.pathname === AdminPath.PRODUCTBROCHURE || location.pathname === AdminPath.QRSCANNER ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                        <div className=""><Icon icon="mdi:files" fontSize={17} />&nbsp; Files</div>
                        <div style={{ marginLeft: 'auto' }}><KeyboardArrowDownRoundedIcon fontSize='small' /></div>
                      </div>
                    </Ripples>

                    <div id="collapseOne2" className="accordion-collapse collapse" style={{ paddingLeft: '15px' }} data-bs-parent="#accordionExample">
                      <Ripples color={color.rippleColor} during={800}>
                        <Link to={AdminPath.PDFFILES} className={location.pathname === AdminPath.PDFFILES ? "myrow align_center menu-item2 menu-active" : "myrow align_center menu-item2"}>
                          <div className=""><Icon icon="material-symbols:picture-as-pdf-rounded" fontSize={18} />&nbsp; Pdf FIles</div>
                        </Link>
                      </Ripples>

                      <Ripples color={color.rippleColor} during={800}>
                        <Link to={AdminPath.PRODUCTBROCHURE} className={location.pathname === AdminPath.PRODUCTBROCHURE ? "myrow align_center menu-item2 menu-active" : "myrow align_center menu-item2"}>
                          <div className=""><Icon icon="material-symbols:content-copy" fontSize={18} />&nbsp; Product Brochure</div>
                        </Link>
                      </Ripples>

                      <Ripples color={color.rippleColor} during={800}>
                        <Link to={AdminPath.QRSCANNER} className={location.pathname === AdminPath.QRSCANNER ? "myrow align_center menu-item2 menu-active" : "myrow align_center menu-item2"}>
                          <div className=""><Icon icon="solar:qr-code-bold-duotone" fontSize={18} />&nbsp; Qr Scanner</div>
                        </Link>
                      </Ripples>

                    </div>
                  </div>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.DYNAMICEMAIL} className={location.pathname === AdminPath.DYNAMICEMAIL ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="material-symbols:stacked-email-rounded" fontSize={18} />&nbsp; Dynamic Email</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.IPADDRESS} className={location.pathname === AdminPath.IPADDRESS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="mdi:ip" fontSize={18} />&nbsp; WhiteListed IP</div>
                    </Link>
                  </Ripples>
                </div>
                : null}

              {/* ======================================================================Accounts Block========================================================= */}

              {user.user_type === userType.admin || user.user_type === userType.sub_admin ?
                <div>
                  <div className="menu-title">Account</div>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.USERS} className={location.pathname === AdminPath.USERS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className=""><Icon icon="solar:user-bold-duotone" fontSize={18} />&nbsp; Manage User</div>
                    </Link>
                  </Ripples>

                </div>
                : null}

              {/* ======================================================================Leads Block========================================================= */}

              {user.user_type === userType.admin || user.user_type === userType.sub_admin || user.user_type === userType.sales_executive || user.user_type === userType.sales_manager ?
                <div>
                  <div className="menu-title">Leads</div>
                  {user.user_type === userType.admin || user.user_type === userType.sub_admin ?
                    <Ripples color={color.rippleColor} during={800}>
                      <Link to={AdminPath.ALLLEADS} className={location.pathname === AdminPath.ALLLEADS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                        <div className=""><Icon icon="uim:layer-group" fontSize={17} />&nbsp; All Leads</div>
                      </Link>
                    </Ripples>
                    : null}

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.ASSIGNEDLEADS} className={location.pathname === AdminPath.ASSIGNEDLEADS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className=""><Icon icon="fluent:layer-diagonal-person-20-filled" fontSize={17} />&nbsp; Assigned Leads</div>
                    </Link>
                  </Ripples>
                  
                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.NEWLEADS} className={location.pathname === AdminPath.NEWLEADS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className=""><Icon icon="ic:outline-accessibility-new" fontSize={17} />&nbsp; New Leads</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.FOLLOWUPLEADS + "/call-back"} className={location.pathname === AdminPath.FOLLOWUPLEADS + "/call-back" ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className=""><Icon icon="solar:incoming-call-rounded-bold-duotone" fontSize={17} />&nbsp; Call Back Leads</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.FOLLOWUPLEADS + "/interested"} className={location.pathname === AdminPath.FOLLOWUPLEADS + "/interested" ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className=""><Icon icon="mdi:alpha-i-box" fontSize={17} />&nbsp; Interested Leads</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.FOLLOWUPLEADS + "/not-interested"} className={location.pathname === AdminPath.FOLLOWUPLEADS + "/not-interested" ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className=""><Icon icon="solar:forbidden-bold-duotone" fontSize={17} />&nbsp; Not Interested Leads</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.FOLLOWUPLEADS + "/npc"} className={location.pathname === AdminPath.FOLLOWUPLEADS + "/npc" ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className=""><Icon icon="material-symbols:call-missed" fontSize={17} />&nbsp; NPC Leads</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.LEADSSERVICES} className={location.pathname === AdminPath.LEADSSERVICES ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className=""><Icon icon="file-icons:service-fabric" fontSize={17} />&nbsp; Service List</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.RECOMMENDATION} className={location.pathname === AdminPath.RECOMMENDATION ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className=""><Icon icon="solar:smartphone-update-bold" fontSize={17} />&nbsp; Recommendations</div>
                    </Link>
                  </Ripples>

                </div>
                : null}

              {/* ======================================================================Compliance Block========================================================= */}
              {user.user_type === userType.admin || user.user_type === userType.sub_admin || user.user_type === userType.compliance ?
                <div>
                  <div className="menu-title">Compliance</div>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.ALLINVOICES} className={location.pathname === AdminPath.ALLINVOICES ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item"}>
                      <div className=""><Icon icon="basil:invoice-solid" fontSize={18} />&nbsp; Invoices</div>
                    </Link>
                  </Ripples>

                </div>
                : null}
              {/* ===========================================Research Sidebar============================================================ */}

              {user.user_type === userType.researcher ?
                <div>
                  <div className="menu-title">SETTINGS</div>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.LOTSIZE} className={location.pathname === AdminPath.LOTSIZE ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="material-symbols-light:ballot-outline" fontSize={18} />&nbsp; Lotsize</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.RESEARCHPRODUCT} className={location.pathname === AdminPath.RESEARCHPRODUCT ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="la:product-hunt" fontSize={18} />&nbsp; Research Product</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.RESEARCHPUPDATE} className={location.pathname === AdminPath.RESEARCHPUPDATE ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="tabler:square-rounded-letter-u" fontSize={18} />&nbsp; Research Updates</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.PASTPERFORMANCE} className={location.pathname === AdminPath.PASTPERFORMANCE ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="pepicons-print:flag" fontSize={18} />&nbsp; Past Performance</div>
                    </Link>
                  </Ripples>
                </div>
                : null}
              {/* ===========================================Website Sidebar============================================================ */}

              {user.user_type === userType.sub_admin || user.user_type === userType.admin ?
                <div>
                  <div className="menu-title">WEBSITE</div>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.WEBENQUIRY} className={location.pathname === AdminPath.WEBENQUIRY ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="solar:info-square-bold-duotone" fontSize={18} />&nbsp; Web Enquiry</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.NEWSLETTER} className={location.pathname === AdminPath.NEWSLETTER ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="mdi:email-newsletter" fontSize={17} />&nbsp; NewsLetter</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.REQDEMO} className={location.pathname === AdminPath.REQDEMO ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="healthicons:d" fontSize={18} />&nbsp; Requested Demo</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.PAYMENTUSER} className={location.pathname === AdminPath.PAYMENTUSER ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="material-symbols:payments-outline-rounded" fontSize={18} />&nbsp; Payment User</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.COMPLAINT} className={location.pathname === AdminPath.COMPLAINT ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="iconoir:c-square" fontSize={18} />&nbsp; Complaint</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.COMPLAINTDATA} className={location.pathname === AdminPath.COMPLAINTDATA ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="icon-park-solid:handle-c" fontSize={18} />&nbsp; Complaint Data</div>
                    </Link>
                  </Ripples>

                  <Ripples color={color.rippleColor} during={800}>
                    <Link to={AdminPath.BLOGS} className={location.pathname === AdminPath.BLOGS ? "myrow align_center menu-item menu-active" : "myrow align_center menu-item2"}>
                      <div className=""><Icon icon="uim:blogger-alt" fontSize={18} />&nbsp; Blogs</div>
                    </Link>
                  </Ripples>

                </div>
                : null}

            </Scrollbars>
          </div>

          <div className='sidebarBottom'>
            {showDropDownNotification &&
              <div style={{ position: 'absolute' }}>
                <div className="more_block2" style={{ backgroundColor: color.secondaryBg, marginTop: token.user_type === userType.admin ? "-205px" : "-130px" }} onClick={(e) => e.stopPropagation()}>

                  <div>
                    <Link to={AdminPath.PROFILE + '/' + token.id} onClick={() => setShowDropDownNotification(false)} className="d-flex notification_wrapper align_center" >
                      <Icon icon="iconamoon:profile-circle-fill" color={color.blue} fontSize={18} />
                      <div style={{ marginLeft: '10px' }}>
                        <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Profile</div>
                      </div>
                    </Link>
                  </div>

                  {token.user_type === userType.admin && <>
                    <div style={{ marginTop: '-10px' }}>
                      <Link to={AdminPath.USERPERMISSION} onClick={() => setShowDropDownNotification(false)} className="d-flex notification_wrapper align_center" >
                        <Icon icon="ic:outline-privacy-tip" color={color.green} fontSize={18} />
                        <div style={{ marginLeft: '10px' }}>
                          <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>User Permissions</div>
                        </div>
                      </Link>
                    </div>

                    <div style={{ marginTop: '-10px' }}>
                      <Link to={AdminPath.SETTINGS} onClick={() => setShowDropDownNotification(false)} className="d-flex notification_wrapper align_center" >
                        <Icon icon="lucide:settings" color={color.blue2} fontSize={18} />
                        <div style={{ marginLeft: '10px' }}>
                          <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Settings</div>
                        </div>
                      </Link>
                    </div>
                  </>
                  }

                  <div style={{ marginTop: '-10px' }}>
                    <div className="d-flex notification_wrapper align_center" onClick={handleLogout}>
                      <Icon icon="heroicons-outline:logout" color={color.yellow} fontSize={18} />
                      <div style={{ marginLeft: '10px' }}>
                        <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Log Out</div>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: '-10px' }}>
                    <div className="d-flex notification_wrapper align_center" onClick={() => setShowDropDownNotification(false)}>
                      <Icon icon="ph:x-duotone" color={color.red} fontSize={15} style={{ marginTop: '' }} />
                      <div style={{ marginLeft: '10px' }}>
                        <div className='' style={{ color: color.darkText, fontSize: '13px', fontWeight: 400 }}>Close</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            }

            <div className="myrow align_center">
              <div><Avatar src={user ? mediaUrl + user.proimg : ''} sx={{ bgcolor: "#fff", color: "#0C768A", fontSize: 14, fontWeight: 700, width: '40px', height: '40px', border: "3px solid #fff" }} /></div>
              <div className='px-2'>
                <div className="reportingTitle">
                  {user ? user.name : ''}
                </div>
              </div>

              <div style={{ marginLeft: 'auto', color: "#fff" }} type='button' onClick={() => setShowDropDownNotification(!showDropDownNotification)}><MoreVertIcon /></div>
            </div>
          </div>

        </div>

      </>
    )
  } else {
    return null
  }
}

export default Sidebar