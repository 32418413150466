import React, { useState, useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import ResearchUpdateList from './ResearchUpdateList.js';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../Loading';
import { useProductStore } from '../../../Store/ProductStore/ProductStore';
import { useResearchUpdateStore } from './../../../Store/ResearchStore/UpdateStore/UpdateStore';
import ReactPaginate from 'react-paginate';

const ResearchUpdate = () => {
    const { addToast } = useToasts();
    const { researchUpdate, fetchResearchUpdate, createResearchUpdate } = useResearchUpdateStore()
    const { color } = useColorStore()
    let token = JSON.parse(localStorage.getItem('user'))

    const [isLoading, setIsLoading] = useState(false)

    const [payload, setPayload] = useState({
        product_category: 'CASH',
        title: '',
        update_format: '',
        execution: null,
    })

    const [error, setError] = useState(false)

    const handleSubmit = () => {
        if (payload.product_category.length === 0 || payload.title.length === 0 || payload.update_format.length === 0) {
            setError(true)
        } else {
            setIsLoading(true)
            createResearchUpdate(payload)
                .then(res => {
                    fetchResearchUpdate()
                    setIsLoading(false)
                    setPayload({
                        ...payload, product_category: '',
                        title: '',
                        update_format: '',
                        execution: null,
                    })
                    setIsLoading(false)
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }
    }

    useEffect(() => {
        fetchResearchUpdate()
    }, [])

    const [searchInput, setSearchInput] = useState("")
    const [searchParam] = useState(["title", "product_category", "update_format"]);

    function search(items) {
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(searchInput.toLowerCase()) > -1
                );
            });
        });
    }

    function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            addToast('Copied...', { appearance: 'success' });
            return navigator.clipboard.writeText(text);
        } else {
            addToast('Copied...', { appearance: 'success' });
            return document.execCommand('copy', true, text);
        }
    }

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (searchInput.length > 0) {
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(search(researchUpdate).slice(itemOffset, endOffset));
            setPageCount(Math.ceil(search(researchUpdate).length / itemsPerPage));
            setitemLength(search(researchUpdate).length)
            setItemEndSet(endOffset)
        }
        else {
            // Fetch items from another resources.
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(researchUpdate.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(researchUpdate.length / itemsPerPage));
            setItemEndSet(endOffset)
            setitemLength(researchUpdate.length)
        }

    }, [itemOffset, itemsPerPage, researchUpdate, searchInput]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % researchUpdate.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search" style={{ position: 'relative' }}>
                        <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} />
                        <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                    </div>
                    <div>
                        <Ripples color='rgba(255,255,255,0.2)' during={1000}>
                            <button className="primaryButton" data-bs-toggle="modal" data-bs-target="#categoryModal" >Add Update</button>
                        </Ripples>

                    </div>
                </div>

                {/* ------------------------------Table Work-------------------------------------- */}

                <div class="table-responsive">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <input type="text" defaultValue="10" onChange={(e) => e.target.value === 0 || e.target.value === '0' ? setItemsPerPage() : setItemsPerPage(parseInt(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '35px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />
                        &nbsp; Out of - {itemLength}
                    </div>
                    <table class="table table-nowrap align-middle mb-0">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Product Category</th>
                                <th style={{ color: color.darkText }} scope="col">Title</th>
                                <th style={{ color: color.darkText }} scope="col">Update Format</th>
                                <th style={{ color: color.darkText }} scope="col">Execution</th>
                                <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {researchUpdate.length > 0 ?
                                currentItems.map((c, count) => {
                                    return <ResearchUpdateList data={c} key={c.id} count={count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                })
                                :
                                null}
                        </tbody>
                    </table>
                    {currentItems.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px',marginTop:'5px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
            {isLoading &&
                <Loading />}
            {/* ----------------------Add Modal---------------------------- */}

            <div class="modal fade" id="categoryModal" tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Add Research Update</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Category <span className="text-danger">*</span></label>
                                <select value={payload.product_category} onChange={(e) => setPayload({ ...payload, product_category: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="CASH">Cash</option>
                                    <option value="FUTURE">Future</option>
                                    <option value="OPTION">Option</option>
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Title</label>
                                <input placeholder='Enter...' value={payload.title} onChange={(e) => setPayload({ ...payload, title: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div>
                                <label className='modal_label' style={{ color: color.darkText }}>Variables</label>
                                <div className="myrow" style={{ fontSize: '12px', color: color.darkText }}>
                                    <div className='m-2 lightButton p-1' style={{ fontSize: '12px' }} type='button' onClick={() => copyTextToClipboard('{STOCK_NAME}')}>STOCK_NAME</div>
                                    <div className='m-2 lightButton p-1' style={{ fontSize: '12px' }} type='button' onClick={() => copyTextToClipboard('{STRIKE_PRICE}')}>STRIKE_PRICE</div>
                                    <div className='m-2 lightButton p-1' style={{ fontSize: '12px' }} type='button' onClick={() => copyTextToClipboard('{BUY_SELL}')}>BUY_SELL</div>
                                    <div className='m-2 lightButton p-1' style={{ fontSize: '12px' }} type='button' onClick={() => copyTextToClipboard('{CMP}')}>CMP</div>
                                    <div className='m-2 lightButton p-1' style={{ fontSize: '12px' }} type='button' onClick={() => copyTextToClipboard('{CMP2}')}>CMP2</div>
                                    <div className='m-2 lightButton p-1' style={{ fontSize: '12px' }} type='button' onClick={() => copyTextToClipboard('{EXPIRY_DATE}')}>EXPIRY_DATE</div>
                                    <div className='m-2 lightButton p-1' style={{ fontSize: '12px' }} type='button' onClick={() => copyTextToClipboard('{CALL_PUT}')}>CALL_PUT</div>
                                </div>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Update Format</label>
                                <textarea placeholder='Enter...' rows={6} value={payload.update_format} onChange={(e) => setPayload({ ...payload, update_format: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'></textarea>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Execution</label>
                                <select value={payload.execution} onChange={(e) => setPayload({ ...payload, execution: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value={null}>None</option>
                                    <option value="POSITIVE">Positive</option>
                                    <option value="NEGATIVE">Negative</option>
                                </select>
                            </div>

                        </div>

                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Save"}</button>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default ResearchUpdate