import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useMarketUpdateStore = create((set) => ({
    marketUpdate: [],
    fetchMarketUpdate: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'ws/view-market-updates/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ marketUpdate: data });
    },
    createMarketUpdate: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'ws/create-market-updates/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    }
}));