import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useResearchStore = create((set) => ({
    sar: [],
    sendSar: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'research/send-sar/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
}));