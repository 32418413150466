import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useResearchDashboardStore = create((set) => ({
    researchTotals:{total_calls_m:0,total_calls_t:0,positive_calls:0,negative_calls:0},
    calls_profit_overview:[],calls_loss_overview:[],clients_overview:{},performance:[],callsCounting:[],productWiseClients:[],segmentCallsOverviewP:[],segmentCallsOverviewL:[],

    fetchResearchTotals: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'dashboard/dashboard-research-totals/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ researchTotals:data});
    },
    fetchCallsOverview: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'dashboard/dashboard-calls-overview/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ calls_profit_overview:data.p,calls_loss_overview:data.l});
    },
    fetchCallSeg: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'dashboard/dashboard-calls-seg/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ clients_overview:data});
    },
    fetchResearcherListDashboard: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'dashboard/dashboard-researcher-list/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ performance:data});
    },
    fetchCallsCounting: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'dashboard/dashboard-product-wise-call/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ callsCounting:data});
    },
    fetchClientsCounting: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'dashboard/dashboard-product-wise-clients/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ productWiseClients:data});
    },
    fetchSegmentCallsOverview: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'dashboard/segment-calls-overview/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ segmentCallsOverviewP:data.p,segmentCallsOverviewL:data.l});
    },
    
}));