import React, { useState, useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import { useToasts } from 'react-toast-notifications';
import { useCategoryStore } from '../../../Store/ProductStore/CategoryStore';
import Loading from '../../../Loading';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useSettingStore } from '../../../Store/SettingStore/SettingStore';
import SettingList from './SettingList';

const animatedComponents = makeAnimated();
const Settings = () => {
    const { addToast } = useToasts();
    const navigation = useNavigate()
    const { color } = useColorStore()
    const { settingOptions, fetchSettingOption, createSettingOption, updateSettingOption } = useSettingStore()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetchSettingOption()
    }, [])
    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="d-flex justify_space_between">
                    <div className="mb-2" style={{ marginTop: '-10px' }}>
                        <Icon icon="cil:arrow-left" className='modal_close font-30' color={color.darkText} type='button' onClick={() => navigation(-1)} />
                    </div>
                </div>

                <div className="userPermissionWrapper mt-4">

                    <div className="d-flex justify_space_between mb-2">
                        <div className="per_block1" style={{ color: color.lightText, fontSize: '12px' }}>
                            SETTING
                        </div>

                        <div className="per_block2" style={{ color: color.lightText, fontSize: '12px', marginRight: '25px' }}>
                            STATUS
                        </div>
                    </div>

                    <hr style={{ borderColor: color.tableBorder, opacity: 1 }} />

                    {settingOptions.length ? <>
                        {settingOptions.map((d, count) => {
                            return <SettingList key={d.id} d={d} fetchSettingOption={fetchSettingOption} updateSettingOption={updateSettingOption} />
                        })
                        }
                    </>
                        :
                        null}

                </div>

            </div>
        </>
    )
}

export default Settings