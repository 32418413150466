import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useLeadActionStore = create((set) => ({
    restoreLeads: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/restore-enquiry/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    trashLeads: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/trash-enquiry/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    assignLeads: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/change-owner-enquiry/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    bulkAssignLeads: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/bulk-assign-enquiry/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    
}));