import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useLeadDetailStore = create((set) => ({
    leadUser: null,
    leadEnquiry: null,
    leadHistory: [],leadHistoryOther:null,
    userServices: [],
    userInvoices: [],
    fetchLeadDetails: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/fetch-enquiry-details/' + id + '/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ leadUser: data.data, leadEnquiry: data.enquiry, })
    },
    fetchUserInvoices: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/view-user-invoices/' + id + '/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ userInvoices: data })
    },
    updateLeadUserDetails: async (id, payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'enquiry/update-enquiry-details-primary/' + id + '/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    updateLeadEnquiryDetails: async (id, payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'enquiry/update-enquiry-details-secondary/' + id + '/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    serviceUpdation: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/service-updation/', null, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    addFollowUp: async (id, payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/add-follow-up/' + id + '/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchLeadHistory: async (id,u) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/view-history/' + id + '/?'+u, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    setLeadHistory: async (data) => {
        set({ leadHistory: data.data,leadHistoryOther:{count:Number(data.count),currentPage:Number(data.currentPage)} })
    },
    freeTrialStart: async (id,arr,payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/free-trial-service/' + id + '/', {arr,payload}, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    paidServiceStart: async (id,arr,payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/paid-service/' + id + '/', {arr,payload}, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    FreebiesServiceStart: async (id,payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/freebies-service/' + id + '/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchUserServices: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/fetch-user-services/' + id + '/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({userServices:data})
    },
    deleteUserServices: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'enquiry/delete-user-services/' + id + '/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    updateUserServices: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'enquiry/update-user-services/' + id + '/', payload,{
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    addServiceFollowUp: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'enquiry/add-service-followup/' + id + '/', payload,{
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
}));