import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useNotificationStore = create((set) => ({
    unread_notification: [], unread_count: 0, notification: [],
    fetchNotification: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'ws/view-notification/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ notification: data });
    },
    fetchUnreadNotification: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'ws/view-unread-notification/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ unread_notification: data });
    },
    fetchUnreadNotificationCount: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'ws/count-unread-notification/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ unread_count: data });
    }
}));