import React, { useRef, useEffect, useState } from 'react'
import './Dashboard.css'
import { useColorStore } from './../../../Store/ColorStore';
import { useAdminDashboardStore } from '../../../Store/DashboardStore.js/AdminDashboardStore';
import * as echarts from 'echarts';
import PerformanceList from './PerformanceList/PerformanceList';
import { Icon } from '@iconify/react';
import { numFormatter } from '../../../Export';


const Dashboard = () => {
  const { fetchTotals, totals, fetchBusinessOverview, fetchClientsOverview, business_overview, clients_overview, fetchUserPerformance, performance, dashLeadsData, fetchDashboardLeadsData, fetchDashboardSalesPerformance, salesPerformance } = useAdminDashboardStore()
  const { color } = useColorStore()
  const chartRef = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const chartRef4 = useRef(null);
  const chartRef5 = useRef(null);

  useEffect(() => {
    fetchTotals()
    fetchBusinessOverview()
    fetchClientsOverview()
    fetchUserPerformance()
    fetchDashboardLeadsData()
    fetchDashboardSalesPerformance()
  }, [])


  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    const chart2 = echarts.init(chartRef2.current);
    const chart3 = echarts.init(chartRef3.current);
    const chart4 = echarts.init(chartRef4.current);
    const chart5 = echarts.init(chartRef5.current);

    // Sample data for the bar chart
    const data = business_overview;

    const option = {
      tooltip: {
        trigger: 'axis', backgroundColor: color.primaryBg, borderColor: 'none', color: color.darkText,
        axisPointer: {
          type: 'shadow'
        }
      },
      title: {
        text: '',
      },
      xAxis: {
        type: '',
        data: data.map(item => item.name),
      },
      yAxis: {
        splitLine: {
          show: true,
          lineStyle: {
            color: [color.tableBorder, color.tableBorder]
          }
        }
      },
      series: [{
        type: 'bar',
        name: 'Rupees',
        data: data.map(item => item.value),
        showBackground: true,
        itemStyle: {
          color: color.blue,
        },
        backgroundStyle: {
          color: color.tableBorder,
        },
        barWidth: 16,
      }],
    };

    const optionSalesPerformance = {
      tooltip: {
        trigger: 'axis', backgroundColor: color.primaryBg, borderColor: 'none', color: color.darkText,
        axisPointer: {
          type: 'shadow'
        }
      },
      title: {
        text: '',
      },
      xAxis: {
        type: '',
        data: salesPerformance.map(item => item.name),
      },
      yAxis: {
        splitLine: {
          show: true,
          lineStyle: {
            color: [color.tableBorder, color.tableBorder]
          }
        }
      },
      series: [{
        type: 'bar',
        name: 'Rupees',
        data: salesPerformance,
        showBackground: false,
        itemStyle: {
          color: color.blue,
        },
        backgroundStyle: {
          color: color.tableBorder,
        },
        barWidth: 16, barGap: '10%'
      }],
    };

    // Pie Chart configuration
    const pieOptions = {
      tooltip: {
        show: false
      },
      legend: {
        bottom: '10%',
        left: 'center', color: color.darkText, selectedMode: true,
        textStyle: {
          color: color.lightText
        }
      },
      series: [
        {
          name: 'Clients',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: color.secondaryBg,
            borderWidth: 4
          },
          label: {
            show: false,
            position: 'center',
            formatter(param) {
              // correct the percentage
              return param.name + '\n' + param.value + ' (' + param.percent + '%)';
            }
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 13,
              fontWeight: '600', color: color.lightText, lineHeight: 18
            }
          },
          data: clients_overview ? [
            { value: clients_overview.paid, name: 'Paid Client', itemStyle: { color: color.blue } },
            { value: clients_overview.renew, name: 'Renew Client', itemStyle: { color: color.green } },
            { value: clients_overview.free, name: 'Free Trials', itemStyle: { color: color.red } },
          ]
            : [
              { value: 300, name: 'Paid Client', itemStyle: { color: color.blue } },
              { value: 200, name: 'Renew Client', itemStyle: { color: color.green } },
              { value: 600, name: 'Free Trials', itemStyle: { color: color.red } },
            ]
        }
      ]
    };
    // Follow Up Pie Chart configuration

    const pieFollowUpOptions = {
      tooltip: {
        trigger: 'item', backgroundColor: color.primaryBg, borderColor: 'none', color: color.darkText,
        formatter: '{a} <br/>{b} - {c} ({d}%)'
      },
      legend: {
        bottom: '0%',
        left: 'center', color: color.darkText, selectedMode: true,
        textStyle: {
          color: color.lightText
        }
      },
      series: [
        {
          name: 'Follow Up Leads',
          type: 'pie',
          center: ['50%', '40%'],
          // roseType: 'radius',
          itemStyle: {
            borderRadius: 2
          },
          label: {
            show: true,
            color: "#fff", fontWeight: 'bold',
            position: 'inner', rotate: 'number',
            formatter(param) {
              // correct the percentage
              return param.percent + '%';
            }
          },
          data: [
            { value: dashLeadsData.int, name: 'Interested', itemStyle: { color: color.blue } },
            { value: dashLeadsData.nint, name: 'Not Interested', itemStyle: { color: color.yellow } },
            { value: dashLeadsData.npc, name: 'NPC', itemStyle: { color: color.green } },
            { value: dashLeadsData.nne, name: 'Number Not Exist', itemStyle: { color: color.blue2 } },
            { value: dashLeadsData.wd, name: 'Wasted Data', itemStyle: { color: color.red } },
          ]
        }
      ]
    };
    // Lead Pie Chart configuration
    const leadsPieOptions = {
      tooltip: {
        show: false
      },
      legend: {
        top: '5%',
        left: 'center', color: color.darkText,
        textStyle: {
          color: color.lightText
        }
      },
      series: [
        {
          name: 'Leads',
          type: 'pie',
          radius: ['50%', '70%'],
          center: ['50%', '65%'],
          // adjust the start angle
          startAngle: 180,
          label: {
            show: true,
            color: "#fff",
            position: 'inner', rotate: 'tangential',
            formatter(param) {
              // correct the percentage
              return param.percent * 2 + '%';
            }
          },
          data: [
            { value: dashLeadsData.ua, name: 'Un-Assigned', itemStyle: { color: color.blue } },
            { value: dashLeadsData.a, name: 'Assigned', itemStyle: { color: color.blue2 } },
            { value: dashLeadsData.t, name: 'Trash', itemStyle: { color: color.red } },
            {
              // make an record to fill the bottom 50%
              value: dashLeadsData.ua + dashLeadsData.a + dashLeadsData.t,
              itemStyle: {
                // stop the chart from rendering this piece
                color: 'none',
                decal: {
                  symbol: 'none'
                }
              },
              label: {
                show: false
              }
            }
          ]
        }
      ]
    };

    chart.setOption(option);
    chart2.setOption(pieOptions);
    chart3.setOption(leadsPieOptions);
    chart4.setOption(pieFollowUpOptions);
    chart5.setOption(optionSalesPerformance);

    // Clean up the chart when the component is unmounted
    return () => {
      chart.dispose();
      chart2.dispose();
      chart3.dispose();
      chart4.dispose();
      chart5.dispose();
    };
  }, [color, business_overview, clients_overview, dashLeadsData]);

  return (
    <>
      <div className="myrow justify_space_between">

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="solar:money-bag-bold" fontSize={32} color={color.blue} /></div>
            </div>
            <div>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Earnings</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Current Month</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>₹ {totals.total_earnings ? numFormatter(totals.total_earnings) : 0}</div>
            </div>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="mdi:user-tie" fontSize={32} color={color.blue} /></div>
            </div>
            <div>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Clients</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Today</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals.total_clients)}</div>
            </div>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="mdi:user-online" fontSize={32} color={color.blue} /></div>
            </div>
            <div>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Active Clients</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>For Today</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals.active_clients)}</div>
            </div>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="ri:service-fill" fontSize={32} color={color.blue} /></div>
            </div>
            <div>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Services</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Today</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals.total_services)}</div>
            </div>
          </div>
        </div>

      </div>
      {/* **********************Second Block Dashboard**************************** */}
      <div className="myrow justify_space_between mb-4">
        <div className="business_block1 p-3" style={{ backgroundColor: color.secondaryBg }}>
          <h6 style={{ color: color.darkText, marginTop: '5px' }}>Business Overview</h6>

          <div className="myrow">
            <div className="ww100">
              <div ref={chartRef} style={{ width: '100%', height: '400px' }} />
            </div>
            <div className="ww100">
              <div ref={chartRef2} style={{ width: '100%', height: '400px' }} />
            </div>
          </div>
        </div>

        <div className="business_block2 p-3" style={{ backgroundColor: color.secondaryBg }}>
          <h6 style={{ color: color.darkText, marginTop: '5px', marginBottom: '10px' }}>Performance</h6>
          <hr style={{ borderColor: color.borderColor }} />
          {performance.length > 0 ? <>
            {performance.map((f, count) => {
              return <PerformanceList data={f} key={count} />
            })}
          </> :
            <div style={{ color: color.red, fontSize: '14px' }}>No Payment for Now.</div>
          }

        </div>
      </div>
      {/* **********************Third Block Dashboard**************************** */}
      <div className="myrow justify_space_between" style={{ paddingTop: '10px' }}>

        <div className="dash_block_3 p-3" style={{ backgroundColor: color.secondaryBg }}>
          <h6 style={{ color: color.darkText, marginTop: '5px' }}>Leads</h6>
          <div ref={chartRef3} style={{ width: '100%', height: '400px' }} />

          <div className='text-center' style={{ marginTop: '-180px' }}>
            <div style={{ color: color.lightText, opacity: 0.8 }} className='menu-title p-0'>Total Leads</div>
            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(Number(dashLeadsData.ua)+Number(dashLeadsData.a)+Number(dashLeadsData.t))}</div>
          </div>

          <div className='myrow mt-5' style={{ justifyContent: 'space-evenly' }}>

            <div className='w-25'>
              <div class="progress nHeight mb-2" role="progressbar" aria-label="Example 1px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar" style={{ width: (1048 * 100 / 2363) + "%", backgroundColor: color.blue }}></div>
              </div>
              <div style={{ color: color.lightText, opacity: 0.8, fontWeight: 'normal' }} className='menu-title p-0 pt-1'>Un-Assigned</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '16px' }}>{dashLeadsData.ua}</div>
            </div>

            <div className='w-25'>
              <div class="progress nHeight mb-2" role="progressbar" aria-label="Example 1px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar" style={{ width: (735 * 100 / 2363) + "%", backgroundColor: color.blue2 }}></div>
              </div>
              <div style={{ color: color.lightText, opacity: 0.8, fontWeight: 'normal' }} className='menu-title p-0 pt-1'>Assigned</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '16px' }}>{dashLeadsData.a}</div>
            </div>

            <div className='w-25'>
              <div class="progress nHeight mb-2" role="progressbar" aria-label="Example 1px high" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar" style={{ width: (430 * 100 / 2363) + "%", backgroundColor: color.red }}></div>
              </div>
              <div style={{ color: color.lightText, opacity: 0.8, fontWeight: 'normal' }} className='menu-title p-0 pt-1'>Trash</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '16px' }}>{dashLeadsData.t}</div>
            </div>
          </div>

        </div>

        <div className="dash_block_3 p-3" style={{ backgroundColor: color.secondaryBg }}>
          <h6 style={{ color: color.darkText, marginTop: '5px', marginBottom: '30px' }}>Leads Segregation</h6>

          {/* <div class="progress-stacked">
            {dashLeadsData.fb > 0 &&
              <div class="progress" role="progressbar" aria-label="Segment one" aria-valuenow={0} aria-valuemin="0" aria-valuemax={0} style={{ width:Math.ceil((dashLeadsData.fb * 100) / leadSegregation) + "%" }}>
                <div class="progress-bar" style={{ width: Math.ceil((dashLeadsData.fb * 100) / leadSegregation) + "%", backgroundColor: color.blue2 }}></div>
              </div>
            }
            {dashLeadsData.g > 0 &&
              <div class="progress" role="progressbar" aria-label="Segment two" aria-valuenow={0} aria-valuemin="0" aria-valuemax={0} style={{ width:Math.ceil((dashLeadsData.g * 100) / leadSegregation) + "%"}}>
                <div class="progress-bar" style={{ width: Math.ceil((dashLeadsData.g * 100) / leadSegregation) + "%", backgroundColor: color.red }}></div>
              </div>
            }
            {dashLeadsData.o > 0 &&
              <div class="progress" role="progressbar" aria-label="Segment three" aria-valuenow={0} aria-valuemin="0" aria-valuemax={0} style={{ width:Math.ceil((dashLeadsData.o * 100) / leadSegregation) + "%"}}>
                <div class="progress-bar" style={{ width: Math.ceil((dashLeadsData.o * 100) / leadSegregation) + "%", backgroundColor: color.green }}></div>
              </div>
            }
            {dashLeadsData.b > 0 &&
              <div class="progress" role="progressbar" aria-label="Segment three" aria-valuenow={0} aria-valuemin="0" aria-valuemax={0} style={{ width:Math.ceil((dashLeadsData.fb * 100) / leadSegregation) + "%"}}>
                <div class="progress-bar" style={{ width: Math.ceil((dashLeadsData.b * 100) / leadSegregation) + "%", backgroundColor: color.yellow }}></div>
              </div>
            }
            {dashLeadsData.ot > 0 &&
              <div class="progress" role="progressbar" aria-label="Segment three" aria-valuenow={0} aria-valuemin="0" aria-valuemax={0} style={{ width:Math.ceil((dashLeadsData.fb * 100) / leadSegregation) + "%"}}>
                <div class="progress-bar" style={{ width: Math.ceil((dashLeadsData.ot * 100) / leadSegregation) + "%", backgroundColor: color.blue }}></div>
              </div>
            }
          </div> */}

          <div class="table-responsive mt-3">
            <table class="table table-nowrap align-middle">
              <thead>
                <tr style={{ color: color.darkText }}>
                  <th style={{ color: color.darkText }} scope="col">Name</th>
                  <th style={{ color: color.darkText }} scope="col">Leads</th>
                  <th style={{ color: color.darkText }} scope="col">#</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ color: color.lightText }}>
                  <td style={{ color: color.lightText, borderBottom: 'none' }}>FaceBook</td>
                  <td style={{ color: color.lightText, borderBottom: 'none' }}>{dashLeadsData.fb}</td>
                  <td style={{ color: color.lightText, borderBottom: 'none' }}><Icon icon="icon-park-solid:block-six" color={color.blue2} /></td>
                </tr>
                <tr style={{ color: color.lightText }}>
                  <td style={{ color: color.lightText, borderBottom: 'none' }}>Google</td>
                  <td style={{ color: color.lightText, borderBottom: 'none' }}>{dashLeadsData.g}</td>
                  <td style={{ color: color.lightText, borderBottom: 'none' }}><Icon icon="icon-park-solid:block-six" color={color.red} /></td>
                </tr>
                <tr style={{ color: color.lightText }}>
                  <td style={{ color: color.lightText, borderBottom: 'none' }}>Organic</td>
                  <td style={{ color: color.lightText, borderBottom: 'none' }}>{dashLeadsData.o}</td>
                  <td style={{ color: color.lightText, borderBottom: 'none' }}><Icon icon="icon-park-solid:block-six" color={color.green} /></td>
                </tr>
                <tr style={{ color: color.lightText }}>
                  <td style={{ color: color.lightText, borderBottom: 'none' }}>Bulk</td>
                  <td style={{ color: color.lightText, borderBottom: 'none' }}>{dashLeadsData.b}</td>
                  <td style={{ color: color.lightText, borderBottom: 'none' }}><Icon icon="icon-park-solid:block-six" color={color.yellow} /></td>
                </tr>
                <tr style={{ color: color.lightText }}>
                  <td style={{ color: color.lightText, borderBottom: 'none' }}>Other</td>
                  <td style={{ color: color.lightText, borderBottom: 'none' }}>{dashLeadsData.ot}</td>
                  <td style={{ color: color.lightText, borderBottom: 'none' }}><Icon icon="icon-park-solid:block-six" color={color.blue} /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="dash_block_3 p-3" style={{ backgroundColor: color.secondaryBg }}>
          <h6 style={{ color: color.darkText, marginTop: '5px' }}>Follow Up Leads</h6>
          <div ref={chartRef4} style={{ width: '100%', height: '400px' }}></div>
        </div>

      </div>
      {/* **********************Fourth Block Dashboard**************************** */}
      <div className="category_body mb-4 p-3" style={{ backgroundColor: color.secondaryBg }}>
        <h6 style={{ color: color.darkText, marginTop: '5px' }}>Sales Team Performance</h6>
        <div ref={chartRef5} style={{ width: '100%', height: '400px' }} />
      </div>

    </>
  )
}

export default Dashboard