import React, { useState } from 'react'
import { useColorStore } from '../../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

const NewsLetterList = (props) => {
    const { addToast } = useToasts();
    const { color } = useColorStore()
    const { data, count } = props

    return (
        <>
            <tr style={{ color: color.darkText }}>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
                    {count}
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '25px' }}>
                    <Icon icon="solar:user-outline" fontSize={14} color={color.blue} /> {data.name} <br /> <span style={{ fontSize: '11px', color: color.lightText, display: 'flex', alignItems: 'center' }}><Icon icon="uiw:time-o" color={color.lightText} fontSize={11} /> <span style={{ marginLeft: '6px' }}>{moment(data.created_at).format('lll')}</span></span>
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '27px' }}>
                    <Icon icon="fluent:call-28-regular" fontSize={14} color={color.blue} /> {data.mobile}<br />{data.email ? <> <Icon icon="fluent:mail-24-regular" fontSize={15} color={color.blue} /> {data.email} </> : ''}
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder,}}>
                    {data.service}
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight:'25px'}}>
                    ₹ {data.amount}<br />
                    {data.payment_status === 'Success' ? <span class="badge bg-success"> Success </span> : <span class="badge bg-danger">Pending</span>}
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder,}}>
                    {data.order_id}
                </td>

            </tr>




        </>
    )
}

export default NewsLetterList