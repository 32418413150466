import { create } from 'zustand';
import axios from 'axios';
import { socket, url } from './../../Export';

export const useSocketStore = create((set) => ({
    state: null, newMarketUpdate: null, newNotification: null,
    connectSocket: () => {
        let id = JSON.parse(localStorage.getItem('user')).token
        var ws = new WebSocket(`${socket}?token=${id}`);
        var connectInterval;
        // Websocket onopen event listener
        ws.onopen = () => {
            console.log("connected websocket main component");
            set({ state: ws });
        };

        // websocket onclose event listener
        ws.onclose = e => {
            console.log(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,

                )} second.`,
                e.reason
            );
            set({ state: ws });
        };

        ws.onmessage = e => {
            if (e) {
                let data = JSON.parse(e.data)
                if (data.type === 'MarketUpdate') {
                    set({ newMarketUpdate: data.data });
                } else if (data.type === 'Notification') {
                    set({ newNotification: data.data });
                }
            }

        }

        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );
            ws.close();
        };
    },
    clearNewNotification: () => {
        set({ newNotification: null })
    },
}));