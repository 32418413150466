import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useEmailStore = create((set) => ({
    currentEmail: {},
    currentEmailList: {},
    email: [],
    emailList: [],
    trashemail: [],
    trashemailList: [],
    sentemail: [],
    sentemailList: [],
    starredemail: [],
    starredemailList: [],
    fetchEmail: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'product/fetch-email/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ email: data.data, emailList: data.list });
    },
    fetchCurrentEmail: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'product/fetch-current-email/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchEmailFile: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'product/fetch-current-email-file/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchTrashEmail: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'product/fetch-trash-email/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ trashemail: data.data, trashemailList: data.list });
    },
    fetchSentEmail: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'product/fetch-sent-email/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ sentemail: data.data, sentemailList: data.list });
    },
    fetchStarredEmail: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'product/fetch-starred-email/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ starredemail: data.data, starredemailList: data.list });
    },
    sendEmail: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'product/send-email/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
                "Content-Type": 'multipart/form-data'
            }
        });
        return response
    },
    trashEmail: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'product/trash-email/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    starredEmail: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'product/starred-email/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    labelEmail: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'product/label-email/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    starredSingleEmail: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'product/single-starred-email/' + id + '/',null, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updateEmailList: async (payload, data) => {
        var foundIndex = await payload.findIndex(x => x.id === data.id);
        let items = [...payload];
        items[foundIndex] = data
        set({ emailList: items });
    },
    createEmailReply: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'product/create-email-reply/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    fetchEmailReply: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'product/view-email-reply/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },

}));