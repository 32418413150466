import React, { useState, useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import { useToasts } from 'react-toast-notifications';
import { useCategoryStore } from '../../../Store/ProductStore/CategoryStore';
import Loading from '../../../Loading';
import './UserPermissions.css'
import { useNavigate } from 'react-router-dom';
import { permissionOptions, userSegrigation } from '../../../Export';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useUserPermissionStore } from '../../../Store/UserPermissionStore/UserPermissionStore';
import UserPermissionsList from './UserPermissionsList';


const animatedComponents = makeAnimated();
const UserPermissions = () => {
    const { addToast } = useToasts();
    const navigation = useNavigate()
    const { color } = useColorStore()
    const { userPermissions, createUserPermission, fetchUserPermissions, updateUserPermission } = useUserPermissionStore()
    const [isLoading, setIsLoading] = useState(false)
    const t = {
        admin: 'ADMIN',
        subAdmin: 'SUB ADMIN',
        researcher: 'RESEARCHER',
        compliance: 'COMPLIANCE',
        salesManager: 'SALES MANAGER',
        salesExecutive: 'SALES EXECUTIVE',
        hr: 'HR',
    }
    const [tabs, setTabs] = useState(t.subAdmin)

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: color.inputBackground,
            // match with the menu
            borderRadius: 4,
            // Overwrittes the different states of border
            borderColor: color.borderColor,
            fontSize: 14,
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: color.borderColor
            }
        }),
        menu: base => ({
            ...base,
            background: color.inputBackground,
            color: color.darkText,
            fontSize: 14,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);

            return {
                ...styles,
                backgroundColor: isFocused ? color.blue : null,
                color: isFocused ? "#fff" : null,
                // color: "#333333"
            };
        }
    };

    useEffect(() => {
        if (userPermissions.length === 0) {
            fetchUserPermissions()
        }
    }, [])


    const [error, setError] = useState(false)

    const [payload, setPayload] = useState([])

    const handleSubmit = () => {
        if (payload.length > 0) {
            createUserPermission(payload)
                .then(res => {
                    setPayload([])
                    fetchUserPermissions()
                    setIsLoading(false)
                    addToast('User Permission Created...', { appearance: 'success' });
                })
                .catch(err => {
                    addToast('Something went wrong...', { appearance: 'error' });
                })
        }
        else {
            addToast('Please select permission...', { appearance: 'error' });
        }
    }

    const [data, setData] = useState()

    useEffect(() => {
        let d
        if (userPermissions.length > 0 && tabs.length > 0) {
            d = userSegrigation.find((f) => {
                return f.key === tabs
            })
        }
        setData(d)
    }, [userPermissions, tabs])

    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>

                <div className="d-flex justify_space_between">
                    <div className="mb-2" style={{ marginTop: '-10px' }}>
                        <Icon icon="cil:arrow-left" className='modal_close font-30' color={color.darkText} type='button' onClick={() => navigation(-1)} />
                    </div>

                    <div className="mb-2" style={{ marginTop: '-10px' }}>
                        <button className="primaryButton" data-bs-toggle="modal" data-bs-target="#followUpModal" >Add New</button>
                    </div>
                </div>

                <div className='myrow'>
                    <div className={tabs === t.subAdmin ? "userTab_active" : "userTab"} onClick={() => setTabs(t.subAdmin)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}><span className="tabTitle">Sub Admin</span></div>
                    <div className={tabs === t.salesManager ? "userTab_active" : "userTab"} onClick={() => setTabs(t.salesManager)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}><span className="tabTitle">Sales Manager</span></div>
                    <div className={tabs === t.salesExecutive ? "userTab_active" : "userTab"} onClick={() => setTabs(t.salesExecutive)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}><span className="tabTitle">Sales Executive</span></div>
                    <div className={tabs === t.researcher ? "userTab_active" : "userTab"} onClick={() => setTabs(t.researcher)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}><span className="tabTitle">Researcher</span></div>
                    <div className={tabs === t.compliance ? "userTab_active" : "userTab"} onClick={() => setTabs(t.compliance)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}><span className="tabTitle">Compliance</span></div>
                    <div className={tabs === t.hr ? "userTab_active" : "userTab"} onClick={() => setTabs(t.hr)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}><span className="tabTitle">HR</span></div>
                </div>

                <div className="userPermissionWrapper">

                    <div className="d-flex justify_space_between mb-2">
                        <div className="per_block1" style={{ color: color.lightText, fontSize: '12px' }}>
                            PERMISSIONS
                        </div>

                        <div className="per_block2" style={{ color: color.lightText, fontSize: '12px', marginRight: '25px' }}>
                            STATUS
                        </div>
                    </div>

                    <hr style={{ borderColor: color.borderColor }} />

                    {userPermissions.length > 0 && data ? <>
                        {userPermissions.map((d, count) => {
                            return <UserPermissionsList key={d.id} d={d} data={data} tabs={tabs} fetchUserPermissions={fetchUserPermissions} permissionOptions={permissionOptions} updateUserPermission={updateUserPermission} />
                        })
                        }
                    </>
                        :
                        null}

                </div>

            </div>

            {/* ------------------------------Follow Up modal----------------------------------- */}

            <div className="modal fade" id="followUpModal" tabIndex="-1" aria-labelledby="followUpModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div className="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }} id="followUpModalLabel">Follow Up</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>Please fill Required fields...!</div>}

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Follow Up State <span className="text-danger">*</span></label>
                                <Select
                                    styles={customStyles}
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    isMulti
                                    options={permissionOptions}
                                    onChange={(e) => setPayload(e)}
                                    value={payload}
                                />
                            </div>

                        </div>
                        <div className="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Save"}</button>
                        </div>
                    </div>
                </div>
            </div>



            {isLoading && <Loading />}
        </>
    )
}

export default UserPermissions