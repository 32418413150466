import React from 'react'
import { useColorStore } from '../../Store/ColorStore'
import parse from 'html-react-parser';
import moment from 'moment';
import { Icon } from '@iconify/react';

const EmailReply = (props) => {
    const { color } = useColorStore()
    const { users, data } = props
    const findSender = () => {
        let u = users.find((f) => {
            return f.id === data.owner
        })
        if (u) {
            return u.name
        } else {
            return " "
        }
    }
    return (
        <>
            <div className="replyCard" style={{ border: "0.5px solid " + color.borderColor }}>
                <div className='pt-1 myrow justify_space_between'>
                    <div className="reportingTitle" style={{ color: color.darkText }}>
                    <Icon icon="ic:baseline-reply" color={color.darkText} fontSize={18} /> {findSender()}
                    </div>
                    <div>
                        <span className=" mb-0" style={{ color: color.lightText, fontSize: '12px' }}>{moment(data.created_at).format("MMM Do, YY")} {moment(data.created_at).format("LT")}</span>
                    </div>
                </div>

                <div className=' py-1 '>
                    {/* <h6 className='mt-5 mb-2' style={{ color: color.darkText }}>{data.subject}</h6> */}
                    <div style={{ color: color.lightText, fontSize: '13px' }}>
                        {parse(String(data.mail))}
                    </div>
                </div>

            </div>

        </>
    )
}

export default EmailReply