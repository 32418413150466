import React, { useState, useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import ResearchProductList from './ResearchProductList';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../Loading';
import { useResearchProductStore } from '../../../Store/ResearchStore/ResearchProductStore/ResearchProductStore';
import { useProductStore } from './../../../Store/ProductStore/ProductStore';
import ReactPaginate from 'react-paginate';

const ResearchProduct = () => {
    const { addToast } = useToasts();
    const { researchProduct, fetchResearchProduct, createResearchProduct } = useResearchProductStore()
    const { fetchProduct, product } = useProductStore()
    const { color } = useColorStore()
    let token = JSON.parse(localStorage.getItem('user'))

    const [isLoading, setIsLoading] = useState(false)

    const [payload, setPayload] = useState({
        category: 'CASH',
        sub_category: 'STOCK',
        product: '',
        calculation: 'PERCENTAGE',
        tgt_prcnt: '',
        sl_prcnt: '',
        profit: '',
        loss: '',
        buy_template: '',
        sell_template: '',
        call_template: '',
        put_template: '',
    })

    const [error, setError] = useState(false)

    const handleSubmit = () => {
        setIsLoading(true)
        if (payload.category === 'CASH' && payload.sub_category.length > 0) {
            if (payload.product.length === 0 || payload.tgt_prcnt.length === 0 || payload.sl_prcnt.length === 0) {
                setIsLoading(false)
                addToast('All Fields Required...', { appearance: 'error' });
            } else {
                createResearchProduct(payload)
                    .then(res => {
                        fetchResearchProduct()
                        setPayload({
                            ...payload, category: 'CASH',
                            sub_category: 'STOCK',
                            product: '',
                            calculation: 'PERCENTAGE',
                            tgt_prcnt: '',
                            sl_prcnt: '',
                            profit: '',
                            loss: '',
                            buy_template: '',
                            sell_template: '',
                            call_template: '',
                            put_template: '',
                        })
                        setIsLoading(false)
                        addToast('Success...', { appearance: 'success' });
                    }).catch(err => {
                        console.log(err);
                        setIsLoading(false)
                        addToast('Error...', { appearance: 'error' });
                    })
            }
        }
        else if (payload.category === 'FUTURE' || payload.category === 'OPTION' && payload.sub_category.length > 0) {
            if (payload.product.length === 0 || payload.profit.length === 0 || payload.loss.length === 0) {
                setIsLoading(false)
                addToast('All Fields Required...', { appearance: 'error' });
            } else {
                createResearchProduct(payload)
                    .then(res => {
                        fetchResearchProduct()
                        setPayload({
                            ...payload, category: 'CASH',
                            sub_category: 'STOCK',
                            product: '',
                            calculation: 'PERCENTAGE',
                            tgt_prcnt: '',
                            sl_prcnt: '',
                            profit: '',
                            loss: '',
                            buy_template: '',
                            sell_template: '',
                            call_template: '',
                            put_template: '',
                        })
                        setIsLoading(false)
                        addToast('Success...', { appearance: 'success' });
                    }).catch(err => {
                        console.log(err);
                        setIsLoading(false)
                        addToast('Error...', { appearance: 'error' });
                    })
            }
        }
        else {
            setIsLoading(false)
            addToast('All Fields Required...', { appearance: 'error' });
        }
    }

    useEffect(() => {
        if (product.length === 0) {
            fetchProduct()
        }
    }, [])

    useEffect(() => {
        fetchResearchProduct()
    }, [])

    const [searchInput, setSearchInput] = useState("")
    const [searchParam] = useState(["category", "sub_category", "calculation", "buy_template", "sell_template", 'call_template', 'put_template']);

    function search(items) {
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(searchInput.toLowerCase()) > -1
                );
            });
        });
    }

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (searchInput.length > 0) {
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(search(researchProduct).slice(itemOffset, endOffset));
            setPageCount(Math.ceil(search(researchProduct).length / itemsPerPage));
            setitemLength(search(researchProduct).length)
            setItemEndSet(endOffset)
        }
        else {
            // Fetch items from another resources.
            const endOffset = itemOffset + itemsPerPage;
            // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
            setCurrentItems(researchProduct.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(researchProduct.length / itemsPerPage));
            setItemEndSet(endOffset)
            setitemLength(researchProduct.length)
        }

    }, [itemOffset, itemsPerPage, researchProduct, searchInput]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % researchProduct.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search" style={{ position: 'relative' }}>
                        <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} />
                        <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                    </div>
                    <div>
                        <Ripples color='rgba(255,255,255,0.2)' during={1000}>
                            <button className="primaryButton" data-bs-toggle="modal" data-bs-target="#categoryModal" >Add Product</button>
                        </Ripples>

                    </div>
                </div>

                {/* ------------------------------Table Work-------------------------------------- */}

                <div class="table-responsive">
                    <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px' }}>
                        Show Entries - <input type="text" defaultValue="10" onChange={(e) => e.target.value === 0 || e.target.value === '0' ? setItemsPerPage() : setItemsPerPage(parseInt(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '35px', padding: '5px', textAlign: 'center', borderRadius: '2px' }} />
                        &nbsp; Out of - {itemLength}
                    </div>
                    <table class="table table-nowrap align-middle mb-0">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">Name</th>
                                <th style={{ color: color.darkText }} scope="col">Category</th>
                                <th style={{ color: color.darkText }} scope="col">Calculation Base</th>
                                <th style={{ color: color.darkText }} scope="col">TGT%/Profit</th>
                                <th style={{ color: color.darkText }} scope="col">SL%/Loss</th>
                                <th style={{ color: color.darkText }} scope="col">Buy/Call Template</th>
                                <th style={{ color: color.darkText }} scope="col">Sell/Put Template</th>
                                <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {researchProduct.length > 0 ?
                                currentItems.map((c, count) => {
                                    return <ResearchProductList data={c} product={product} key={c.id} count={count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                })
                                :
                                null}
                        </tbody>
                    </table>

                    {currentItems.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px' ,marginTop:'5px'}}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }

                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
            {isLoading &&
                <Loading />}
            {/* ----------------------Add Modal---------------------------- */}

            <div class="modal fade" id="categoryModal" tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Add Research Product</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Category <span className="text-danger">*</span></label>
                                <select value={payload.category} onChange={(e) => setPayload({ ...payload, category: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="CASH">Cash</option>
                                    <option value="FUTURE">Future</option>
                                    <option value="OPTION">Option</option>
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Sub Category <span className="text-danger">*</span></label>
                                <select value={payload.sub_category} onChange={(e) => setPayload({ ...payload, sub_category: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="STOCK">Stock</option>
                                    <option value="INDEX">Index</option>
                                    <option value="COMMODITY">Commodity</option>
                                    <option value="CURRENCY">Currency</option>
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Select Product <span className="text-danger">*</span></label>
                                <select value={payload.product} onChange={(e) => setPayload({ ...payload, product: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="">--------Select Product---------</option>
                                    {product.map((s) => {
                                        return <option value={s.id} key={s.id}>{s.name}</option>
                                    })}
                                </select>
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Calculation Base <span className="text-danger">*</span></label>
                                <select value={payload.calculation} onChange={(e) => setPayload({ ...payload, calculation: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="PERCENTAGE">Percentage</option>
                                    <option value="PROFIT">Profit</option>
                                </select>
                            </div>

                            <div className="myrow justify_space_between">

                                {payload.calculation === 'PERCENTAGE' ? <>
                                    <div className='mb-2' style={{ width: '48%' }}>
                                        <label className='modal_label' style={{ color: color.darkText }}>TGT% <span className="text-danger">*</span></label>
                                        <input placeholder='Enter TGT Percentage...' value={payload.tgt_prcnt} onChange={(e) => setPayload({ ...payload, tgt_prcnt: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>

                                    <div className='mb-2' style={{ width: '48%' }}>
                                        <label className='modal_label' style={{ color: color.darkText }}>SL% <span className="text-danger">*</span></label>
                                        <input placeholder='Enter SL Percentage...' value={payload.sl_prcnt} onChange={(e) => setPayload({ ...payload, sl_prcnt: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>
                                </> : <>
                                    <div className='mb-2' style={{ width: '48%' }}>
                                        <label className='modal_label' style={{ color: color.darkText }}>Profit <span className="text-danger">*</span></label>
                                        <input placeholder='Enter Profit...' value={payload.profit} onChange={(e) => setPayload({ ...payload, profit: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>

                                    <div className='mb-2' style={{ width: '48%' }}>
                                        <label className='modal_label' style={{ color: color.darkText }}>Loss <span className="text-danger">*</span></label>
                                        <input placeholder='Enter Loss...' value={payload.loss} onChange={(e) => setPayload({ ...payload, loss: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                    </div>

                                </>
                                }

                                <div className='mb-2' style={{ width: '48%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Buy Template Name</label>
                                    <input placeholder='Enter...' value={payload.buy_template} onChange={(e) => setPayload({ ...payload, buy_template: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-2' style={{ width: '48%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Sell Template Name</label>
                                    <input placeholder='Enter...' value={payload.sell_template} onChange={(e) => setPayload({ ...payload, sell_template: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-2' style={{ width: '48%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Call Template Name</label>
                                    <input placeholder='Enter...' value={payload.call_template} onChange={(e) => setPayload({ ...payload, call_template: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-2' style={{ width: '48%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Put Template Name</label>
                                    <input placeholder='Enter...' value={payload.put_template} onChange={(e) => setPayload({ ...payload, put_template: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                            </div>



                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Save"}</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ResearchProduct