import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Category from '../../Admin/Category/Category';
import Dashboard from '../Dashboard/Dashboard';
import SubCategory from '../../Admin/SubCategory/SubCategory';
import Product from '../../Admin/Product/Product';
import Email from '../../Email/Email';
import { AdminPath } from './../../Admin/AdminRoutes/AdminPath';
import ScrollToTop from '../../Admin/AdminRoutes/ScrollToTop';
import AssignedLeads from './../../Admin/Leads/AssignedLeads/AssignedLeads';
import LeadsDetails from './../../Admin/Leads/LeadsDetails/LeadsDetails';
import Services from '../../Admin/Leads/Services/Services';
import UserDetails from '../../Admin/Accounts/Users/UserDetails/UserDetails';
import Recommendation from '../../Research/Recommendation/Recommendation';
import FollowUp from '../../Admin/Leads/FollowUp/FollowUp';
import NewLeads from './../../Admin/Leads/AssignedLeads/NewLeads';

export const SalesRoutes = () => {
    return (
        <>
        <ScrollToTop />
            <Routes>
                <Route path={AdminPath.DASHBOARD} element={<Dashboard />} />
                <Route path={AdminPath.CATEGORY} element={<Category />} />
                <Route path={AdminPath.SUBCATEGORY} element={<SubCategory />} />
                <Route path={AdminPath.PRODUCT} element={<Product />} />
                <Route path={AdminPath.EMAIL} element={<Email />} />
                <Route path={AdminPath.ASSIGNEDLEADS} element={<AssignedLeads />} />
                <Route path={AdminPath.FOLLOWUPLEADS+ "/:fid"} element={<FollowUp />} />
                <Route path={AdminPath.ALLLEADS+ "/:userId"} element={<LeadsDetails />} />
                <Route path={AdminPath.LEADSSERVICES} element={<Services />} />
                <Route path={AdminPath.PROFILE + "/:userId"} element={<UserDetails />} />
                <Route path={AdminPath.RECOMMENDATION} element={<Recommendation />} />
                <Route path={AdminPath.NEWLEADS} element={<NewLeads />} />
                {/* <Route path={AdminPath.PRODUCTBROCHURE} element={<ProductBrochure />} />
                <Route path={AdminPath.QRSCANNER} element={<QrScanner />} />
                <Route path={AdminPath.DYNAMICEMAIL} element={<DynamicEmail />} />
                <Route path={AdminPath.IPADDRESS} element={<IpAddress />} />
                <Route path={AdminPath.USERS} element={<Users />} />
                <Route path={AdminPath.ALLLEADS} element={<AllLeads />} />
                <Route path={AdminPath.ALLINVOICES} element={<Compliance />} />
                <Route path={AdminPath.USERPERMISSION} element={<UserPermissions />} />
                <Route path={AdminPath.SETTINGS} element={<Settings />} /> */}
            </Routes>
        </>
    )
}
