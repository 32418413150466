import React, { useState } from 'react'
import { useColorStore } from '../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { useToasts } from 'react-toast-notifications';
import moment from 'moment/moment';
import { useComplianceMsgStore } from '../../../Store/ResearchStore/ComplianceMsgStore/ComplianceMsgStore';

const ComplianceMsgList = (props) => {
    const { addToast } = useToasts();
    const { complianceMsg, fetchComplianceMsg, updateComplianceMsg, deleteComplianceMsg, sendComplianceMsg } = useComplianceMsgStore()
    const { color } = useColorStore()
    const { data, count, isLoading, setIsLoading } = props
    const [error, setError] = useState(false)
    const [payload, setPayload] = useState({
        name: data.name,
        msg: data.msg,
    })

    const handleSubmit = () => {
        if (payload.name.length === 0 || payload.msg.length === 0) {
            setError(true)
        } else {
            setIsLoading(true)
            updateComplianceMsg(payload, data.id)
                .then(res => {
                    fetchComplianceMsg()
                    setIsLoading(false)
                    setPayload({
                        ...payload, name: payload.name,
                        msg: payload.msg,
                    })
                    setIsLoading(false)
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }
    }

    const handleDelete = () => {
        let d = document.getElementById("dltBtn" + data.id)
        d.click()
        setIsLoading(true)
        deleteComplianceMsg(data.id)
            .then(res => {
                fetchComplianceMsg()
                setIsLoading(false)
                addToast('Success...', { appearance: 'success' });
            }).catch(err => {
                console.log(err);
                setIsLoading(false)
                addToast('Error...', { appearance: 'error' });
            })
    }

    const handleSendMessage = () => {
        setIsLoading(true)
        sendComplianceMsg(data)
            .then(res => {
                setIsLoading(false)
                addToast('Success...', { appearance: 'success' });
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false)
                addToast('Error...', { appearance: 'error' });
            })
    }

    return (
        <>
            <tr style={{ color: color.darkText }}>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
                    {count}
                </td>

                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.name}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, whiteSpace: 'pre-wrap' }}>{data.msg}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}> <button onClick={handleSendMessage} className="infoButton">SEND</button> </td>

                <td style={{ borderBottom: '1px solid ' + color.tableBorder }}>
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                            <span type="button" className="px-2" data-bs-toggle="modal" data-bs-target={"#categoryModal" + data.id} style={{ color: color.blue }}><Icon icon="akar-icons:edit" fontSize={18} /></span>
                        </li>
                        <li className="list-inline-item">
                            <span type={'button'} className="px-2" data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal" + data.id} style={{ color: color.red }}><Icon icon="ep:delete" fontSize={17} /></span>
                        </li>
                    </ul>
                </td>
            </tr>

            {/* ----------------------Update Modal---------------------------- */}

            <div class="modal fade" id={"categoryModal" + data.id} tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Update Compliance Message</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Template Name</label>
                                <input placeholder='Enter...' value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className='mb-2'>
                                <label className='modal_label' style={{ color: color.darkText }}>Message Format</label>
                                <textarea placeholder='Enter...' rows={6} value={payload.msg} onChange={(e) => setPayload({ ...payload, msg: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'></textarea>
                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Update"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------------------------Delete Modal------------------------ */}

            <div class="modal fade" id={"categoryDeleteModal" + data.id} tabindex="-1" aria-labelledby="categoryDeleteModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to delete ( {data.name} ) Compliance Message ? You would'nt be able to retrieve this.</div>
                        </div>


                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id={"dltBtn" + data.id} style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleDelete}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Delete"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ComplianceMsgList