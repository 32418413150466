import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'
import { useUserStore } from '../../../../../Store/UserStore/UserStore'
import { useToasts } from 'react-toast-notifications'
import { useUserPersonalDetailsStore } from './../../../../../Store/UserStore/UserPersonalDetailsStore';

const PersonalDetails = (props) => {
  const { addToast } = useToasts()
  const { color, isLoading, setIsLoading, currentUser, permission } = props
  const { users, fetchUsers } = useUserStore()
  const { usersPersonalDetails, fetchUsersPersonalDetails, createUsersPersonalDetails } = useUserPersonalDetailsStore()
  const [error, setError] = useState(false)

  const [payload, setPayload] = useState({
    user: currentUser.id,
    gender: usersPersonalDetails.gender,
    dob: usersPersonalDetails.dob,
    blood_group: usersPersonalDetails.blood_group,
    parents_name: usersPersonalDetails.parents_name,
    husband_name: usersPersonalDetails.husband_name,
    parents_number: usersPersonalDetails.parents_number,
    relationship: usersPersonalDetails.relationship,
    languages: usersPersonalDetails.languages,
  })

  const handleSubmit = () => {
    if (payload.gender.length === 0 || payload.dob.length === 0 || payload.blood_group.length === 0 || payload.parents_name.length === 0 || payload.parents_number.length === 0 || payload.relationship.length === 0 || payload.languages.length === 0) {
      setError(true)
    } else {
      setError(false)
      createUsersPersonalDetails(payload)
        .then(res => {
          fetchUsersPersonalDetails(currentUser.id)
          setIsLoading(false)
          setPayload({
            user: currentUser.id,
            gender: payload.gender,
            dob: payload.dob,
            blood_group: payload.blood_group,
            parents_name: payload.parents_name,
            husband_name: payload.husband_name,
            parents_number: payload.parents_number,
            relationship: payload.relationship,
            languages: payload.languages,
          })
          addToast('Success...', { appearance: 'success' });
        }).catch(err => {
          console.log(err);
          setIsLoading(false)
          addToast('Error...', { appearance: 'error' });
        })
    }

  }

  useEffect(() => {
    if (usersPersonalDetails) {
      setPayload({
        ...payload,
        user: currentUser.id,
        gender: usersPersonalDetails.gender,
        dob: usersPersonalDetails.dob,
        blood_group: usersPersonalDetails.blood_group,
        parents_name: usersPersonalDetails.parents_name,
        husband_name: usersPersonalDetails.husband_name,
        parents_number: usersPersonalDetails.parents_number,
        relationship: usersPersonalDetails.relationship,
        languages: usersPersonalDetails.languages,
      })
    } else {
      setPayload({
        ...payload,
        user: currentUser.id,
        gender: "",
        dob: "",
        blood_group: "",
        parents_name: "",
        husband_name: "",
        parents_number: "",
        relationship: "",
        languages: "",
      })
    }
  }, [usersPersonalDetails])


  useEffect(() => {
    fetchUsersPersonalDetails(currentUser.id)
  }, [])


  return (
    <>
      <div className="mt-4">
        <div className="modal-body" style={{ borderBottomColor: color.tableBorder }}>

          {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Gender <span className="text-danger">*</span></label>
            <select value={payload.gender} disabled={!permission} onChange={(e) => setPayload({ ...payload, gender: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
              <option value="">----------Choose gender---------</option>
              <option value="MALE">MALE</option>
              <option value="FEMALE">FEMALE</option>
            </select>
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>DOB <span className="text-danger">*</span></label>
            <input type='date' placeholder='Enter Email' value={payload.dob} disabled={!permission} onChange={(e) => setPayload({ ...payload, dob: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Blood Group <span className="text-danger">*</span></label>
            <select value={payload.blood_group} disabled={!permission} onChange={(e) => setPayload({ ...payload, blood_group: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
              <option value="" disabled selected>----------Choose blood group---------</option>
              <option value="A+">A+</option><option value="A-">A-</option>
              <option value="B+">B+</option><option value="B-">B-</option>
              <option value="O+">O+</option><option value="O-">O-</option>
              <option value="AB+">AB+</option><option value="AB-">AB-</option>
            </select>
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Parents/Guardian Name <span className="text-danger">*</span></label>
            <input placeholder='Enter Name' value={payload.parents_name} disabled={!permission} onChange={(e) => setPayload({ ...payload, parents_name: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Husband Name</label>
            <input placeholder='Enter Husband Name' value={payload.husband_name} disabled={!permission} onChange={(e) => setPayload({ ...payload, husband_name: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Parents Number <span className="text-danger">*</span></label>
            <input placeholder='Enter number' value={payload.parents_number} disabled={!permission} onChange={(e) => setPayload({ ...payload, parents_number: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Relationship Status <span className="text-danger">*</span></label>
            <input placeholder='Enter...' value={payload.relationship} disabled={!permission} onChange={(e) => setPayload({ ...payload, relationship: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

          <div className='mb-2'>
            <label className='modal_label' style={{ color: color.darkText }}>Languages Known <span className="text-danger">*</span></label>
            <input placeholder='Enter comma seperated...' value={payload.languages} disabled={!permission} onChange={(e) => setPayload({ ...payload, languages: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
          </div>

        </div>

        {permission &&
          <div className=" pt-4 pb-3" style={{ borderTopColor: color.tableBorder }}>
            <button type="button" className="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
              <>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                Please wait...
              </>

              : "Submit"}</button>
          </div>
        }
      </div>
    </>
  )
}

export default PersonalDetails