import React, { useState, useEffect } from 'react'
import { useColorStore } from '../../../../../Store/ColorStore'
import { useNavigate, useParams } from 'react-router-dom'
import { Icon } from '@iconify/react'
import './UserDetails.css'
import Profile from './Profile';
import PersonalDetails from './PersonalDetails';
import { useUserStore } from '../../../../../Store/UserStore/UserStore'
import Loading from '../../../../../Loading'
import JoiningInfo from './JoiningInfo';
import BankDetails from './BankDetails'
import Addresses from './Addresses'
import UserCompanyAssets from './UsersCompanyAssets'
import { userSegrigation, userType } from '../../../../../Export'
import { useUserPermissionStore } from './../../../../../Store/UserPermissionStore/UserPermissionStore';

const UserDetails = () => {
    let token = JSON.parse(localStorage.getItem('user'))
    const navigation = useNavigate()
    const { color } = useColorStore()
    let { userId } = useParams();
    const { users, fetchUsers } = useUserStore()
    const [isLoading, setIsLoading] = useState(false)

    const t = {
        profile: 'profile',
        personalDetails: 'personalDetails',
        joiningInfo: 'joiningInfo',
        bankDetails: 'bankDetails',
        address: 'address',
        assets: 'assets',
    }

    const [currentUser, setCurrentUser] = useState()

    useEffect(() => {
        if (users.length === 0) {
            fetchUsers()
        }
    }, [])


    useEffect(() => {
        if (token.user_type === userType.admin || token.user_type === userType.sub_admin) {
            const d = users.find((f) => {
                return f.id === Number(userId)
            })
            if (d) {
                setCurrentUser(d)
            }
        } else if (token.id === Number(userId)) {
            const d = users.find((f) => {
                return f.id === Number(userId)
            })
            if (d) {
                setCurrentUser(d)
            }
        } else {
            setCurrentUser()
        }
    }, [userId, users])

    // -----------------Permission Work------------------
    const [permission, setPermission] = useState(false)
    const { userPermissions } = useUserPermissionStore()
    useEffect(() => {
        let d = userPermissions.find((f) => {
            return f.permission_name === 'STAFF_PERMISSION'
        })
        let x = userSegrigation.find((f) => {
            return f.key === token.user_type
        })
        if (d && x) {
            setPermission(d[x.name])
        }
    }, [userPermissions])

    const [tabs, setTabs] = useState(t.profile)

    return (
        <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
            <div className="mb-2" style={{ marginTop: '-10px' }}>
                <Icon icon="cil:arrow-left" className='modal_close font-30' color={color.darkText} type='button' onClick={() => navigation(-1)} />
            </div>
            <div className='myrow'>
                <div className={tabs === t.profile ? "userTab_active" : "userTab"} onClick={() => setTabs(t.profile)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText }}><Icon icon="ri:shield-user-line" fontSize={23} /> <br /><span className="tabTitle">Profile</span></div>
                <div className={tabs === t.personalDetails ? "userTab_active" : "userTab"} onClick={() => setTabs(t.personalDetails)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText }}><Icon icon="icon-park-outline:personal-privacy" fontSize={23} /> <br /><span className="tabTitle">Personal Info</span></div>
                <div className={tabs === t.joiningInfo ? "userTab_active" : "userTab"} onClick={() => setTabs(t.joiningInfo)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText }}><Icon icon="mdi:user-tie" fontSize={23} /> <br /><span className="tabTitle">Joining Info</span></div>
                <div className={tabs === t.bankDetails ? "userTab_active" : "userTab"} onClick={() => setTabs(t.bankDetails)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText }}><Icon icon="basil:bank-solid" fontSize={23} /> <br /><span className="tabTitle">Bank Details</span></div>
                <div className={tabs === t.address ? "userTab_active" : "userTab"} onClick={() => setTabs(t.address)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText }}><Icon icon="mdi:address-marker-outline" fontSize={23} /> <br /><span className="tabTitle">Address</span></div>
                <div className={tabs === t.assets ? "userTab_active" : "userTab"} onClick={() => setTabs(t.assets)} style={{ borderBottom: "2px solid " + color.tableBorder, color: color.lightText }}><Icon icon="mdi:generator-stationary" fontSize={23} /> <br /><span className="tabTitle">Assets</span></div>
            </div>

            {currentUser ?
                <div className='mt-5'>
                    {tabs === t.profile &&
                        <Profile permission={permission} currentUser={currentUser} color={color} users={users} isLoading={isLoading} setIsLoading={setIsLoading} />
                    }
                    {tabs === t.personalDetails &&
                        <PersonalDetails permission={permission} currentUser={currentUser} color={color} users={users} isLoading={isLoading} setIsLoading={setIsLoading} />
                    }
                    {tabs === t.joiningInfo &&
                        <JoiningInfo permission={permission} currentUser={currentUser} color={color} users={users} isLoading={isLoading} setIsLoading={setIsLoading} />
                    }
                    {tabs === t.bankDetails &&
                        <BankDetails permission={permission} currentUser={currentUser} color={color} users={users} isLoading={isLoading} setIsLoading={setIsLoading} />
                    }
                    {tabs === t.address &&
                        <Addresses permission={permission} currentUser={currentUser} color={color} users={users} isLoading={isLoading} setIsLoading={setIsLoading} />
                    }
                    {tabs === t.assets &&
                        <UserCompanyAssets permission={permission} currentUser={currentUser} color={color} users={users} isLoading={isLoading} setIsLoading={setIsLoading} />
                    }

                </div>
                :
                <div className='my-5 text-center' style={{ color: color.darkText, fontSize: 15, fontWeight: 500 }}> <Icon icon="solar:danger-triangle-broken" color={color.red} fontSize={40} /><br /> No User Found...!!!</div>
            }

            {isLoading &&
                <Loading />}
        </div>
    )
}

export default UserDetails