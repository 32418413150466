import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import { useColorStore } from './../../../Store/ColorStore';
import moment from 'moment';
import { Avatar } from '@mui/material';
import { fileUrl, mediaUrl } from '../../../Export';

const MarketUpdateList = (props) => {
    const{data,users} = props
    const { color } = useColorStore()
    const [cuser, setCuser] = useState()
    const findUser = () =>{
        let d = users.find((f)=>{
            return f.id===data.user
        })
        setCuser(d)
    }

    useEffect(() => {
      findUser()
    }, [data,users])
    
    return (
        <>
            {/* -----------------Market Update------------------------------------- */}
            <div className="d-flex notification_wrapper" >
                <div>
                <div><Avatar src={cuser ? fileUrl + cuser.proimg : ''} sx={{ bgcolor: "#fff", color: "#0C768A", fontSize: 14, fontWeight: 700, width: '40px', height: '40px', border: "3px solid #fff" }} /></div>
                </div>
                <div style={{ marginLeft: '20px' }}>
                    <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', fontWeight: 450 }}>{cuser?cuser.name:''}</div>
                    <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px',whiteSpace:'pre-wrap' }}>{data.content}</div>
                    <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.created_at).startOf('seconds').fromNow()}</span></div>
                </div>
            </div>
            <hr style={{borderColor:color.borderColor,margin:0}} />
        </>
    )
}

export default MarketUpdateList