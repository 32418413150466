import React, { useState } from 'react'
import { useColorStore } from '../../../../Store/ColorStore'
import { Icon } from '@iconify/react'
import { useComplaintStore } from '../../../../Store/WebsiteStore/ComplaintStore'
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

const ComplaintList = (props) => {
    const { addToast } = useToasts();
    const { updateComplaint, deleteComplaint, fetchComplaint } = useComplaintStore()
    const { color } = useColorStore()
    const { data, count, isLoading, setIsLoading, permission } = props
    const [error, setError] = useState(false)
    const [payload, setPayload] = useState({
        types: data.types,
        date: data.date,
        carried: data.carried,
        recieved: data.recieved,
        resolved: data.resolved,
        pending: data.pending,
    })

    const handleSubmit = () => {
        if (payload.types.length === 0 || payload.date.length === 0 || payload.carried.length === 0 || payload.recieved.length === 0 || payload.resolved.length === 0 || payload.pending.length === 0) {
            setError(true)
            addToast('All Fields Required...', { appearance: 'error' });
        } else {
            setError(false)
            setIsLoading(true)
            updateComplaint(payload, data.id)
                .then(res => {
                    fetchComplaint()
                    setIsLoading(false)
                    setPayload({ ...payload, types: payload.types,
                        date: payload.date,
                        carried: payload.carried,
                        recieved: payload.recieved,
                        resolved: payload.resolved,
                        pending: payload.pending,})
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    console.log(err);
                    setIsLoading(false)
                    addToast('Error...', { appearance: 'error' });
                })
        }
    }

    const handleDelete = () => {
        let d = document.getElementById("dltBtnCom"+data.id)
        d.click()
        setIsLoading(true)
        deleteComplaint(data.id)
            .then(res => {
                fetchComplaint()
                setIsLoading(false)
                addToast('Success...', { appearance: 'success' });
            }).catch(err => {
                console.log(err);
                setIsLoading(false)
                addToast('Error...', { appearance: 'error' });
            })
    }

    return (
        <>
            <tr style={{ color: color.darkText }}>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }} scope="row">
                    {count}
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder, lineHeight: '25px' }}>
                    {data.types} <br /> <span style={{ fontSize: '11px', color: color.lightText, display: 'flex', alignItems: 'center' }}><Icon icon="uiw:time-o" color={color.lightText} fontSize={11} /> <span style={{ marginLeft: '6px' }}>{moment(data.created_at).format('lll')}</span></span>
                </td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.date}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.carried}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.recieved}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.resolved}</td>
                <td style={{ color: color.darkText, borderBottom: '1px solid ' + color.tableBorder }}>{data.pending}</td>

                <td style={{ borderBottom: '1px solid ' + color.tableBorder }}>
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <span type="button" class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryModal" + data.id} style={{ color: color.blue }}><Icon icon="akar-icons:edit" fontSize={18} /></span>
                        </li>
                        <li class="list-inline-item">
                            <span type={'button'} class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal" + data.id} style={{ color: color.red }}><Icon icon="ep:delete" fontSize={17} /></span>
                        </li>
                    </ul>
                </td>
            </tr>

            {/* ----------------------Update Modal---------------------------- */}

            <div class="modal fade" id={"categoryModal" + data.id} tabindex="-1" aria-labelledby="categoryModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="categoryModalLabel">Update Complaints</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>All fields Required...!</div>}

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Select Type <span className="text-danger">*</span></label>
                                <select value={payload.types} onChange={(e) => setPayload({ ...payload, types: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                                    <option value="Month">Month</option>
                                    <option value="Year">Year</option>
                                </select>
                            </div>

                            <div className='mb-3'>
                                <label className='modal_label' style={{ color: color.darkText }}>Month/Year <span className="text-danger">*</span></label>
                                <input value={payload.date} onChange={(e) => setPayload({ ...payload, date: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>

                            <div className="myrow justify_space_between">
                                <div className='mb-3' style={{ width: '49%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Carried <span className="text-danger">*</span></label>
                                    <input value={payload.carried} onChange={(e) => setPayload({ ...payload, carried: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-3' style={{ width: '49%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Recieved <span className="text-danger">*</span></label>
                                    <input value={payload.recieved} onChange={(e) => setPayload({ ...payload, recieved: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-3' style={{ width: '49%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Resolved <span className="text-danger">*</span></label>
                                    <input value={payload.resolved} onChange={(e) => setPayload({ ...payload, resolved: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>

                                <div className='mb-3' style={{ width: '49%' }}>
                                    <label className='modal_label' style={{ color: color.darkText }}>Pending <span className="text-danger">*</span></label>
                                    <input value={payload.pending} onChange={(e) => setPayload({ ...payload, pending: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleSubmit}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Update"}</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----------------------------Delete Modal------------------------ */}

            <div class="modal fade" id={"categoryDeleteModal" + data.id} tabindex="-1" aria-labelledby="categoryDeleteModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>

                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>
                            <div className="modal_title" style={{ color: color.darkText }}>Are you sure?</div>

                            <div className='pt-3' style={{ color: color.lightText, fontSize: '15px' }}>Do you really want to delete this Data ? You would'nt be able to retrieve this.</div>
                        </div>


                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id={"dltBtnCom"+data.id} style={{ color: color.darkText }} className="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="dangerButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleDelete}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>
                                : "Delete"}</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ComplaintList