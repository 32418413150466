import React, { useEffect, useState } from 'react'
import { useResearchCallStore } from '../../../../Store/ResearchStore/CallStore/CallStore';
import { useResearchProductStore } from './../../../../Store/ResearchStore/ResearchProductStore/ResearchProductStore';
import { useProductStore } from './../../../../Store/ProductStore/ProductStore';
import { useToasts } from 'react-toast-notifications';
import { useColorStore } from '../../../../Store/ColorStore';
import Loading from '../../../../Loading';
import { callDetailsNumber, callDetailsWebsite } from '../../../../Export';

const Cash = () => {
  const { addToast } = useToasts();
  const { color } = useColorStore();
  const { researchProduct } = useResearchProductStore()
  const { sendCall } = useResearchCallStore()
  const [isLoading, setIsLoading] = useState(false)
  const { product } = useProductStore()
  const [products, setProducts] = useState([])
  const [currProduct, setCurrProduct] = useState()
  const [msgContent, setMsgContent] = useState('')

  useEffect(() => {
    if (researchProduct) {
      let data = researchProduct.filter((d) => d.category === 'CASH')
      if (data) {
        setProducts(data)
      }
    }
  }, [researchProduct])

  useEffect(() => {
    if (products.length > 0) {
      setCurrProduct(products[0])
    }
  }, [products])

  useEffect(() => {
    if (currProduct) {
      setPayload({ ...payload, tgt: currProduct.tgt_prcnt, sl: currProduct.sl_prcnt, template: payload.type === 'BUY' ? currProduct.buy_template : currProduct.sell_template, segment: currProduct.product_id })
    }
  }, [currProduct])

  const findProduct = (id) => {
    for (let i = 0; i < product.length; i++) {
      const element = product[i];
      if (element.id === id) {
        return element.name
      }
    }
  }

  const [payload, setPayload] = useState({
    ct: 'CASH',
    type: 'BUY',
    stockName: '',
    stockPrice: '',
    option: '',
    strikePrice: '',
    expiryDate: '',
    lotsize: '',
    template: '',
    tgt: 0,
    sl: 0,
    correction: false,
    segment: ''
  })

  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Calculator Work>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>


  const [ntgt, setNtgt] = useState('')
  const [nsl, setNsl] = useState('')
  let calcutgt = 0
  let calcusl = 0
  if (payload.stockPrice.length > 0) {
    calcutgt = parseFloat(payload.stockPrice) * payload.tgt / 100
    calcusl = parseFloat(payload.stockPrice) * payload.sl / 100
  }
  useEffect(() => {
    if (payload.type === 'BUY') {
      setNtgt(parseFloat(parseFloat(payload.stockPrice) + parseFloat(calcutgt)).toFixed(1))
      setNsl(parseFloat(parseFloat(payload.stockPrice) - parseFloat(calcusl)).toFixed(1))
    } else if (payload.type === 'SELL') {
      setNtgt(parseFloat(parseFloat(payload.stockPrice) - parseFloat(calcutgt)).toFixed(1))
      setNsl(parseFloat(parseFloat(payload.stockPrice) + parseFloat(calcusl)).toFixed(1))
    }
  })
  const sell_content = `${payload.correction ? "Correction\n" : ''}` +
    "SELL " + payload.stockName.toUpperCase() + " (NSECASH) BELOW " + payload.stockPrice + " TGT " + `${ntgt.split(".")[1] === '0' ? ntgt.split(".")[0] : ntgt.toString() + '0'}` + " SL " + `${nsl.split(".")[1] === '0' ? nsl.split(".")[0] : nsl.toString() + '0'}` + "."

  const buy_content = `${payload.correction ? "Correction\n" : ''}` +
    "BUY " + payload.stockName.toUpperCase() + " (NSECASH) ABOVE " + payload.stockPrice + " TGT " + `${ntgt.split(".")[1] === '0' ? ntgt.split(".")[0] : ntgt.toString() + '0'}` + " SL " + `${nsl.split(".")[1] === '0' ? nsl.split(".")[0] : nsl.toString() + '0'}` + "."

  useEffect(() => {
    if (payload.type === 'BUY') {
      setMsgContent(buy_content)
    } else if (payload.type === 'SELL') {
      setMsgContent(sell_content)
    }
  }, [payload, product])

  useEffect(() => {
    setPayload({ ...payload, nsl: nsl, ntgt: ntgt })
  }, [nsl, ntgt])

  function copyTextToClipboard() {
    addToast('Copied', { appearance: 'success' });
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(`${currProduct ? findProduct(currProduct.product_id) :null}\n` + msgContent + "\n" + callDetailsWebsite + "\n" + callDetailsNumber);
    } else {
      return document.execCommand('copy', true, `${currProduct ? findProduct(currProduct.product_id) :null}\n` + msgContent + "\n" + callDetailsWebsite + "\n" + callDetailsNumber);
    }
  }

  const sendCallsHandler = () => {
    setIsLoading(true)
    if (msgContent.length === 0 || payload.stockName.length === 0 || payload.stockPrice.length === 0) {
      setIsLoading(false)
      addToast('All Fields Required...!', { appearance: 'error' });
    } else {
      copyTextToClipboard()
      sendCall({ msgContent, currProduct, payload })
        .then(res => {
          setIsLoading(false)
          addToast('Success', { appearance: 'success' });
        })
        .catch(err => {
          setIsLoading(false)
          addToast('Error', { appearance: 'error' });
        })
    }
  }

  if (products.length > 0 && currProduct) {
    return (
      <>
        <div className="myrow">
          {products.map((c) => {
            return <button type='button' key={c.id} style={{ color: c.id === currProduct.id ? '' : color.darkText }} className={c.id === currProduct.id ? "infoButton mx-1" : "lightButton mx-1"} onClick={() => setCurrProduct(c)}>
              {findProduct(c.product_id)}
            </button>
          })}
        </div>

        <hr style={{ borderColor: color.borderColor, opacity: 1 }} />

        <div className="myrow justify_space_between mb-3">
          <div>
            <button style={{ color: payload.type === 'BUY' ? '' : color.darkText }} className={payload.type === 'BUY' ? 'infoButton' : 'lightButton'} onClick={(e) => setPayload({ ...payload, type: 'BUY' })}>
              BUY
            </button>
            &nbsp;
            <button style={{ color: payload.type === 'SELL' ? '' : color.darkText }} className={payload.type === 'SELL' ? 'infoButton' : 'lightButton'} onClick={(e) => setPayload({ ...payload, type: 'SELL' })}>
              SELL
            </button>
          </div>
          <button style={{ color: payload.correction ? '' : color.darkText }} className={payload.correction ? 'infoButton' : 'lightButton'} onClick={(e) => setPayload({ ...payload, correction: payload.correction ? false : true })}>
            CORRECTION
          </button>
        </div>

        <div className="myrow">
          <div className="m-2">
            <label className='modal_label' style={{ color: color.darkText }}>Stock Name</label>
            <input value={payload.stockName} onChange={(e) => setPayload({ ...payload, stockName: e.target.value.toUpperCase() })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
          </div>
          <div className="m-2">
            <label className='modal_label' style={{ color: color.darkText }}>Above/Below</label>
            <input value={payload.stockPrice} onChange={(e) => setPayload({ ...payload, stockPrice: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
          </div>
          <div className="m-2">
            <label className='modal_label' style={{ color: color.darkText }}>TGT%</label>
            <input value={payload.tgt} onChange={(e) => setPayload({ ...payload, tgt: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
          </div>
          <div className="m-2">
            <label className='modal_label' style={{ color: color.darkText }}>SL%</label>
            <input value={payload.sl} onChange={(e) => setPayload({ ...payload, sl: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '0.5px solid ' + color.borderColor }} className='calculator_input' />
          </div>
        </div>

        <div className='m-1 mt-3'>
          <textarea readOnly={true} value={`${currProduct ? findProduct(currProduct.product_id) :null}\n` + msgContent + "\n" + callDetailsWebsite + "\n" + callDetailsNumber} onChange={(e) => setMsgContent(e.target.value)} rows={5} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor, lineHeight: '25px', fontSize: '15px' }} className='modal_input'></textarea>

        </div>

        <div className='m-3'>
          <button className='warningButton' style={{ textTransform: 'uppercase' }} onClick={copyTextToClipboard}>
            Copy
          </button>
          &nbsp;&nbsp;&nbsp;
          <button className="primaryButton" disabled={isLoading} style={{ textTransform: 'uppercase' }} onClick={sendCallsHandler}>
            {isLoading ?
              <>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                Please wait...
              </>
              :
              "Send"}
          </button>
        </div>
        {isLoading && <Loading />}
      </>
    )
  } else {
    return null
  }
}

export default Cash